@import url(https://fonts.googleapis.com/css?family=Quicksand:300,300i,400,400i,600,600i,700,700i&subset=cyrillic);
@import url(https://fonts.googleapis.com/css?family=Rubik:300,300i,400,400i,500,500i,700,700i&subset=cyrillic);
@import url(https://fonts.googleapis.com/css?family=Poppins:300,300i,400,400i,500,500i,700,700i&subset=cyrillic);
.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.app-notifications {
  padding: 5px 10px;
  text-align: center; }
  .app-notifications.error {
    background-color: red;
    color: #fff;
    font-weight: 600; }

.refresh-button {
  background: none;
  border: none;
  text-decoration: underline;
  cursor: pointer;
  color: black;
  transition: color .2s ease-in-out; }
  .refresh-button:hover {
    color: var(--zohohc_asap_web_appziausermessage_bg); }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.breadcrumbs {
  margin: 20px 0 40px 0;
  color: #2e384d;
  font-size: 15px; }
  .breadcrumbs span {
    text-transform: capitalize; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.certificate-item {
  text-align: center;
  border-top-right-radius: 7px;
  border-top-left-radius: 7px;
  background-color: #fff;
  margin-bottom: 2rem;
  overflow: hidden; }
  .certificate-item .preview-img {
    height: 150px;
    background: #ccc;
    background-size: cover; }
  .certificate-item .info {
    padding: 0 10px 20px 10px; }
  .certificate-item .name {
    display: inline-block;
    color: #2e384d;
    margin: 15px 0 10px 0; }
  .certificate-item .description {
    font-size: 12px;
    color: #606d7c;
    margin-bottom: 4px; }
  .certificate-item a {
    display: block; }
  .certificate-item .ui.button.basic.blue {
    font-size: 15px;
    color: #0067ac !important;
    box-shadow: none !important; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.circle-progress-bar {
  position: relative;
  height: 110px;
  overflow: hidden; }
  .circle-progress-bar .progress-count {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translateX(-50%);
    text-align: center; }
    .circle-progress-bar .progress-count .count {
      display: block;
      color: #2e384d;
      font-size: 36px;
      line-height: 35px; }
    .circle-progress-bar .progress-count .text {
      font-size: 13px;
      line-height: 18px;
      text-transform: uppercase;
      letter-spacing: 1.2px; }
  .circle-progress-bar .loader {
    stroke: #bfc5d2;
    stroke-width: 18;
    stroke-dasharray: 186, 350;
    transform-origin: center;
    transform: rotate(172deg);
    fill: transparent; }
  .circle-progress-bar .loader.main {
    stroke: #0067ac;
    stroke-dasharray: 0, 350; }
  .circle-progress-bar .dash {
    stroke: #fff;
    stroke-width: 20;
    stroke-dasharray: 4 2;
    transform-origin: center;
    transform: rotate(133deg);
    fill: transparent; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.colour-tooltip .colour-item {
  display: flex;
  align-items: center;
  margin-top: 8px; }
  .colour-tooltip .colour-item .colour-block {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    margin-right: 8px; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.custom-column-div {
  width: 100% !important;
  border: 2px solid #647382;
  background: #fff;
  text-align: center;
  border-radius: 14px;
  padding: 15px; }
  .custom-column-div .custom-column {
    display: flex !important;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px; }
  .custom-column-div .tag-listing {
    width: -moz-fit-content;
    width: fit-content;
    margin: 0;
    padding: 0;
    border: 0; }
  .custom-column-div h3 {
    font-size: 18px;
    font-weight: bold;
    font-family: 'Quicksand', sans-serif;
    margin-bottom: 0; }
  .custom-column-div p {
    font-size: 18px;
    word-break: break-all; }
  .custom-column-div.pointer {
    cursor: pointer !important; }

.license-subscription {
  display: flex;
  grid-gap: 8px;
  gap: 8px; }

.bundle-proceed {
  width: 150px !important;
  height: 35px !important;
  margin-top: -6px !important; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.ui.custom-dropdown.dropdown.selection {
  border-color: #0067ac; }
  .ui.custom-dropdown.dropdown.selection > .default.text,
  .ui.custom-dropdown.dropdown.selection > .text,
  .ui.custom-dropdown.dropdown.selection.visible .text:not(.default) {
    color: #0067ac; }
  .ui.custom-dropdown.dropdown.selection:hover, .ui.custom-dropdown.dropdown.selection:focus, .ui.custom-dropdown.dropdown.selection.active, .ui.custom-dropdown.dropdown.selection.active:hover,
  .ui.custom-dropdown.dropdown.selection.active .menu,
  .ui.custom-dropdown.dropdown.selection.active:hover .menu {
    border-color: #0067ac; }
  .ui.custom-dropdown.dropdown.selection .icon.angle {
    cursor: pointer;
    position: absolute;
    width: auto;
    height: auto;
    line-height: 1.21428571em;
    top: 0.78571429em;
    right: .9em;
    z-index: 3;
    margin: -0.78571429em;
    padding: 0.5em;
    font-size: 18px;
    transition: opacity 0.1s ease;
    color: #0067ac; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.customer-logo img {
  display: block;
  height: 40px; }

.customer-logo .ui.dropdown {
  display: flex;
  align-items: center; }
  .customer-logo .ui.dropdown .menu {
    margin-top: 10px; }

.customer-logo .icon-wrap {
  display: inline-block;
  line-height: 1;
  border: 2px solid #b0bac9;
  color: #b0bac9;
  padding: 0 3px;
  margin-left: 13px; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.app-header {
  display: flex;
  align-items: center;
  flex: 0 0 80px;
  max-height: 80px;
  border-bottom: 1px solid #CACED6;
  background: #fff; }
  .app-header .customer-logo {
    flex: 0 0 215px;
    padding: 0 15px; }
    .app-header .customer-logo i {
      color: inherit; }
    @media screen and (max-width: 991px) {
      .app-header .customer-logo {
        display: none; } }
  .app-header .container {
    flex: 0 0 auto; }
  @media screen and (max-width: 991px) {
    .app-header {
      max-height: 60px; } }

.header-inner {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%; }
  @media screen and (max-width: 991px) {
    .header-inner .menu-btn {
      margin-right: auto; } }

.notifications {
  position: relative;
  cursor: pointer; }
  .notifications::after {
    content: '';
    position: absolute;
    top: -3px;
    right: -3px;
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #0067ac; }
  .notifications i {
    font-size: 30px;
    color: #bfc5d2; }
    @media screen and (max-width: 767px) {
      .notifications i {
        font-size: 22px; } }

.user-box {
  display: flex;
  align-items: center;
  margin-left: 32px; }
  .user-box .avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    border-radius: 5px;
    background: #bfc5d2 center;
    background-size: cover;
    margin-right: 10px; }
    .user-box .avatar i {
      font-size: 26px;
      color: #fff; }
  .user-box .content {
    font-size: 16px; }
    .user-box .content .greeting {
      color: #606d7c; }
    .user-box .content.impersonate .greeting {
      color: red; }
    .user-box .content .name {
      color: #2e384d; }
    @media screen and (max-width: 767px) {
      .user-box .content {
        font-size: 14px; } }
  .user-box span {
    display: block; }
  .user-box .ui.dropdown .menu {
    z-index: 110; }
    .user-box .ui.dropdown .menu .quit-impersonate {
      color: red; }
  @media screen and (max-width: 991px) {
    .user-box {
      margin-left: 10px; } }

.menu-btn {
  position: relative;
  display: none;
  font-size: 26px;
  line-height: 1;
  color: #b0bac9;
  border: none;
  outline: none;
  background-color: transparent; }
  .menu-btn i {
    width: 30px; }
  .menu-btn .fa-bars::after {
    content: '';
    position: absolute;
    bottom: 4px;
    right: 5px;
    width: 9px;
    height: 6px;
    background-color: #fff; }
  @media screen and (max-width: 991px) {
    .menu-btn {
      display: block; } }

.ui.button.switch-role {
  background-color: #90a6d5; }
  .ui.button.switch-role i {
    padding-right: 25px;
    color: white; }
  .ui.button.switch-role:hover {
    background-color: #90a6d5; }
  @media screen and (max-width: 767px) {
    .ui.button.switch-role {
      font-size: 10px !important; }
      .ui.button.switch-role i {
        display: none; } }

.ui.toggle.checkbox.manager-notifications-toggle {
  margin-right: 32px; }
  .ui.toggle.checkbox.manager-notifications-toggle.checked label::after {
    content: "\F0F3" !important; }
  .ui.toggle.checkbox.manager-notifications-toggle label::after {
    content: "\F1F6" !important;
    font-family: 'Font Awesome 5 Pro';
    display: flex;
    justify-content: center;
    align-items: center; }

.accessibility-btn {
  background: none;
  border: none;
  cursor: pointer; }

.accessibility-icon {
  color: #9dce4d !important;
  font-size: 30px; }

.account-logo-section {
  display: flex;
  width: 100%; }

.third-party-link-mb {
  display: none !important; }

@media screen and (max-width: 992px) {
  .third-party-link-lg {
    display: none !important; }
  .container-section {
    max-width: 100% !important; }
  .third-party-link-mb {
    display: block !important;
    margin-right: auto; } }

@media screen and (min-width: 992px) {
  .container-section {
    max-width: calc(100vw - 400px) !important; } }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.left-menu {
  position: relative;
  flex: 0 0 215px;
  max-width: 215px;
  background: #647283;
  transition: transform 0.15s ease-out;
  display: flex;
  flex-direction: column; }
  .left-menu .menu-wrap {
    list-style: none;
    padding: 0;
    margin: 0; }
  .left-menu .menu-item {
    position: relative;
    cursor: pointer;
    color: #fff; }
    .left-menu .menu-item.has-customer-logo {
      padding: 15px 20px; }
      .left-menu .menu-item.has-customer-logo img {
        height: auto; }
      .left-menu .menu-item.has-customer-logo .ui.dropdown .menu {
        width: calc(100vw - 40px);
        max-width: 320px;
        min-width: 0; }
        .left-menu .menu-item.has-customer-logo .ui.dropdown .menu > .item {
          display: flex;
          align-items: flex-start;
          white-space: normal; }
          .left-menu .menu-item.has-customer-logo .ui.dropdown .menu > .item img {
            margin-top: 0;
            margin-bottom: 0; }
      @media screen and (min-width: 992px) {
        .left-menu .menu-item.has-customer-logo {
          display: none; } }
    .left-menu .menu-item i {
      display: inline-block;
      color: #bfc5d2; }
  .left-menu .link {
    display: block;
    line-height: 20px;
    padding: 19px 20px 17px;
    transition: all 0.15s ease-out; }
    .left-menu .link.active, .left-menu .link:hover {
      color: #0067ac;
      background-color: rgba(46, 56, 77, 0.1); }
      .left-menu .link.active::before, .left-menu .link:hover::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 3px;
        height: 100%;
        background: #bfc5d2; }
      .left-menu .link.active span, .left-menu .link:hover span {
        color: #fff; }
    .left-menu .link span {
      font-size: 15px;
      color: #fff;
      padding-left: 16px; }
  .left-menu .bottom-logo {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    width: 100%;
    text-align: center;
    padding: 10px 0; }
    .left-menu .bottom-logo img {
      max-width: 85% !important; }
    .left-menu .bottom-logo-copyright {
      font-size: 13px;
      padding: 0 10px; }
  @media screen and (max-width: 991px) {
    .left-menu {
      position: absolute;
      z-index: 100;
      min-height: calc(100vh - 60px);
      top: 0;
      left: 0;
      transform: translateX(-100%); }
      .left-menu.open {
        transform: translateX(0); } }
  .left-menu button {
    display: inline-block; }
  .left-menu .mtext {
    position: relative;
    top: -7px;
    padding-left: 10px; }

html.zohohc-asap-web-viewer-opened .mtext {
  top: -13px; }

.ZohoButton_root__1LvoS {
  transform: rotate(45deg);
  border-radius: 30%;
  animation-duration: inherit;
  animation-fill-mode: none;
  transition: all 0.2s cubic-bezier(0.42, 0, 0, 0.92);
  padding: 5px;
  box-shadow: 0 5px 7px 0 rgba(0, 0, 0, 0.24);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: var(--zohohc_asap_web_applauncher_bg);
  overflow: hidden;
  border: none;
  outline: none; }

.ZohoButton_content__tr631 {
  transform: rotate(-45deg);
  width: var(--zohohc_asap_web_launcher_icon_width);
  height: var(--zohohc_asap_web_launcher_icon_height); }

.ZohoButton_wrap__1LpYm {
  height: inherit;
  justify-content: center; }

.ZohoButton_icon__2Rn_V {
  display: flex;
  height: inherit;
  box-sizing: content-box;
  justify-content: center;
  color: var(--zohohc_asap_web_applauncher_iconclr);
  width: auto; }

.ZohoButton_svg__19_gD {
  width: inherit;
  fill: currentColor;
  color: inherit; }
  .ZohoButton_svg__19_gD.ZohoButton_opened__1pCEZ {
    width: 150%;
    height: 150%;
    margin-top: -25%; }

.ZohoButton_maindiv__24ImA {
  padding: 7px;
  padding-left: 12px;
  outline: none !important;
  border: none !important; }

.ZohoButton_maindiv__24ImA:hover,
.ZohoButton_maindiv__24ImA.ZohoButton_active__6g8dN {
  background-color: rgba(46, 56, 77, 0.1); }

.ZohoButton_maindiv__24ImA.ZohoButton_active__6g8dN:before,
.ZohoButton_maindiv__24ImA:hover:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 3px;
  height: 100%;
  background: #bfc5d2; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.accessibilityButton {
  padding: 14px 14px 15px; }
  .accessibilityButton span {
    font-size: 15px;
    color: #fff;
    padding-left: 16px; }

.rootButton {
  outline: none !important;
  border: none !important;
  background: none;
  padding: 0; }

.accessibilityButton:hover,
.accessibilityButton.active {
  background-color: rgba(46, 56, 77, 0.1); }

.accessibilityButton.active:before,
.accessibilityButton:hover:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 3px;
  height: 100%;
  background: #bfc5d2; }

.accessibility-nav-button {
  position: relative;
  top: -7px;
  padding-left: 10px !important; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.loader-wrap {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0; }

.lds-ellipsis {
  width: 64px;
  height: 64px;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.lds-ellipsis div {
  position: absolute;
  top: 27px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #0067ac;
  animation-timing-function: cubic-bezier(0, 1, 1, 0); }

.lds-ellipsis div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite; }

.lds-ellipsis div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite; }

.lds-ellipsis div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 0.6s infinite; }

.lds-ellipsis div:nth-child(4) {
  left: 45px;
  animation: lds-ellipsis3 0.6s infinite; }

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0); }
  100% {
    transform: scale(1); } }

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1); }
  100% {
    transform: scale(0); } }

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0); }
  100% {
    transform: translate(19px, 0); } }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.page-content .page-message {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  color: #b0bac9;
  padding: 50px; }
  .page-content .page-message.error {
    color: #db2828; }
  .page-content .page-message .icon {
    height: auto;
    margin-right: 10px; }

@media screen and (min-width: 1200px) {
  .page-content.has-right-bar .content {
    padding-right: 250px; } }

.page-content .right-bar {
  position: fixed;
  z-index: 3;
  top: 80px;
  right: 10px;
  bottom: 0;
  width: 250px;
  background-color: #fff;
  transition: transform .15s ease-out; }
  #recite-wrapper .page-content .right-bar {
    top: 140px; }
  .page-content .right-bar .ui.selection.dropdown {
    min-width: 100%;
    border: 1px solid rgba(34, 36, 38, 0.15); }
    .page-content .right-bar .ui.selection.dropdown .icon:not(.delete):not(.close) {
      z-index: 10; }
    .page-content .right-bar .ui.selection.dropdown.active {
      border-color: #0067ac; }
  @media screen and (max-width: 1199px) {
    .page-content .right-bar {
      right: 0;
      transform: translateX(100%); }
      .page-content .right-bar.open {
        transform: translateX(-10px); } }
  @media screen and (max-width: 991px) {
    .page-content .right-bar {
      top: 60px; } }

.page-content .right-bar-toggle {
  position: absolute;
  top: 30px;
  right: 100%;
  padding: 10px 12px;
  color: #fff;
  border-radius: 4px 0 0 4px;
  background-color: #0067ac;
  cursor: pointer; }
  .page-content .right-bar-toggle .icon {
    margin-right: 0;
    color: white; }
  @media screen and (min-width: 1200px) {
    .page-content .right-bar-toggle {
      display: none; } }

.page-content .right-bar-content {
  height: 100%;
  padding: 30px 25px;
  overflow-y: auto; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.progress-bar {
  width: 100%; }
  .progress-bar + .progress-bar {
    margin-top: 18px; }
  .progress-bar .label-bar {
    display: inline-block;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1.12px;
    white-space: nowrap;
    color: #8798ad; }
  .progress-bar .bar {
    width: 100%;
    height: 16px;
    background-color: #fff;
    overflow: hidden; }
  .progress-bar .progress {
    height: 100%; }

.flex-title {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
  padding-top: 10px;
  margin-bottom: 10px; }

.margin-top-props {
  margin-top: 10px; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

#recite-wrapper #zohohc-asap-web-layoutbox {
  height: calc(100% - 60px); }

#recite-dropdowns #recite-close.show {
  display: block !important;
  cursor: pointer; }

div#recite-header {
  top: 0 !important; }

#recite-wrapper * i.fa,
#recite-wrapper * i.fal,
#recite-wrapper * i.fas {
  font-family: "Font Awesome 5 Pro" !important; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

#root .ui.table {
  background-color: transparent;
  border: none; }
  #root .ui.table .spacer {
    height: 10px; }
    #root .ui.table .spacer:first-child {
      display: none; }
  #root .ui.table .link {
    color: #0067ac; }
  #root .ui.table thead th {
    background-color: transparent;
    color: #1a1a1a;
    font-family: sans-serif;
    font-size: 12px;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 1.12px;
    border: none; }
    #root .ui.table thead th > div {
      display: flex; }
    #root .ui.table thead th.pointer {
      cursor: pointer; }
  #root .ui.table tr.custom-tr {
    height: 96px;
    color: #2e384d;
    border-radius: 1px;
    background-color: #fff; }
    @media screen and (max-width: 767px) {
      #root .ui.table tr.custom-tr {
        height: auto !important; } }
  #root .ui.table th:first-child,
  #root .ui.table td:first-child {
    padding-left: 20px; }
  #root .ui.table th:last-child,
  #root .ui.table td:last-child {
    padding-right: 20px; }
  #root .ui.table th > *,
  #root .ui.table td > * {
    vertical-align: middle; }
  #root .ui.table th .icon:last-child,
  #root .ui.table td .icon:last-child {
    margin-right: 0; }
  #root .ui.table td {
    border: none; }

.table-sort-toggle {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 1.12px;
  color: #1a1a1a;
  border: none;
  outline: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer; }
  .table-sort-toggle > span {
    display: block;
    margin-top: 3px; }
  .table-sort-toggle .icon {
    display: block;
    height: auto;
    font-size: 16px;
    margin-right: 0; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.user-initials-wrap,
.user-photo-wrap {
  position: relative;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  background-color: #bfc5d2; }
  .user-initials-wrap.square,
  .user-photo-wrap.square {
    border-radius: 3px; }
  .user-initials-wrap .ui.inline.loader,
  .user-photo-wrap .ui.inline.loader {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    max-width: 90%;
    max-height: 90%;
    transform: translate(-50%, -50%); }
    .user-initials-wrap .ui.inline.loader::before, .user-initials-wrap .ui.inline.loader::after,
    .user-photo-wrap .ui.inline.loader::before,
    .user-photo-wrap .ui.inline.loader::after {
      width: 100% !important;
      height: 100% !important; }
  .user-initials-wrap .ui.image,
  .user-photo-wrap .ui.image {
    display: block !important;
    z-index: 3;
    margin: 0 !important; }

.user-initials {
  color: #fff;
  font-weight: bold;
  font-size: 16px; }

img.ui.image.square {
  border-radius: 3px; }

label .user-initials-wrap,
label .user-photo-wrap {
  position: relative;
  overflow: hidden;
  cursor: pointer; }
  label .user-initials-wrap::before,
  label .user-photo-wrap::before {
    content: '';
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: opacity .2s; }
  label .user-initials-wrap::after,
  label .user-photo-wrap::after {
    content: '\F093';
    position: absolute;
    z-index: 7;
    top: 0;
    left: 0;
    width: 100%;
    font-family: 'Font Awesome 5 Pro', sans-serif;
    font-size: 16px;
    color: #fff;
    opacity: 0;
    transition: opacity .2s; }
  label .user-initials-wrap:hover::before, label .user-initials-wrap:hover::after,
  label .user-photo-wrap:hover::before,
  label .user-photo-wrap:hover::after {
    opacity: 1; }
  label .user-initials-wrap.small::after,
  label .user-photo-wrap.small::after {
    font-size: 36px; }

label .user-photo-wrap {
  background-color: transparent; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.subjects {
  font-family: "Quicksand", sans-serif; }
  .subjects .row {
    display: flex;
    padding: 8px 0;
    align-items: center; }
    @media screen and (max-width: 500px) {
      .subjects .row {
        flex-direction: column; } }
    .subjects .row .cell {
      flex: 0 0 30%;
      padding: 0 10px;
      vertical-align: middle; }
      .subjects .row .cell.subject-column {
        flex: 0 0 34%;
        color: #1a1a1a; }
      .subjects .row .cell.checkbox-column {
        flex: 0 0 6%;
        padding-top: 3px; }
      .subjects .row .cell .dropdown {
        min-width: auto; }
      .subjects .row .cell .field-wrap {
        margin-bottom: 0; }
  .subjects .header {
    padding: 8px 13px; }
    .subjects .header .cell {
      font-size: 12px; }
  .subjects .body {
    background-color: #F4F6FC;
    border-radius: 4px;
    border: 1px solid #E0E7FF;
    padding: 18px 13px; }
    .subjects .body .row {
      border-bottom: 1px solid #E0E7FF; }
      .subjects .body .row:last-child {
        border-bottom: none; }
      @media screen and (max-width: 500px) {
        .subjects .body .row .cell {
          padding-bottom: 10px;
          width: 100%;
          text-align: center; } }
  .subjects input, .subjects .dropdown {
    min-width: auto;
    min-height: auto;
    width: 100%;
    border-radius: 0;
    height: 34px;
    border: 1px solid #DFE7FF;
    padding-left: 7px; }
  .subjects .date-input-wrap {
    font-size: 12px; }
    .subjects .date-input-wrap i {
      font-size: 14px;
      background-color: #EAEAEA;
      opacity: 1;
      border-radius: 0;
      border: 1px solid #DFE7FF; }

.ui.grid.CoursesTabsNav_root__1hUvC > .row {
  padding: 0; }
  .ui.grid.CoursesTabsNav_root__1hUvC > .row > .column {
    padding-top: 1rem;
    padding-bottom: 1rem; }

.CoursesTabsNav_item__2PlhO {
  display: flex;
  align-items: center;
  background-color: #647283;
  padding: 16px 10px 15px;
  border-radius: 10px;
  color: #fff;
  font-size: 20px;
  transition: background-color .2s; }
  .CoursesTabsNav_item__2PlhO:hover {
    color: #fff; }
  .CoursesTabsNav_item__2PlhO span {
    white-space: nowrap; }

.CoursesTabsNav_total_current__3YEAO {
  background-color: #b80202; }

.CoursesTabsNav_total_optional__1SdFP {
  background-color: #0067ac; }

.CoursesTabsNav_total_completed__1D5N3 {
  background-color: #238823; }

i.icon.CoursesTabsNav_icon__3fXhq {
  color: inherit;
  font-size: 1.8em;
  position: relative;
  top: -2px; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.development-spacer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0; }
  .development-spacer i {
    font-size: 35px;
    padding-right: 15px; }
  .development-spacer h2 {
    margin: 0;
    color: #2e384d;
    font-weight: 400; }

.bottom-cart {
  display: flex;
  align-items: center;
  justify-content: center; }
  .bottom-cart h3 {
    color: #2e384d; }
  .bottom-cart i {
    padding-right: 15px; }

.dashboard-page.learner .user-cart {
  display: flex;
  justify-content: space-between;
  height: 100%;
  background-color: #fff;
  padding: 40px 45px; }
  .dashboard-page.learner .user-cart .button-link {
    margin-top: 5px; }
  @media screen and (min-width: 992px) and (max-width: 1199px) {
    .dashboard-page.learner .user-cart {
      flex-direction: column-reverse;
      align-items: center;
      padding: 20px 25px; }
      .dashboard-page.learner .user-cart .right {
        margin-bottom: 20px; } }
  @media screen and (max-width: 767px) {
    .dashboard-page.learner .user-cart {
      flex-direction: column-reverse;
      align-items: center;
      padding: 20px 25px; }
      .dashboard-page.learner .user-cart .right {
        margin-bottom: 20px; } }

.dashboard-page.learner .info-item + .info-item {
  margin-top: 15px; }

.dashboard-page.learner .info-item span {
  display: block; }

.dashboard-page.learner .right {
  width: 180px; }
  .dashboard-page.learner .right .manager-photo {
    margin-bottom: 10px;
    display: inline-block; }
  .dashboard-page.learner .right span {
    display: block; }

.dashboard-page.learner .ui.grid .ui.button {
  width: 100%;
  font-family: "Quicksand", sans-serif;
  font-size: 15px;
  margin-top: 15px; }

.dashboard-page.learner .button-link {
  width: 100%; }

.dashboard-subject-card {
  display: flex;
  max-height: 302px;
  height: 100%;
  justify-content: space-between;
  background-color: #fff; }
  .dashboard-subject-card.empty-card {
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 40px; }
    .dashboard-subject-card.empty-card i {
      font-size: 80px; }
  .dashboard-subject-card .info {
    padding: 30px 40px; }
  .dashboard-subject-card .bg {
    max-width: 50%;
    flex: 0 1 100%;
    background-color: #b0bac9;
    background-position: center;
    background-size: cover; }
  @media screen and (min-width: 992px) and (max-width: 1199px) {
    .dashboard-subject-card {
      flex-direction: column-reverse;
      max-height: none; }
      .dashboard-subject-card .info {
        padding: 20px 25px; }
      .dashboard-subject-card .bg {
        max-width: 100%; } }
  @media screen and (max-width: 767px) {
    .dashboard-subject-card {
      flex-direction: column-reverse;
      max-height: none; }
      .dashboard-subject-card .info {
        padding: 20px 25px; }
      .dashboard-subject-card .bg {
        max-width: 100%; }
      .dashboard-subject-card .bg {
        min-height: 300px; } }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.dashboard-page.manager .info {
  display: flex;
  margin-top: 5px; }
  @media screen and (max-width: 370px) {
    .dashboard-page.manager .info {
      flex-direction: column; }
      .dashboard-page.manager .info > a {
        margin-bottom: 25px; } }

.dashboard-page.manager .tile {
  background-color: #fff;
  padding: 30px;
  display: block; }
  .dashboard-page.manager .tile.over, .dashboard-page.manager .tile.attention, .dashboard-page.manager .tile.remaining, .dashboard-page.manager .tile.progress {
    color: #1a1a1a;
    text-align: center; }
    .dashboard-page.manager .tile.over span, .dashboard-page.manager .tile.attention span, .dashboard-page.manager .tile.remaining span, .dashboard-page.manager .tile.progress span {
      display: block; }
    .dashboard-page.manager .tile.over .custom-label, .dashboard-page.manager .tile.attention .custom-label, .dashboard-page.manager .tile.remaining .custom-label, .dashboard-page.manager .tile.progress .custom-label {
      font-size: 13px;
      font-family: "Poppins", sans-serif; }
  .dashboard-page.manager .tile.over i {
    color: #b80202; }
  .dashboard-page.manager .tile.remaining i {
    color: #ff9900; }
  .dashboard-page.manager .tile.progress i {
    color: #33ac2e; }
  .dashboard-page.manager .tile.compliance {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    color: #1a1a1a; }
    .dashboard-page.manager .tile.compliance span:not(.masked-icon) {
      padding-left: 20px;
      font-size: 25px;
      text-transform: capitalize;
      font-family: "Poppins", sans-serif; }
    @media all and (min-width: 569px) and (max-width: 768px) {
      .dashboard-page.manager .tile.compliance span:not(.masked-icon) {
        padding-left: 10px;
        font-size: 19px; } }
    @media all and (min-width: 481px) and (max-width: 568px) {
      .dashboard-page.manager .tile.compliance span:not(.masked-icon) {
        padding-left: 10px;
        font-size: 18px; } }
    @media all and (min-width: 321px) and (max-width: 480px) {
      .dashboard-page.manager .tile.compliance span:not(.masked-icon) {
        padding-left: 10px;
        font-size: 20px; } }
    @media all and (min-width: 0px) and (max-width: 320px) {
      .dashboard-page.manager .tile.compliance span:not(.masked-icon) {
        padding-left: 10px;
        font-size: 12px; } }
    .dashboard-page.manager .tile.compliance i {
      color: #0067ac; }
  .dashboard-page.manager .tile.billing {
    background-color: #0067ac; }
    .dashboard-page.manager .tile.billing i,
    .dashboard-page.manager .tile.billing span {
      color: white; }

.dashboard-page.manager .count {
  font-size: 40px;
  line-height: 55px; }

.dashboard-page.manager i {
  font-size: 4em; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.dashboard-page {
  max-width: 1140px;
  padding-top: 27px; }
  .dashboard-page .cart-wrap {
    display: block; }
    @media screen and (max-width: 991px) {
      .dashboard-page .cart-wrap {
        margin-bottom: 2rem !important; } }
  @media screen and (max-width: 1199px) {
    .dashboard-page .ui.grid > .row[class*="three column"].pages-carts > .column {
      width: 50% !important; }
      .dashboard-page .ui.grid > .row[class*="three column"].pages-carts > .column.cart-wrap {
        margin-bottom: 2rem !important; }
        .dashboard-page .ui.grid > .row[class*="three column"].pages-carts > .column.cart-wrap:last-child {
          margin-bottom: 0 !important; } }
  @media screen and (max-width: 767px) {
    .dashboard-page .ui.grid > .row[class*="three column"].pages-carts > .column {
      width: 100% !important; } }
  .dashboard-page h3 {
    font-size: 20px;
    font-weight: normal;
    line-height: 1.6; }
    .dashboard-page h3 span {
      display: block; }
    @media screen and (max-width: 767px) {
      .dashboard-page h3 {
        font-size: 18px; } }
  .dashboard-page .actions {
    margin-bottom: 27px;
    text-align: right; }
    .dashboard-page .actions .ui.button {
      width: auto;
      margin: 0;
      color: #0067ac;
      background-color: rgba(46, 91, 255, 0.2);
      font-weight: normal; }
      .dashboard-page .actions .ui.button:hover {
        background-color: rgba(46, 91, 255, 0.3); }
  .dashboard-page .bottom-cart {
    height: 100%;
    padding: 35px 25px;
    display: flex;
    background-color: #fff; }
    .dashboard-page .bottom-cart .info {
      margin-left: 20px; }
    .dashboard-page .bottom-cart h3 {
      margin: 0; }
    .dashboard-page .bottom-cart span {
      color: #606d7c;
      line-height: 28px; }
    .dashboard-page .bottom-cart i {
      font-size: 3em; }
    .dashboard-page .bottom-cart a {
      margin-top: 7px;
      display: inline-block;
      color: #0067ac; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.subjects-list .red {
  color: red;
  font-weight: bold; }

.subjects-list .tabs-view {
  margin-top: 30px; }
  .subjects-list .tabs-view a.active.item {
    background: #647283 !important;
    color: #fff !important; }
  .subjects-list .tabs-view .ui.bottom.attached.segment.active.tab {
    background: transparent;
    border: none; }

.subjects-list .ui.grid > .row {
  padding: 0; }
  .subjects-list .ui.grid > .row > .column {
    padding-top: 1rem;
    padding-bottom: 1rem; }

.subjects-list .ui.table tr.custom-tr {
  height: 75px !important;
  color: #2e384d !important;
  border-radius: 1px !important;
  background-color: #fff !important; }
  .subjects-list .ui.table tr.custom-tr .image {
    height: 50px;
    width: 50px;
    margin: 0 auto;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 100%; }
  @media (max-width: 768px) {
    .subjects-list .ui.table tr.custom-tr .image {
      height: 150px !important;
      width: 150px !important; } }

.subjects-list .ui.table th,
.subjects-list .ui.table td {
  width: auto; }
  .subjects-list .ui.table th:first-child,
  .subjects-list .ui.table td:first-child {
    width: 100px; }

.subjects-list .ui.search {
  width: 100%; }
  .subjects-list .ui.search input, .subjects-list .ui.search input:focus {
    border-right: none; }
  .subjects-list .ui.search input:focus + button {
    border-color: #0067ac; }
  .subjects-list .ui.search button {
    background-color: #fff;
    color: rgba(34, 36, 38, 0.15);
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-left: none;
    border-radius: 0.28571429rem;
    transition: border-color 0.1s ease;
    outline: none;
    pointer-events: none; }
    .subjects-list .ui.search button.active {
      color: #0067ac;
      cursor: pointer;
      pointer-events: auto; }

.customCursor {
  cursor: pointer; }

.session-icon {
  width: 50px;
  height: 50px;
  background: #64728345;
  text-align: center;
  border-radius: 50%; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.spacer {
  height: 10px; }

.ui .section-item {
  white-space: pre-wrap; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.subject-user-card {
  white-space: nowrap;
  background-color: #fff;
  margin-right: 30px;
  padding: 15px 24px; }
  .subject-user-card .photo {
    margin-right: 10px; }
  .subject-user-card > * {
    vertical-align: middle; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.certificate .page-title {
  margin-bottom: 15px; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.sections-to-export {
  width: 210mm; }

.single-subject.scorm-subject .certificate .certificate-item {
  min-width: 350px; }

.single-subject .title {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }
  @media screen and (max-width: 1199px) {
    .single-subject .title {
      flex-direction: column; } }

.single-subject .ui.basic.button.export-button {
  border: none;
  box-shadow: none;
  color: #0067ac !important;
  padding: 0;
  margin-top: 10px; }
  .single-subject .ui.basic.button.export-button:hover, .single-subject .ui.basic.button.export-button:focus {
    background-color: transparent !important; }
  .single-subject .ui.basic.button.export-button i {
    margin-left: 5px !important;
    color: #0067ac; }

.single-subject .info {
  max-width: 600px;
  flex: 1 1 auto; }
  .single-subject .info.flex {
    display: flex;
    align-items: center; }
    .single-subject .info.flex h1 {
      margin-top: 0; }
    @media screen and (max-width: 767px) {
      .single-subject .info.flex {
        flex-wrap: wrap; } }
  @media screen and (max-width: 767px) {
    .single-subject .info .subject-user-card {
      margin-right: 0;
      margin-bottom: 10px; } }
  @media screen and (max-width: 1199px) {
    .single-subject .info {
      max-width: none; } }

.single-subject i {
  padding-left: 4px; }
  .single-subject i.info {
    flex: auto; }

.single-subject .subject-progress {
  flex: 0 1 30%;
  max-width: 350px; }
  @media screen and (max-width: 1199px) {
    .single-subject .subject-progress {
      flex-basis: 100%;
      margin-top: 20px; } }
  @media screen and (max-width: 767px) {
    .single-subject .subject-progress {
      max-width: none; } }

.single-subject .actions {
  margin-top: 20px; }
  @media screen and (max-width: 767px) {
    .single-subject .actions .button-link {
      display: block; } }

@media screen and (max-width: 767px) {
  .single-subject .ui.table:not(.unstackable) tr > th:not(.mobile-cell-hide),
  .single-subject .ui.table:not(.unstackable) tr > td:not(.mobile-cell-hide) {
    display: flex !important;
    justify-content: space-between; } }

.single-subject .ui.table th {
  font-weight: initial !important; }

@media screen and (max-width: 767px) {
  .single-subject .ui.table tr.custom-tr {
    height: auto; } }

.single-subject .ui.table .mobile-cell-data {
  text-align: right; }
  .single-subject .ui.table .mobile-cell-data .link {
    display: block; }
  @media screen and (min-width: 768px) {
    .single-subject .ui.table .mobile-cell-data {
      display: none; } }

@media screen and (max-width: 767px) {
  .single-subject .ui.table .mobile-cell-hide {
    display: none !important; } }

.single-subject .ui.sections-table {
  margin-top: 35px; }
  .single-subject .ui.sections-table td:first-child {
    width: 60%; }

.single-subject .button-link {
  margin-right: 20px;
  display: inline-block; }

.single-subject .link {
  color: #0067ac;
  cursor: pointer; }

.single-subject .edit_end_date {
  background-color: transparent;
  color: #0067ac;
  border: none;
  cursor: pointer;
  outline: none; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.manage-outcomes .page-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0; }
  .manage-outcomes .page-title .page-title-text .fa-plus-square {
    color: #69707f; }
  .manage-outcomes .page-title .page-title-text .fa-info-circle {
    cursor: pointer; }
  .manage-outcomes .page-title > .ui {
    margin-left: 20px; }
    @media screen and (max-width: 500px) {
      .manage-outcomes .page-title > .ui {
        margin-left: 0;
        margin-top: 15px; } }
  @media screen and (max-width: 500px) {
    .manage-outcomes .page-title {
      flex-wrap: wrap; }
      .manage-outcomes .page-title > * {
        flex-basis: 100%; } }

.manage-outcomes h2 {
  margin: 0;
  font-size: 28px;
  font-weight: normal;
  line-height: 48px; }

.manage-outcomes .outcome-wrap.open .outcome-info {
  display: block; }
  .manage-outcomes .outcome-wrap.open .outcome-info .no-answer {
    margin-bottom: 30px; }

.manage-outcomes .outcome-item {
  margin-top: 8px;
  padding: 0 20px;
  display: flex;
  background-color: #fff;
  color: #2e384d;
  border: 1px solid rgba(46, 91, 255, 0.08);
  border-radius: 1px;
  overflow: hidden; }
  .manage-outcomes .outcome-item.title {
    margin-top: 0;
    padding: 0 20px;
    background-color: transparent;
    border: none; }
    .manage-outcomes .outcome-item.title .status {
      text-align: left; }
  .manage-outcomes .outcome-item .actions {
    flex: 0 0 60px;
    display: flex;
    align-items: center; }
  .manage-outcomes .outcome-item .ref {
    flex: 0 0 50px;
    display: flex;
    align-items: center; }
  .manage-outcomes .outcome-item .name {
    flex-grow: 1;
    position: relative;
    display: flex;
    align-items: center; }
  .manage-outcomes .outcome-item .status {
    display: flex;
    flex: 0 0 15px;
    text-align: center;
    color: #b0bac9; }
    .manage-outcomes .outcome-item .status i {
      font-size: 18px;
      margin-left: 10px;
      display: flex;
      align-items: center; }
  .manage-outcomes .outcome-item .custom-label {
    color: #8798ad;
    font-size: 12px; }
  .manage-outcomes .outcome-item .circle-status {
    width: 17px;
    height: 17px; }
  .manage-outcomes .outcome-item .view {
    margin-left: 20px;
    color: #8798ad;
    font-size: 20px; }
    .manage-outcomes .outcome-item .view i {
      vertical-align: top; }
    .manage-outcomes .outcome-item .view.done {
      color: #33ac2e; }
  .manage-outcomes .outcome-item .ui.checkbox.checked label::before {
    background-color: #0067ac;
    border-color: #0067ac; }
  .manage-outcomes .outcome-item .ui.checkbox.checked label::after {
    color: #fff; }

.manage-outcomes .outcome-info {
  display: none;
  border: 1px solid rgba(46, 91, 255, 0.08);
  border-radius: 1px;
  background-color: #fff;
  padding: 40px 45px; }
  .manage-outcomes .outcome-info .evidence-item + .evidence-item {
    margin-top: 20px; }
  .manage-outcomes .outcome-info .evidence-item .icon {
    display: inline-block;
    vertical-align: text-top;
    font-size: 20px;
    color: #8798ad;
    margin-right: 10px; }
  @media screen and (max-width: 767px) {
    .manage-outcomes .outcome-info {
      padding: 15px 20px; } }
  .manage-outcomes .outcome-info .answer-status {
    display: inline;
    float: right;
    margin-right: 115px;
    margin-left: 10px; }
  .manage-outcomes .outcome-info .answer-status:hover {
    position: relative; }
  .manage-outcomes .outcome-info .answer-icon {
    margin-left: 50px;
    margin-right: 25px; }
  .manage-outcomes .outcome-info .answer-icon:hover {
    position: relative; }
  .manage-outcomes .outcome-info .answer-label {
    display: inline;
    font-size: 10px;
    float: right;
    text-transform: none;
    margin-left: 20px; }

.manage-outcomes .info-item + .info-item {
  margin-top: 25px; }

.manage-outcomes .info-item .add_evidence {
  margin: 15px 0 10px; }

.manage-outcomes .info-item .learning-link {
  margin-left: 15px; }

.manage-outcomes .info-item h5 .blue-text {
  text-transform: none; }

.manage-outcomes .info-item h5 i {
  color: white; }

.manage-outcomes h5 {
  font-family: "Quicksand", sans-serif;
  font-size: 12px;
  line-height: 1.15;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1.12px;
  margin-bottom: 12px !important; }
  .manage-outcomes h5 .icon {
    display: inline-block;
    width: 14px;
    height: 14px;
    text-align: center;
    color: #fff;
    background-color: #0067ac;
    margin-right: 8px; }
  .manage-outcomes h5 i {
    vertical-align: top;
    font-size: 16px;
    line-height: 0.875; }

.manage-outcomes p {
  line-height: 1.45;
  margin: 0; }

.manage-outcomes ul {
  list-style: none;
  padding: 0;
  margin: 0; }
  .manage-outcomes ul li:not(.outcome-wrap) {
    display: flex;
    justify-content: space-between; }
    .manage-outcomes ul li:not(.outcome-wrap) p {
      flex: 1 1 auto; }
    .manage-outcomes ul li:not(.outcome-wrap) .circle-status {
      flex: 0 1 20px;
      min-width: 20px;
      margin-left: 15px; }

.manage-outcomes .manager-evidence > span {
  margin-right: 8px; }

.manage-outcomes .manager {
  display: inline-block;
  line-height: 35px; }
  .manage-outcomes .manager .ui.image.photo,
  .manage-outcomes .manager .photo {
    margin-right: 8px; }

.manage-outcomes .evidences-bottom-form {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #fff; }
  .manage-outcomes .evidences-bottom-form .container {
    padding-bottom: 0;
    overflow: visible;
    width: auto; }
    @media screen and (min-width: 992px) {
      .manage-outcomes .evidences-bottom-form .container {
        margin-left: 215px; } }
  .manage-outcomes .evidences-bottom-form .header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 56px;
    border: solid rgba(46, 91, 255, 0.08);
    border-width: 1px 0;
    background-color: #eaeeff; }
    .manage-outcomes .evidences-bottom-form .header .ui.button {
      max-width: 180px;
      font-size: 15px;
      font-weight: 600;
      padding: 10px;
      margin-right: 0; }
      .manage-outcomes .evidences-bottom-form .header .ui.button.is_open {
        color: #0067ac;
        border: 1px solid #0067ac;
        background-color: transparent; }
      @media screen and (min-width: 401px) {
        .manage-outcomes .evidences-bottom-form .header .ui.button {
          width: 100%; } }
  .manage-outcomes .evidences-bottom-form .count-items {
    display: inline-block;
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    margin-right: 12px; }
  .manage-outcomes .evidences-bottom-form form {
    padding-bottom: 40px; }
  .manage-outcomes .evidences-bottom-form .form-fields {
    display: flex;
    margin-top: 30px; }
    @media screen and (max-width: 767px) {
      .manage-outcomes .evidences-bottom-form .form-fields {
        flex-wrap: wrap; } }
  .manage-outcomes .evidences-bottom-form .field-wrap {
    min-width: 270px; }
    .manage-outcomes .evidences-bottom-form .field-wrap + .field-wrap {
      flex-grow: 1; }
      @media screen and (min-width: 768px) {
        .manage-outcomes .evidences-bottom-form .field-wrap + .field-wrap {
          margin-top: 0;
          margin-left: 20px; } }
    @media screen and (max-width: 767px) {
      .manage-outcomes .evidences-bottom-form .field-wrap {
        flex-basis: 100%; } }
  .manage-outcomes .evidences-bottom-form .ui.dropdown .default.text {
    color: #647283; }
  .manage-outcomes .evidences-bottom-form .ui.dropdown.selection {
    border: 1px solid #647283;
    width: 100%; }
  .manage-outcomes .evidences-bottom-form .field-label {
    color: #647283;
    font-size: 12px;
    font-weight: 700; }
  .manage-outcomes .evidences-bottom-form textarea {
    border-color: #647283; }
  .manage-outcomes .evidences-bottom-form .actions {
    margin-top: 10px; }
    @media screen and (min-width: 401px) {
      .manage-outcomes .evidences-bottom-form .actions .ui.button + .button {
        margin-left: 20px; } }
    @media screen and (max-width: 400px) {
      .manage-outcomes .evidences-bottom-form .actions .ui.button + .button {
        margin-top: 10px; } }
    @media screen and (max-width: 400px) {
      .manage-outcomes .evidences-bottom-form .actions .ui.button {
        width: 100%; } }
    @media screen and (max-width: 400px) {
      .manage-outcomes .evidences-bottom-form .actions {
        margin-top: 30px; } }

.manage-outcomes .evidences-list .evidence-item {
  padding: 17px 25px;
  background-color: #fff;
  transition: background-color 1s 2s ease-out; }
  .manage-outcomes .evidences-list .evidence-item.flesh {
    background-color: #ffa;
    transition: none; }
  .manage-outcomes .evidences-list .evidence-item + .evidence-item {
    margin-top: 8px; }
  .manage-outcomes .evidences-list .evidence-item .field-wrap {
    margin: 10px 0 5px; }
  .manage-outcomes .evidences-list .evidence-item button {
    margin: 15px 0; }

.manage-outcomes .evidences-list .top-items .dark-text,
.manage-outcomes .evidences-list .top-items .blue-text {
  margin-right: 20px; }

.manage-outcomes .evidences-list .top-items .custom-label {
  margin-right: 10px;
  font-size: 12px; }

.manage-outcomes .evidences-list .top-items i.edit {
  cursor: pointer; }

.manage-outcomes .evidences-list p {
  line-height: 30px; }

.manage-outcomes .evidences-list .view-more {
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none; }

.manage-outcomes .verify {
  margin: 0;
  padding: 35px 32px 40px; }
  .manage-outcomes .verify p {
    line-height: 30px; }

.manage-outcomes .scroll-top-wrap {
  padding-top: 20px; }

.outcomeCheck label:before,
.outcomeCheck label:after {
  width: 20px !important;
  height: 20px !important;
  border: 2px solid #23252559 !important;
  top: -2px !important;
  background-color: #ffffff !important; }

.outcomeCheck label:after {
  left: 0px !important;
  border-radius: 0.21428571rem;
  color: rgba(0, 0, 0, 0.95) !important; }

.customPadding {
  padding: 15px;
  vertical-align: middle;
  display: flex;
  align-items: center; }

.customTooltip {
  position: relative;
  min-width: min-content;
  z-index: auto;
  border: 1px solid #d4d4d5;
  line-height: 1.4285em;
  max-width: 250px;
  background: #fff;
  padding: 0.833em 1em;
  font-weight: 400;
  font-style: normal;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12), 0 2px 10px 0 rgba(34, 36, 38, 0.15);
  padding-right: 11px !important;
  margin-left: 10px; }

.customTooltip:before {
  position: absolute;
  content: '';
  width: 0.71428571em;
  height: 0.71428571em;
  background: #fff;
  transform: rotate(45deg);
  z-index: 2;
  top: 50%;
  left: -0.30714286em;
  bottom: auto;
  right: auto;
  margin-top: -0.30714286em;
  box-shadow: -1px 1px 0 0 #bababc; }

.session-evidence-icon {
  display: inline;
  float: right; }

.status-align {
  display: flex; }

.learning-asset-align {
  display: flex;
  grid-gap: 8px;
  gap: 8px;
  align-items: center;
  width: 180px;
  padding-right: 40px;
  position: relative; }

.learning-asset-align-asset {
  display: flex;
  grid-gap: 8px;
  gap: 8px;
  align-items: center;
  width: 130px; }

.learning-assestment-statuts {
  display: flex; }
  .learning-assestment-statuts .learning-status {
    width: 170px;
    color: #8798ad;
    text-transform: uppercase;
    font-size: 12px;
    display: flex;
    align-items: center;
    letter-spacing: 1.02px;
    line-height: 20px !important; }
  .learning-assestment-statuts .assessment-status {
    width: 130px;
    color: #8798ad;
    text-transform: uppercase;
    font-size: 12px;
    display: flex;
    align-items: center;
    letter-spacing: 1.02px;
    line-height: 20px !important; }

.learning-asset-icon {
  position: absolute;
  top: -27px;
  right: -60px; }

.name-card-icon {
  position: absolute;
  top: -37px;
  right: -25px; }

.flex-assesment {
  display: flex !important;
  grid-gap: 10px !important;
  gap: 10px !important;
  align-items: center; }

.flex-assesment-start {
  display: flex !important;
  grid-gap: 10px !important;
  gap: 10px !important;
  align-items: center !important; }

.assesment-icon-size {
  font-size: 28px !important; }

.textalignstart {
  text-align: start; }

.textalignend {
  text-align: end; }

.icon-slide {
  margin-left: 0 !important; }

@media screen and (max-width: 768px) {
  .hidden-sm, .name-card-icon, .name-card-icon, .learning-asset-icon {
    display: none; }
  .learning-asset-align {
    width: 40px; }
  .learning-asset-align-asset {
    width: -moz-fit-content;
    width: fit-content; }
  .learning-status {
    width: 72px; }
  .learning-assestment-statuts {
    display: flex;
    flex-direction: column;
    grid-gap: 2px;
    gap: 2px;
    width: 80px; }
  .status-align {
    display: flex !important;
    flex-direction: column !important;
    align-items: start !important;
    grid-gap: 15px !important;
    gap: 15px !important;
    justify-content: center;
    padding: 10px 0; }
  .list-outcomes .outcome-item .ref {
    flex: 0 0 30px; }
  .list-outcomes .outcome-item {
    padding: 0 10px !important; }
  .list-outcomes .outcome-item .ref {
    flex: 0 0 10px; }
  .list-outcomes .outcome-item .status i {
    margin-left: 0 !important; }
  svg {
    margin-left: 0 !important; }
  .li-empty-tag {
    display: none !important; }
  .list-outcomes .outcome-item .status {
    flex: 0 0 80px !important; } }

@media screen and (max-width: 1024px) {
  .name-card-icon, .svg-height {
    display: none; }
  .list-outcomes .outcome-item .ref {
    flex: 0 0 40px !important; } }

.view-learning-btn {
  color: green !important;
  text-align: start !important;
  padding: 0 !important;
  margin: 0 !important;
  background-color: transparent !important;
  font-weight: 400 !important;
  font-size: 15px !important;
  line-height: 22px !important; }

.margin-props {
  margin-right: 7px; }

.start-learning-btn {
  color: orange !important;
  text-align: start !important;
  padding: 0 !important;
  margin: 0 !important;
  background-color: transparent !important;
  font-weight: 400 !important;
  font-size: 15px !important;
  line-height: 22px !important; }

svg {
  margin-left: 10px; }

.svg-height {
  height: 140%;
  position: absolute;
  right: 50px;
  top: 10px; }

.outcome-list-name {
  padding: 20px 40px 20px 0;
  flex-grow: 1;
  position: relative; }

.outcome-item {
  padding: 0 20px !important; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.verify {
  margin-top: 30px;
  padding: 40px;
  background-color: #fff; }
  .verify p {
    line-height: 28px; }
  .verify .ui.button {
    width: 100%;
    max-width: 200px;
    margin-top: 20px; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.section-info .page-title.flex {
  display: flex;
  align-items: center; }
  .section-info .page-title.flex h1 {
    margin-top: 0;
    margin-right: 30px; }

.section-info .section-progress {
  margin-top: 0.5em;
  padding-top: 0.5em;
  line-height: 28px;
  border-top: 1px solid #CACED6; }
  .section-info .section-progress.manager {
    margin-top: 20px;
    border-top: none; }
  .section-info .section-progress span {
    font-weight: 600; }

.section-info .section-retake {
  margin-top: 0.5em;
  padding-top: 0.5em;
  line-height: 28px;
  border-top: 1px solid #CACED6;
  font-weight: 600;
  color: #FF0000; }

.section-info .actions {
  display: flex;
  align-items: center;
  margin-top: 40px; }
  .section-info .actions > .icon {
    vertical-align: middle;
    margin: 8px 0 0 8px;
    cursor: pointer; }
    @media screen and (max-width: 500px) {
      .section-info .actions > .icon {
        order: 2; } }
  .section-info .actions .button-link {
    flex: 0 1 auto; }
    @media screen and (max-width: 550px) {
      .section-info .actions .button-link {
        min-width: 150px; } }
    @media screen and (max-width: 500px) {
      .section-info .actions .button-link {
        flex-basis: 100%;
        order: 5;
        min-width: 0; }
        .section-info .actions .button-link:first-child {
          flex-basis: calc(100% - 35px);
          order: 1; } }
  @media screen and (max-width: 500px) {
    .section-info .actions {
      flex-wrap: wrap; } }

.section-info .button-link {
  vertical-align: middle;
  min-width: 200px;
  white-space: nowrap;
  margin-top: 8px; }
  .section-info .button-link[disabled] {
    opacity: .7;
    cursor: default; }
    .section-info .button-link[disabled].secondary {
      background-color: #33ac2e !important; }
  .section-info .button-link .icon {
    margin-left: 10px; }
  @media screen and (max-width: 500px) {
    .section-info .button-link {
      display: block;
      margin-left: 0 !important; } }

.ui.modal.instructions-modal > .content {
  font-size: 15px !important; }
  .ui.modal.instructions-modal > .content p {
    line-height: 1.85; }
  @media screen and (max-width: 767px) {
    .ui.modal.instructions-modal > .content {
      padding-top: 40px !important; } }

.mt_25 {
  margin-top: 25px !important; }

.mb_18 {
  margin-bottom: -18px !important; }

.btn-assessment-disable {
  background-color: #7bcf78 !important;
  cursor: none !important; }

.btn-assessment-enable {
  background-color: #33ac2e !important;
  cursor: pointer !important; }

.btn-assessment {
  vertical-align: middle;
  min-width: 200px;
  white-space: nowrap;
  margin-top: 8px !important;
  margin-left: 10px !important;
  height: 40px !important;
  padding: 0 20px;
  font-weight: 500 !important;
  background-color: #7bcf78 !important; }
  .btn-assessment .icon {
    margin-left: 10px; }

.btn-not-allowed {
  cursor: not-allowed !important;
  pointer-events: all !important; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.section-learn .menu-wrap {
  width: 100%;
  margin: 0;
  padding: 0;
  flex: 0 1 260px;
  background-color: #ebeef6;
  list-style: none; }

.section-learn .menu-item {
  position: relative; }
  .section-learn .menu-item.active, .section-learn .menu-item:hover {
    background-color: #fff; }
    .section-learn .menu-item.active::before, .section-learn .menu-item:hover::before {
      content: '';
      width: 5px;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: #0067ac; }
    .section-learn .menu-item.active .link, .section-learn .menu-item:hover .link {
      color: #0067ac; }

.section-learn .bookmarks {
  margin-top: 15px;
  padding-left: 30px; }
  .section-learn .bookmarks i {
    margin-right: 10px;
    display: inline-block;
    color: #0067ac;
    font-size: 24px;
    vertical-align: middle; }
  .section-learn .bookmarks a {
    padding: 15px 0;
    color: #606d7c; }

.section-learn .link {
  height: 56px;
  padding-left: 30px;
  display: block;
  color: #69707f;
  line-height: 56px; }
  .section-learn .link.active {
    color: #0067ac; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.outcome-content {
  width: 100%;
  padding: 35px 10px 35px 75px;
  flex: 1 1 auto;
  background-color: #fff; }
  .outcome-content .content-item {
    max-width: 90%;
    position: relative; }
    .outcome-content .content-item + .content-item {
      margin-top: 35px;
      padding-top: 35px;
      border-top: 1px solid #BFC5D2; }
    .outcome-content .content-item:first-of-type .bookmark {
      top: 5px; }
    .outcome-content .content-item p {
      max-width: 90%; }
    .outcome-content .content-item .bookmark {
      position: absolute;
      top: 40px;
      right: 0;
      background-color: transparent;
      color: #0067ac;
      font-size: 26px;
      border: none;
      cursor: pointer;
      outline: none; }
  .outcome-content .actions {
    margin-top: 85px;
    display: flex;
    justify-content: space-between; }
    .outcome-content .actions .button-link {
      width: 100%;
      max-width: 200px; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.section-learn {
  height: 100%;
  margin-top: 20px; }
  .section-learn .back-link i {
    margin-right: 8px;
    display: inline-block;
    vertical-align: middle; }

.outcomes-wrap {
  padding-top: 20px;
  height: 100%;
  display: flex; }
  .outcomes-wrap iframe {
    border: none;
    height: 100%; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.archive-info {
  background-color: white;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  padding-right: 30%; }

.info-row {
  display: flex;
  min-width: calc(50% - 20px);
  margin-bottom: 18px;
  margin-right: 20px; }
  .info-row span:first-of-type {
    min-width: 140px;
    font-size: 13px;
    color: #B0BAC9;
    text-transform: uppercase;
    font-weight: bold; }
  .info-row span {
    font-size: 15px;
    color: #454D60; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.outcome-card-wrapper {
  margin-top: 10px; }
  .outcome-card-wrapper .title {
    color: white;
    font-size: 15px;
    font-weight: bold;
    padding: 8px 20px;
    border-radius: 3px; }
  .outcome-card-wrapper .evidence {
    background-color: #72AB0F; }
  .outcome-card-wrapper .viewed {
    background-color: #9D5995; }
  .outcome-card-wrapper .observation {
    background-color: #A2ECF2; }
  .outcome-card-wrapper .outcome-card-body {
    background-color: white;
    padding: 21px; }
    .outcome-card-wrapper .outcome-card-body .outcome-card-item {
      display: flex;
      margin-bottom: 24px;
      color: #454D60; }
      .outcome-card-wrapper .outcome-card-body .outcome-card-item span:first-of-type {
        min-width: 155px;
        display: block;
        font-size: 13px;
        color: #B0BAC9;
        font-weight: bold;
        text-transform: uppercase; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.outcome-labels {
  display: flex;
  margin-bottom: 18px; }
  .outcome-labels .outcome-label {
    display: block;
    color: white;
    font-size: 15px;
    font-weight: bold;
    border-radius: 3px;
    padding: 8px 20px;
    margin-right: 12px; }
    .outcome-labels .outcome-label:first-of-type {
      background-color: #0067AC; }
    .outcome-labels .outcome-label:nth-of-type(2) {
      background-color: #F2C830; }
    .outcome-labels .outcome-label:nth-of-type(3) {
      background-color: #72AB0F;
      margin-right: 0; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.archive-outcome {
  border-bottom: 1px solid #0067AC;
  margin-bottom: 34px; }
  .archive-outcome .outcome-card {
    background-color: white;
    padding: 21px;
    margin-bottom: 10px; }
    .archive-outcome .outcome-card:last-of-type {
      margin-bottom: 30px; }
    .archive-outcome .outcome-card .outcome-card-item {
      display: flex;
      margin-bottom: 24px;
      color: #454D60; }
      .archive-outcome .outcome-card .outcome-card-item .card-item-list {
        list-style-type: none;
        padding: 0;
        margin: 0;
        font-size: 15px;
        line-height: 22px;
        counter-reset: item; }
        .archive-outcome .outcome-card .outcome-card-item .card-item-list li.bold {
          font-weight: bold; }
        .archive-outcome .outcome-card .outcome-card-item .card-item-list li::before {
          content: counter(item) ") ";
          counter-increment: item; }
      .archive-outcome .outcome-card .outcome-card-item span:first-of-type {
        min-width: 155px;
        display: block;
        font-size: 13px;
        color: #B0BAC9;
        font-weight: bold;
        text-transform: uppercase; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.archive-outcomes {
  margin-top: 51px; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.archive-inputs .ui.table thead th {
  background-color: #19bd9c !important;
  color: white !important;
  font-weight: 900 !important;
  font-size: 14px !important;
  text-transform: capitalize !important;
  white-space: nowrap; }

.archive-inputs .ui.table tbody tr td {
  border-bottom: 1px solid #999 !important; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.learning-archive .page-title {
  font-size: 20px;
  margin-bottom: 32px;
  font-weight: 500;
  color: #2e384d; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.report-broken-link.ui.vertical.animated.button {
  background-color: transparent;
  width: 146px;
  position: fixed;
  bottom: 20px;
  right: 20px; }
  .report-broken-link.ui.vertical.animated.button .hidden.content {
    display: block;
    color: black;
    white-space: nowrap; }
  .report-broken-link.ui.vertical.animated.button:hover {
    background-color: #0094d1;
    color: white; }
    .report-broken-link.ui.vertical.animated.button:hover .hidden.content {
      color: inherit; }

.report-broken-link__feedback {
  padding: 0 20px; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.subject-page {
  font-size: 15px;
  color: #606d7c;
  flex: 0 1 100%; }
  .subject-page .title p {
    line-height: 28px; }
  .subject-page h1 {
    font-weight: normal;
    color: #2e384d; }

.obs-module-outcomes li {
  cursor: pointer; }
  .obs-module-outcomes li:hover {
    text-decoration: underline; }

.obs-module-outcomes.obs-module-outcomes--readonly li {
  cursor: pointer; }
  .obs-module-outcomes.obs-module-outcomes--readonly li:hover {
    text-decoration: underline; }

.obs-pagination-container {
  display: 'flex';
  justify-content: 'center'; }
  .obs-pagination-container .pagination {
    flex-wrap: wrap; }
    .obs-pagination-container .pagination .item.active {
      background-color: orange !important;
      color: white !important; }
    .obs-pagination-container .pagination .item[data-obs-content-viewed="true"] {
      background-color: green;
      color: white; }

.obs-lesson-feedback_toggle-trigger {
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.assessment-instructions .content {
  background-color: #fff;
  padding: 40px 0;
  margin-top: 50px; }
  .assessment-instructions .content h3 {
    font-family: "Quicksand", sans-serif;
    font-size: 20px;
    line-height: 1.6;
    font-weight: normal; }
  .assessment-instructions .content p {
    line-height: 1.85; }
  .assessment-instructions .content .ui.grid {
    margin: 0; }
    .assessment-instructions .content .ui.grid > .column {
      padding: 1rem 50px; }
      .assessment-instructions .content .ui.grid > .column + .column {
        border-left: 1px solid #caced6; }
        @media screen and (max-width: 767px) {
          .assessment-instructions .content .ui.grid > .column + .column {
            border-top: 1px solid #caced6;
            border-left: none; } }
      @media screen and (max-width: 767px) {
        .assessment-instructions .content .ui.grid > .column {
          padding: 50px; } }
      @media screen and (max-width: 500px) {
        .assessment-instructions .content .ui.grid > .column {
          padding: 25px; } }
  .assessment-instructions .content .ui.button {
    margin: 5px; }
    .assessment-instructions .content .ui.button.secondary {
      display: block;
      width: 100%;
      max-width: 200px;
      margin: 50px auto 0; }
  @media screen and (max-width: 767px) {
    .assessment-instructions .content {
      padding: 0; } }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.questions-wrap .content {
  margin-top: 32px;
  padding: 45px 0;
  background-color: #fff; }
  .questions-wrap .content .ui.grid {
    margin: 0; }
    .questions-wrap .content .ui.grid > .column {
      padding: 0 45px; }
      .questions-wrap .content .ui.grid > .column + .column {
        border-left: 1px solid #caced6; }
        @media screen and (max-width: 767px) {
          .questions-wrap .content .ui.grid > .column + .column {
            border-top: 1px solid #caced6;
            border-left: none; } }
      @media screen and (max-width: 767px) {
        .questions-wrap .content .ui.grid > .column {
          padding: 50px; } }
      @media screen and (max-width: 500px) {
        .questions-wrap .content .ui.grid > .column {
          padding: 25px; } }
  .questions-wrap .content .ui.checkbox + .checkbox {
    margin-top: 30px; }
  .questions-wrap .content .title {
    color: #0067ac;
    font-size: 20px;
    line-height: 32px; }
  .questions-wrap .content .question {
    margin-top: 8px;
    color: #2e384d;
    font-size: 28px;
    line-height: 48px; }
  .questions-wrap .content form {
    margin-top: 20px; }
    .questions-wrap .content form label {
      text-transform: none; }
    @media screen and (max-width: 767px) {
      .questions-wrap .content form {
        margin-top: 0; } }
  .questions-wrap .content span.label {
    margin-bottom: 20px;
    display: inline-block;
    color: #2e384d;
    font-size: 16px;
    line-height: 32px; }
  .questions-wrap .content .depends-wrap {
    margin-top: 35px;
    padding-top: 22px;
    border-top: 1px solid #CACED6; }
    .questions-wrap .content .depends-wrap .title {
      color: #2e384d;
      font-size: 16px;
      line-height: 32px; }
    .questions-wrap .content .depends-wrap p {
      margin-bottom: 20px;
      color: #606d7c;
      line-height: 30px; }
  .questions-wrap .content .actions {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 150px; }
    .questions-wrap .content .actions .ui.button:last-child {
      margin-right: 0; }
    @media screen and (max-width: 1199px) {
      .questions-wrap .content .actions > * {
        flex: 0 1 100%;
        margin-bottom: .25em; }
        .questions-wrap .content .actions > *:last-child {
          margin-bottom: 0; }
      .questions-wrap .content .actions > div {
        display: flex; }
        .questions-wrap .content .actions > div > * {
          flex: 0 1 50%; } }
  @media screen and (max-width: 1199px) and (max-width: 370px) {
    .questions-wrap .content .actions > div {
      flex-wrap: wrap; }
      .questions-wrap .content .actions > div > * {
        flex: 0 1 100%;
        margin-right: 0;
        margin-bottom: .25em; }
        .questions-wrap .content .actions > div > *:last-child {
          margin-bottom: 0; } }
  .questions-wrap .content .custom-radio + .custom-radio {
    margin-top: 23px; }
  .questions-wrap .content .custom-checkbox {
    display: block; }
  @media screen and (max-width: 767px) {
    .questions-wrap .content {
      padding: 0; } }

.outcome-questions-count {
  display: flex;
  flex-direction: column; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.certificates-list .available-certificates {
  margin-top: 55px; }

.certificates-list h2 {
  line-height: 41px;
  font-weight: normal;
  color: #2e384d; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.user-info-form {
  width: 100%;
  max-width: 600px;
  margin-top: 40px;
  padding: 30px;
  background-color: #fff; }
  .user-info-form .actions {
    text-align: right; }
  .user-info-form .ui.button {
    margin-top: 20px; }

.user-info-form__actions {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 10px;
  gap: 10px; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.circle-status {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-color: #bfc5d2;
  text-align: center;
  line-height: 24px;
  border-radius: 50%; }
  .circle-status.success {
    background-color: #33ac2e; }
  .circle-status.danger {
    background-color: #ee7410; }
  .circle-status.error {
    background-color: #d0021b; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

#root .single-user .user-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 30px 40px; }
  @media screen and (max-width: 767px) {
    #root .single-user .user-card {
      flex-wrap: wrap; }
      #root .single-user .user-card > div {
        flex-basis: 100%; }
        #root .single-user .user-card > div:first-child {
          margin-bottom: 30px; }
        #root .single-user .user-card > div:nth-child(3) {
          margin-top: 15px; } }

#root .single-user .user-name {
  display: block;
  font-size: 20px;
  line-height: 1.6;
  color: #2e384d;
  margin-top: 8px; }

#root .single-user .info-item + .info-item {
  margin-top: 15px; }

#root .single-user .user-account {
  max-width: 110px; }

#root .single-user .custom-label {
  display: block; }

#root .single-user .actions {
  flex: 0 1 100% !important;
  max-width: 180px; }
  #root .single-user .actions .button {
    width: 100%;
    margin-right: 0; }
    #root .single-user .actions .button + .button {
      margin-top: 10px; }
  @media screen and (max-width: 767px) {
    #root .single-user .actions {
      max-width: none;
      margin-top: 30px; } }

#root .single-user .ui .button {
  width: 100%;
  max-width: 180px;
  display: block; }
  #root .single-user .ui .button + .button {
    margin-top: 17px; }

#root .single-user .ui.user-subjects-table {
  margin-top: 40px; }
  @media screen and (max-width: 767px) {
    #root .single-user .ui.user-subjects-table tr.custom-tr {
      height: auto; } }
  @media screen and (min-width: 768px) {
    #root .single-user .ui.user-subjects-table th:nth-child(1),
    #root .single-user .ui.user-subjects-table td:nth-child(1) {
      width: 30% !important; }
    #root .single-user .ui.user-subjects-table th:nth-child(2),
    #root .single-user .ui.user-subjects-table td:nth-child(2) {
      width: 25% !important; }
    #root .single-user .ui.user-subjects-table th:nth-child(3),
    #root .single-user .ui.user-subjects-table td:nth-child(3) {
      width: 15% !important; }
    #root .single-user .ui.user-subjects-table th:nth-child(4),
    #root .single-user .ui.user-subjects-table td:nth-child(4) {
      width: 25% !important; }
    #root .single-user .ui.user-subjects-table th:nth-child(5),
    #root .single-user .ui.user-subjects-table td:nth-child(5) {
      width: 5% !important; } }
  @media screen and (max-width: 767px) {
    #root .single-user .ui.user-subjects-table th,
    #root .single-user .ui.user-subjects-table td {
      text-align: center; }
      #root .single-user .ui.user-subjects-table th:first-child,
      #root .single-user .ui.user-subjects-table td:first-child {
        font-weight: bold;
        margin-bottom: 0 !important; }
      #root .single-user .ui.user-subjects-table th button,
      #root .single-user .ui.user-subjects-table td button {
        padding-left: 0;
        padding-right: 0; } }
  #root .single-user .ui.user-subjects-table th i.info {
    vertical-align: middle;
    font-size: 18px;
    padding-left: 5px; }
  #root .single-user .ui.user-subjects-table th span {
    vertical-align: middle; }
  @media screen and (max-width: 767px) {
    #root .single-user .ui.user-subjects-table th:last-child {
      display: none !important; } }
  @media screen and (max-width: 767px) {
    #root .single-user .ui.user-subjects-table td:nth-child(3) {
      max-width: 200px;
      margin-left: auto;
      margin-right: auto; } }

#root .single-user .ui.table.user-learning-archive-table tr.custom-tr {
  height: 55px; }

#root .single-user .ui.table.user-learning-archive-table tr td:last-child {
  width: 12%; }

#root .single-user .user-learning-archive .header .input {
  background: transparent; }

#root .single-user .user-learning-archive .header .icon {
  color: #bfc5d2; }

#root .single-user .user-subjects,
#root .single-user .user-learning-archive {
  margin-top: 70px;
  overflow-x: scroll; }
  #root .single-user .user-subjects .table-sort-toggle.center,
  #root .single-user .user-learning-archive .table-sort-toggle.center {
    margin: auto; }
  #root .single-user .user-subjects .tabs-view,
  #root .single-user .user-learning-archive .tabs-view {
    margin-top: 30px; }
    #root .single-user .user-subjects .tabs-view a.active.item,
    #root .single-user .user-learning-archive .tabs-view a.active.item {
      background: #647283 !important;
      color: #fff !important; }
    #root .single-user .user-subjects .tabs-view .ui.bottom.attached.segment.active.tab,
    #root .single-user .user-learning-archive .tabs-view .ui.bottom.attached.segment.active.tab {
      min-height: 100px !important;
      background: transparent;
      border: none; }

#root .single-user .header {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  #root .single-user .header .left-part {
    display: flex;
    align-items: center; }
  #root .single-user .header .link {
    cursor: pointer;
    margin-left: 25px;
    font-size: 16px; }

#root .single-user h3 {
  margin: 0;
  color: #2e384d;
  font-size: 20px;
  font-weight: normal; }

.user-activity .header {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.user-activity .export-btn {
  width: 100%;
  max-width: 95px;
  height: 40px;
  color: #33ac2e;
  border: 1px solid #33ac2e;
  border-radius: 4px;
  outline: none;
  background-color: transparent;
  transition: all 0.1s;
  cursor: pointer; }
  .user-activity .export-btn:hover {
    background-color: #33ac2e;
    color: #fff; }
  .user-activity .export-btn i {
    display: inline-block;
    margin-right: 10px; }

.user-activity .activity-list {
  list-style: none;
  padding: 0;
  margin: 20px 0 0 0; }

.user-activity .activity-item {
  display: flex; }
  .user-activity .activity-item > * {
    flex: 0 1 auto; }
  .user-activity .activity-item .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    text-align: center;
    line-height: 32px;
    border-radius: 50%;
    background-color: #ccc;
    margin-right: 10px; }
    .user-activity .activity-item .icon.auth {
      color: #bfc5d2;
      background-color: #d6dffe; }
    .user-activity .activity-item .icon.began {
      color: #26c1d4;
      background-color: #ccf3f7; }
    .user-activity .activity-item .icon.viewed {
      color: #8c54ff;
      background-color: #f7d7db; }
    .user-activity .activity-item .icon.completed {
      color: #33ac2e;
      background-color: #d7efd6; }
  .user-activity .activity-item .custom-label {
    display: block; }

.subject-user-card .ui.image.photo.avatar {
  width: 3em;
  height: 3em;
  margin-right: 10px; }

.subject-status-wrap {
  display: flex;
  justify-content: center;
  align-items: center; }
  .subject-status-wrap .circle-status {
    margin-right: 10px; }

.edit-user-modal .field-wrap {
  margin-top: 23px; }

.signoff-box h2 {
  margin: 0;
  font-size: 28px;
  font-weight: normal;
  line-height: 48px; }

.signoff-box p {
  padding: 20px;
  background: #fff; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.ui.modal.large.add-users-modal > .header {
  font-size: 28px;
  font-weight: 400;
  border-bottom: none;
  padding-bottom: 0; }

.ui.modal.large.add-users-modal .content {
  padding-top: 15px; }

.ui.modal.large.add-users-modal .repeater-field tr.add-item-row button {
  margin-bottom: 15px;
  background-color: #0067ac;
  color: #ffffff;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: 500; }

.ui.modal.large.add-users-modal .users-list {
  border-bottom: 1px solid #E0E7FF; }

.ui.modal.large.add-users-modal .btn-color {
  color: #ffffff !important; }

.btn-color {
  color: #ffffff !important; }

.react-toast-notifications__toast__content {
  overflow: scroll;
  max-height: 700px; }

.group-fields-pt {
  padding-top: 0 !important; }

.ui.dimmer .ui.loader:after {
  border-color: #7d7d9b transparent transparent !important; }

.bundle-dropdown-cls {
  margin-bottom: 10px !important; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.users-list .page-title {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .users-list .page-title .actions button {
    margin-left: 20px; }

.users-list .ui.table th span {
  vertical-align: middle; }

.users-list .ui.table tr.custom-tr {
  height: 75px; }
  .users-list .ui.table tr.custom-tr.inactive {
    background-color: rgba(255, 0, 0, 0.2); }

.users-list .ui.selection.custom-dropdown {
  min-width: 150px;
  background-color: transparent; }

.users-list .ui.checkbox {
  vertical-align: text-bottom; }

.users-list .ui.avatar.image,
.users-list .user-photo {
  margin-right: 10px;
  display: inline-block; }

.users-list .users-list-status {
  display: flex;
  align-items: center; }
  .users-list .users-list-status .circle-status {
    margin-right: 10px; }

.users-filter form {
  margin-top: 20px; }

.users-filter .ui.search {
  width: calc(100% - 28px); }
  .users-filter .ui.search input, .users-filter .ui.search input:focus {
    border-right: none; }
  .users-filter .ui.search input:focus + button {
    border-color: #0067ac; }
  .users-filter .ui.search button {
    background-color: transparent;
    color: rgba(34, 36, 38, 0.15);
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-left: none;
    border-radius: 0.28571429rem;
    transition: border-color 0.1s ease;
    outline: none;
    pointer-events: none; }
    .users-filter .ui.search button.active {
      color: #0067ac;
      cursor: pointer;
      pointer-events: auto; }

.users-filter .ui.button {
  margin-top: 30px;
  margin-right: 10px; }
  .users-filter .ui.button:last-child {
    margin-right: 0; }

.customCheck label:before, .customCheck label:after {
  width: 20px !important;
  height: 20px !important;
  border: 2px solid #23252559 !important;
  top: -3px !important; }

.customCheck label:after {
  top: 0px !important;
  left: 1px !important;
  border: 0px !important; }

.IrreversibleConfirmation_confirmationContent__1YUhW {
  padding: 1rem; }

.IrreversibleConfirmation_confirmationHelperText__f8edW {
  margin-top: 2rem;
  font-weight: 600; }

.IrreversibleConfirmation_confirmationActionContainer__1vP6D {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem; }

.IrreversibleConfirmation_confirmationActionContainer__1vP6D > div {
  margin-bottom: 0; }

.IrreversibleConfirmation_confirmationActionContainer__1vP6D input {
  width: inherit; }

.ui.modal.UpdateDueDateModal_confirm__2S2vq > .content {
  background-color: #fff; }

.ui.modal.UpdateDueDateModal_confirm__2S2vq > .actions {
  background: #f9fafb;
  padding: 1rem 1rem;
  border-top: 1px solid rgba(34, 36, 38, 0.15);
  text-align: right; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.book-session-btns {
  display: flex !important;
  flex-direction: row-reverse !important;
  flex-wrap: nowrap !important; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.activity-page .ui.table thead.sorting button {
  padding-left: 0;
  padding-right: 0; }

.activity-page .ui.table th,
.activity-page .ui.table td {
  width: auto; }
  .activity-page .ui.table th:first-child,
  .activity-page .ui.table td:first-child {
    width: 20px; }
  .activity-page .ui.table th:nth-child(2),
  .activity-page .ui.table td:nth-child(2) {
    width: 25%; }
    .activity-page .ui.table th:nth-child(2) > *,
    .activity-page .ui.table td:nth-child(2) > * {
      vertical-align: middle; }
  .activity-page .ui.table th:nth-child(3),
  .activity-page .ui.table td:nth-child(3) {
    width: 15%; }
  .activity-page .ui.table th:nth-child(4),
  .activity-page .ui.table td:nth-child(4) {
    width: 15%; }
  .activity-page .ui.table th:nth-child(5),
  .activity-page .ui.table td:nth-child(5) {
    width: 100px; }
  .activity-page .ui.table th:nth-child(6),
  .activity-page .ui.table td:nth-child(6) {
    width: 100px; }
  .activity-page .ui.table th:nth-child(7),
  .activity-page .ui.table td:nth-child(7) {
    width: 100px; }
  .activity-page .ui.table th:last-child,
  .activity-page .ui.table td:last-child {
    width: 65px; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.ui.modal.large.add-users-modal > .header {
  font-size: 28px;
  font-weight: 400;
  border-bottom: none;
  padding-bottom: 0; }

.ui.modal.large.add-users-modal .content {
  padding-top: 15px; }

.ui.modal.large.add-users-modal .repeater-field tr.add-item-row button {
  margin-bottom: 15px;
  background-color: #0067ac;
  color: #ffffff;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: 500; }

.ui.modal.large.add-users-modal .users-list {
  border-bottom: 1px solid #E0E7FF; }

.ui.modal.large.add-users-modal .group-fields {
  padding-top: 25px; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.ui.modal.large.add-users-modal > .header {
  font-size: 28px;
  font-weight: 400;
  border-bottom: none;
  padding-bottom: 0; }

.ui.modal.large.add-users-modal .content {
  padding-top: 15px; }

.ui.modal.large.add-users-modal .repeater-field tr.add-item-row button {
  margin-bottom: 15px;
  background-color: #0067ac;
  color: #ffffff;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: 500; }

.ui.modal.large.add-users-modal .users-list {
  border-bottom: 1px solid #E0E7FF; }

.ui.modal.large.add-users-modal .group-fields {
  padding-top: 25px; }

.ui.modal.large.add-users-modal .btn-color {
  color: #ffffff !important; }

.btn-color {
  color: #ffffff !important; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.users-list.assessor .ui.table th,
.users-list.assessor .ui.table td {
  width: auto; }
  .users-list.assessor .ui.table th:first-child,
  .users-list.assessor .ui.table td:first-child {
    width: 30%; }
  .users-list.assessor .ui.table th:nth-child(3),
  .users-list.assessor .ui.table td:nth-child(3) {
    width: 15%; }
  .users-list.assessor .ui.table th:nth-child(4), .users-list.assessor .ui.table th:nth-child(5),
  .users-list.assessor .ui.table td:nth-child(4),
  .users-list.assessor .ui.table td:nth-child(5) {
    width: 11%; }
  .users-list.assessor .ui.table th .user-link,
  .users-list.assessor .ui.table td .user-link {
    display: inline-block;
    cursor: pointer; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.users-list .page-title {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .users-list .page-title .actions > .ui.button {
    margin-right: 0; }
    @media screen and (min-width: 401px) {
      .users-list .page-title .actions > .ui.button {
        margin-left: 10px !important; } }
  @media screen and (max-width: 400px) {
    .users-list .page-title .actions > .ui {
      width: 100%;
      max-width: none;
      margin: 0; }
      .users-list .page-title .actions > .ui.button {
        margin-bottom: 15px; } }
  @media screen and (max-width: 400px) {
    .users-list .page-title .actions {
      display: flex;
      flex-direction: column-reverse;
      width: 100%; } }
  @media screen and (max-width: 767px) {
    .users-list .page-title {
      flex-direction: column; } }

.users-list .ui.selection.custom-dropdown {
  min-width: 150px;
  background-color: transparent; }

.users-list .ui.table tr.custom-tr {
  height: 75px !important; }
  .users-list .ui.table tr.custom-tr.inactive {
    background-color: rgba(255, 0, 0, 0.2) !important; }

.users-list .ui.checkbox {
  vertical-align: text-bottom; }

.users-list .ui.avatar.image,
.users-list .user-photo {
  margin-right: 10px;
  display: inline-block; }

.select-all-popup {
  display: flex;
  flex-direction: column; }
  .select-all-popup div {
    padding: 5px 0; }

.users-filter form {
  margin-top: 20px; }

.users-filter .ui.button {
  margin-top: 30px;
  margin-right: 10px; }
  .users-filter .ui.button:last-child {
    margin-right: 0; }

.users-filter .ui.search {
  width: calc(100% - 28px); }
  .users-filter .ui.search input,
  .users-filter .ui.search input:focus {
    border-right: none; }
  .users-filter .ui.search input:focus + button {
    border-color: #0067ac; }
  .users-filter .ui.search button {
    background-color: transparent;
    color: rgba(34, 36, 38, 0.15);
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-left: none;
    border-radius: 0.28571429rem;
    transition: border-color 0.1s ease;
    outline: none;
    pointer-events: none; }
    .users-filter .ui.search button.active {
      color: #0067ac;
      cursor: pointer;
      pointer-events: auto; }

.create-user-modal .field-wrap {
  margin-top: 23px; }

.customCheck label:before, .customCheck label:after {
  width: 20px !important;
  height: 20px !important;
  border: 2px solid #23252559 !important;
  top: -3px !important; }

.customCheck label:after {
  top: -1px !important;
  left: 0px !important;
  border: 0px !important; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.ui.modal.small.add-users-modal > .users-list {
  border-bottom: 1px solid #e0e7ff; }

.ui.modal.small.add-users-modal .group-fields {
  padding-top: 25px; }

.tag-listing {
  padding: 30px;
  background: #ececec;
  cursor: pointer !important;
  text-align: center; }

.custom-column {
  padding-left: 0 !important;
  padding-right: 0 !important;
  border: 1px solid #ccc;
  margin-top: -1px !important;
  margin-left: -1px !important; }

.users-list .field-wrap .ui.selection.dropdown {
  width: 100%; }

.users-list .field-wrap + .field-wrap {
  margin-top: 0; }

.users-list .field-wrap.date-input-wrap {
  margin-bottom: 0 !important; }

.users-list .upload {
  position: relative;
  cursor: pointer;
  height: 2.5rem; }
  .users-list .upload input {
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    top: 0;
    bottom: 0;
    z-index: 9; }
  .users-list .upload .upload-custom {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 5;
    height: 2.5rem;
    padding: 0.5rem 1rem;
    line-height: 1.5;
    color: #555;
    background-color: #fff;
    border: 0.075rem solid #ddd;
    border-radius: 0.25rem;
    box-shadow: inset 0 0.2rem 0.4rem rgba(0, 0, 0, 0.05);
    -webkit-user-select: none;
    user-select: none; }
  .users-list .upload .upload-custom:before {
    position: absolute;
    top: -0.075rem;
    right: -0.075rem;
    bottom: -0.075rem;
    z-index: 6;
    display: block;
    content: 'Browse';
    height: 2.5rem;
    padding: 0.5rem 1rem;
    line-height: 1.5;
    color: #555;
    background-color: #eee;
    border: 0.075rem solid #ddd;
    border-radius: 0 0.25rem 0.25rem 0; }
  .users-list .upload .upload-custom:after {
    content: 'Choose file...'; }
  .users-list .upload b {
    position: absolute;
    z-index: 6;
    font-size: 12px;
    color: #727e8e;
    background-color: white;
    top: 1px;
    bottom: 1px;
    left: 13px;
    padding: 6px;
    width: 80%;
    overflow: hidden; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.session-details-list .no-wrap {
  white-space: nowrap; }

.session-details-list .flex {
  display: flex; }

.session-details-list .justify-content-between {
  justify-content: space-between; }

.session-details-list .align-items-center {
  align-items: center; }

.session-details-list .w-100 {
  width: 100%; }

.session-details-list h2 {
  line-height: 41px;
  font-weight: normal;
  color: #2e384d; }

.session-details-list .descriptions-table {
  border-collapse: collapse !important;
  table-layout: fixed !important; }
  .session-details-list .descriptions-table td {
    border: 0px solid #a8a8a8 !important;
    background: #fff !important;
    border-right: 15px solid #f4f6fd !important; }
  .session-details-list .descriptions-table .spacer {
    height: 15px !important; }
  .session-details-list .descriptions-table a,
  .session-details-list .descriptions-table span {
    margin-left: 30px; }

.session-details-list .table-session {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  border-collapse: collapse; }
  .session-details-list .table-session thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #a8a8a8; }
  .session-details-list .table-session th {
    padding: 0.75rem; }
  .session-details-list .table-session td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #a8a8a8;
    text-align: center; }

.session-details-list .table-bordered {
  border: 1px solid #a8a8a8; }
  .session-details-list .table-bordered thead th {
    border-bottom-width: 2px; }
  .session-details-list .table-bordered th {
    border: 1px solid #a8a8a8; }
  .session-details-list .table-bordered td {
    border: 1px solid #a8a8a8; }

.clr-blue {
  color: #0067ac !important;
  text-decoration: underline;
  display: inline;
  cursor: pointer; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.session-date {
  border: 1px solid rgba(34, 36, 38, 0.15);
  margin-bottom: 15px;
  padding: 8px 15px;
  border-radius: 3px; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.ui.modal.small.add-users-modal > .users-list {
  border-bottom: 1px solid #e0e7ff; }

.ui.modal.small.add-users-modal .group-fields {
  padding-top: 25px; }

.tag-listing {
  padding: 30px;
  background: #ececec;
  cursor: pointer !important;
  text-align: center; }

.custom-column {
  padding-left: 0 !important;
  padding-right: 0 !important;
  border: 1px solid #ccc;
  margin-top: -1px !important;
  margin-left: -1px !important; }

.users-list .field-wrap .ui.selection.dropdown {
  width: 100%; }

.users-list .field-wrap + .field-wrap {
  margin-top: 0; }

.users-list .field-wrap.date-input-wrap {
  margin-bottom: 0 !important; }

.warning {
  max-width: 450px;
  text-align: justify;
  display: inline !important;
  margin-left: 20px !important;
  margin-top: 30px !important; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.list-outcomes {
  list-style: none;
  padding: 0;
  margin: 0; }
  .list-outcomes li.outcome-item.obs-outcome-item {
    display: grid;
    grid-template-columns: 50px 1fr 200px 95px;
    align-items: center; }
    .list-outcomes li.outcome-item.obs-outcome-item .outcome-list-content, .list-outcomes li.outcome-item.obs-outcome-item .outcome-learning-status {
      position: relative;
      padding-right: 90px; }
      .list-outcomes li.outcome-item.obs-outcome-item .outcome-list-content p, .list-outcomes li.outcome-item.obs-outcome-item .outcome-learning-status p {
        line-height: 1.45;
        margin: 0; }
      .list-outcomes li.outcome-item.obs-outcome-item .outcome-list-content .bi-chevron-compact-right, .list-outcomes li.outcome-item.obs-outcome-item .outcome-learning-status .bi-chevron-compact-right {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
        height: calc(100% + 64px);
        width: 150px;
        margin-top: -32px; }
    .list-outcomes li.outcome-item.obs-outcome-item .outcome-learning-status {
      z-index: 2; }
      .list-outcomes li.outcome-item.obs-outcome-item .outcome-learning-status .view-learning-btn {
        display: grid;
        grid-template-columns: auto auto 1fr;
        grid-gap: 15px;
        gap: 15px;
        align-items: center;
        background: none;
        border: none;
        text-align: left;
        color: green;
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;
        font-family: Quicksand, sans-serif;
        transform: translateX(-40px);
        position: relative;
        z-index: 2; }
        .list-outcomes li.outcome-item.obs-outcome-item .outcome-learning-status .view-learning-btn.outcome-learning-status_viewed {
          cursor: pointer; }
        .list-outcomes li.outcome-item.obs-outcome-item .outcome-learning-status .view-learning-btn.outcome-learning-status_not-viewed {
          color: #b0bac9; }
        .list-outcomes li.outcome-item.obs-outcome-item .outcome-learning-status .view-learning-btn.outcome-learning-status_start-learning {
          color: orange;
          cursor: pointer; }
          .list-outcomes li.outcome-item.obs-outcome-item .outcome-learning-status .view-learning-btn.outcome-learning-status_start-learning .fa-fade {
            fill: #ff9500;
            animation-delay: 0s;
            animation-direction: normal;
            animation-duration: 1s;
            animation-iteration-count: infinite;
            animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
            animation-name: fa-fade; }
        .list-outcomes li.outcome-item.obs-outcome-item .outcome-learning-status .view-learning-btn .margin-props {
          margin-right: 7px;
          fill: #0067ac; }
    .list-outcomes li.outcome-item.obs-outcome-item .outcome-learning-stats {
      color: #b0bac9;
      padding-right: 50px;
      white-space: nowrap; }
      .list-outcomes li.outcome-item.obs-outcome-item .outcome-learning-stats .outcome-learning-stats_started {
        display: flex;
        align-items: center;
        justify-content: center;
        grid-gap: 10px;
        gap: 10px;
        color: green; }
  .list-outcomes li:not(.outcome-wrap) {
    display: flex;
    justify-content: space-between; }
    .list-outcomes li:not(.outcome-wrap) + li {
      margin-top: 14px; }
    .list-outcomes li:not(.outcome-wrap) p {
      flex: 1 1 auto; }
    .list-outcomes li:not(.outcome-wrap) .circle-status {
      flex: 0 1 20px;
      min-width: 20px;
      margin-left: 15px; }
  .list-outcomes.checkbox.checked label::before {
    background-color: #0067ac;
    border-color: #0067ac; }
  .list-outcomes.checkbox.checked label::after {
    color: #fff; }
  .list-outcomes h2 {
    margin: 0;
    font-size: 28px;
    font-weight: normal;
    line-height: 48px; }
  .list-outcomes .outcome-wrap.open .outcome-info {
    display: block; }
    .list-outcomes .outcome-wrap.open .outcome-info .no-answer {
      margin-bottom: 30px; }
  .list-outcomes .outcome-item {
    margin-top: 8px;
    padding: 16px 20px;
    display: flex;
    background-color: #fff;
    color: #2e384d;
    border: 1px solid rgba(46, 91, 255, 0.08);
    border-radius: 1px; }
    .list-outcomes .outcome-item.title {
      margin-top: 0;
      padding: 0 20px;
      background-color: transparent;
      border: none; }
      .list-outcomes .outcome-item.title .status {
        text-align: left; }
    .list-outcomes .outcome-item .actions {
      flex: 0 0 60px; }
    .list-outcomes .outcome-item .ref {
      flex: 0 0 50px; }
    .list-outcomes .outcome-item .name {
      flex-grow: 1; }
    .list-outcomes .outcome-item .status {
      display: flex;
      flex: 0 0 15px;
      text-align: center;
      color: #b0bac9; }
      .list-outcomes .outcome-item .status i {
        font-size: 18px;
        margin-left: 10px; }
    .list-outcomes .outcome-item .custom-label {
      color: #8798ad;
      font-size: 12px; }
    .list-outcomes .outcome-item .circle-status {
      width: 17px;
      height: 17px; }
    .list-outcomes .outcome-item .view {
      margin-left: 20px;
      color: #8798ad;
      font-size: 20px; }
      .list-outcomes .outcome-item .view i {
        vertical-align: top; }
      .list-outcomes .outcome-item .view.done {
        color: #33ac2e; }
    .list-outcomes .outcome-item .ui.checkbox.checked label::before {
      background-color: #0067ac;
      border-color: #0067ac; }
    .list-outcomes .outcome-item .ui.checkbox.checked label::after {
      color: #fff; }
  .list-outcomes .outcome-info {
    display: none;
    border: 1px solid rgba(46, 91, 255, 0.08);
    border-radius: 1px;
    background-color: #fff;
    padding: 40px 45px; }
    .list-outcomes .outcome-info .evidence-item + .evidence-item {
      margin-top: 20px; }
    .list-outcomes .outcome-info .evidence-item .icon {
      display: inline-block;
      vertical-align: text-top;
      font-size: 20px;
      color: #8798ad;
      margin-right: 10px; }
    @media screen and (max-width: 767px) {
      .list-outcomes .outcome-info {
        padding: 15px 20px; } }
    .list-outcomes .outcome-info .answer-status {
      display: inline;
      float: right;
      margin-right: 115px;
      margin-left: 10px; }
    .list-outcomes .outcome-info .answer-status:hover {
      position: relative; }
    .list-outcomes .outcome-info .answer-icon {
      margin-left: 50px;
      margin-right: 25px; }
    .list-outcomes .outcome-info .answer-icon:hover {
      position: relative; }
    .list-outcomes .outcome-info .answer-label {
      display: inline;
      font-size: 10px;
      float: right;
      text-transform: none;
      margin-left: 20px; }
  .list-outcomes .info-item + .info-item {
    margin-top: 25px; }
  .list-outcomes .info-item .add_evidence {
    margin: 15px 0 10px; }
  .list-outcomes .info-item .learning-link {
    margin-left: 15px; }
  .list-outcomes .info-item h5 .blue-text {
    text-transform: none; }
  .list-outcomes .info-item h5 i {
    color: white; }
  .list-outcomes h5 {
    font-family: "Quicksand", sans-serif;
    font-size: 12px;
    line-height: 1.15;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1.12px;
    margin-bottom: 12px !important; }
    .list-outcomes h5 .icon {
      display: inline-block;
      width: 14px;
      height: 14px;
      text-align: center;
      color: #fff;
      background-color: #0067ac;
      margin-right: 8px; }
    .list-outcomes h5 i {
      vertical-align: top;
      font-size: 16px;
      line-height: 0.875; }
  .list-outcomes p {
    line-height: 1.45;
    margin: 0; }
  .list-outcomes ul {
    list-style: none;
    padding: 0;
    margin: 0; }
    .list-outcomes ul li:not(.outcome-wrap) {
      display: flex;
      justify-content: space-between; }
      .list-outcomes ul li:not(.outcome-wrap) + li {
        margin-top: 14px; }
      .list-outcomes ul li:not(.outcome-wrap) p {
        flex: 1 1 auto; }
      .list-outcomes ul li:not(.outcome-wrap) .circle-status {
        flex: 0 1 20px;
        min-width: 20px;
        margin-left: 15px; }

.cross-reference-modal .outcomeCheck label:before,
.cross-reference-modal .outcomeCheck label:after {
  width: 20px !important;
  height: 20px !important;
  border: 2px solid #23252559 !important;
  top: -2px !important;
  background-color: #ffffff !important; }

.cross-reference-modal .outcomeCheck label:after {
  left: 0px !important;
  border-radius: 0.21428571rem;
  color: rgba(0, 0, 0, 0.95) !important; }

.cross-reference-modal .customPadding {
  padding: 15px;
  vertical-align: middle;
  display: flex;
  align-items: center; }

.cross-reference-modal .customTooltip {
  position: relative;
  min-width: min-content;
  z-index: auto;
  border: 1px solid #d4d4d5;
  line-height: 1.4285em;
  max-width: 250px;
  background: #fff;
  padding: 0.833em 1em;
  font-weight: 400;
  font-style: normal;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12), 0 2px 10px 0 rgba(34, 36, 38, 0.15);
  padding-right: 11px !important;
  margin-left: 10px; }

.cross-reference-modal .customTooltip:before {
  position: absolute;
  content: '';
  width: 0.71428571em;
  height: 0.71428571em;
  background: #fff;
  transform: rotate(45deg);
  z-index: 2;
  top: 50%;
  left: -0.30714286em;
  bottom: auto;
  right: auto;
  margin-top: -0.30714286em;
  box-shadow: -1px 1px 0 0 #bababc; }

.cross-reference-modal .ui.checkbox input.hidden + label {
  margin-top: -30px; }

.cross-reference-modal .outcome-status {
  display: inline;
  float: right; }

.cross-reference-modal .column.subject-name-div {
  background: #0067ac;
  padding: 10px;
  margin: 12px;
  color: #ffffff; }

.outcome-select {
  background: none !important;
  padding: 0 !important;
  margin: 0 !important;
  width: 100% !important; }

.cross-reference-modal .list-outcomes .outcome-item .name {
  text-align: justify; }

@keyframes fa-fade {
  50% {
    opacity: .4; } }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.evidences-list-wrap {
  margin-top: 15px;
  background-color: white;
  padding-top: 30px; }
  .evidences-list-wrap .ui.grid > .row {
    padding-top: initial;
    padding-bottom: initial; }
    .evidences-list-wrap .ui.grid > .row .column:last-child {
      padding-right: 25px; }
  .evidences-list-wrap h2 {
    margin: 0;
    margin-left: 25px;
    font-size: 15px;
    font-weight: bold; }
  .evidences-list-wrap .evidences-list {
    padding-inline-start: 0px; }
    .evidences-list-wrap .evidences-list .evidence-item {
      min-height: 60px;
      padding: 17px 25px;
      background-color: #fff;
      transition: background-color 1s 2s ease-out; }
      .evidences-list-wrap .evidences-list .evidence-item.flesh {
        background-color: #ffa;
        transition: none; }
      .evidences-list-wrap .evidences-list .evidence-item + .evidence-item {
        margin-top: 8px; }
      .evidences-list-wrap .evidences-list .evidence-item .field-wrap {
        margin: 10px 0 5px; }
      .evidences-list-wrap .evidences-list .evidence-item button {
        margin: 15px 0; }
    .evidences-list-wrap .evidences-list .top-items .dark-text,
    .evidences-list-wrap .evidences-list .top-items .blue-text {
      margin-right: 60px; }
    .evidences-list-wrap .evidences-list .top-items .custom-label {
      margin-right: 10px;
      font-size: 12px; }
    .evidences-list-wrap .evidences-list .top-items i.edit {
      cursor: pointer; }
    .evidences-list-wrap .evidences-list p {
      line-height: 30px;
      margin-top: 15px; }
    .evidences-list-wrap .evidences-list i.trash.icon {
      margin-left: 20px; }
    .evidences-list-wrap .evidences-list .view-more {
      padding: 0;
      background-color: transparent;
      border: none;
      cursor: pointer;
      outline: none; }

.clr-black {
  color: black;
  display: inline;
  cursor: pointer; }

.cursor-pointer {
  cursor: pointer; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.task-form .margin-30 {
  margin-top: 30px; }

.task-form h2 {
  line-height: 41px;
  font-weight: normal;
  color: #2e384d; }

.task-form table {
  border-collapse: collapse !important;
  table-layout: fixed !important; }
  .task-form table td {
    border: 1px solid #a8a8a8 !important; }

.task-form .field {
  margin-bottom: 20px;
  position: relative; }
  .task-form .field.error label {
    color: red; }
  .task-form .field.error input {
    color: red;
    border-color: red;
    background-color: #fff6f6; }

.task-form label {
  margin-bottom: 5px;
  display: block;
  color: #1a1a1a;
  font-size: 11px;
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 1.02px; }

.task-form .field-wrap + .field-wrap {
  margin-top: 0; }

.task-form .field-wrap.date-input-wrap {
  margin-bottom: 15px !important; }

.task-form .upload {
  position: relative;
  cursor: pointer;
  height: 2.5rem; }
  .task-form .upload input {
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    top: 0;
    bottom: 0;
    z-index: 9; }
  .task-form .upload .upload-custom {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 5;
    height: 2.5rem;
    padding: 0.5rem 1rem;
    line-height: 1.5;
    color: #555;
    background-color: #fff;
    border: 0.075rem solid #ddd;
    border-radius: 0.25rem;
    box-shadow: inset 0 0.2rem 0.4rem rgba(0, 0, 0, 0.05);
    -webkit-user-select: none;
    user-select: none; }
  .task-form .upload .upload-custom:before {
    position: absolute;
    top: -0.075rem;
    right: -0.075rem;
    bottom: -0.075rem;
    z-index: 6;
    display: block;
    content: 'Browse';
    height: 2.5rem;
    padding: 0.5rem 1rem;
    line-height: 1.5;
    color: #555;
    background-color: #eee;
    border: 0.075rem solid #ddd;
    border-radius: 0 0.25rem 0.25rem 0; }
  .task-form .upload .upload-custom:after {
    content: 'Choose file...'; }
  .task-form .upload b {
    position: absolute;
    z-index: 6;
    font-size: 12px;
    color: #727e8e;
    background-color: white;
    top: 1px;
    bottom: 1px;
    left: 13px;
    padding: 6px;
    width: 80%;
    overflow: hidden; }

.ui.selection.dropdown .icon:not(.delete):not(.close) {
  position: absolute;
  right: 10px;
  z-index: 10;
  color: #0067ac;
  top: 50%;
  transform: translateY(-50%);
  margin: 0;
  padding: 0; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.task-list-wrap {
  margin-top: 15px;
  background-color: white;
  padding-top: 30px; }
  .task-list-wrap .ui.grid > .row {
    padding-top: initial;
    padding-bottom: initial; }
  .task-list-wrap h2 {
    margin: 0;
    margin-left: 25px;
    font-size: 15px;
    font-weight: bold; }
  .task-list-wrap .task-list {
    padding-inline-start: 0px; }
    .task-list-wrap .task-list .task-item {
      min-height: 60px;
      padding: 17px 25px;
      background-color: #fff;
      transition: background-color 1s 2s ease-out; }
      .task-list-wrap .task-list .task-item .task-name {
        font-size: 20px;
        padding: 0px 10px 0px 0px; }
      .task-list-wrap .task-list .task-item button.ui.small.fluid.button.task-add-evidence-btn {
        display: inline;
        width: 140px !important;
        float: right; }
      .task-list-wrap .task-list .task-item .evidence-div {
        line-height: 30px;
        margin: 10px 0px 0px 0px; }
        .task-list-wrap .task-list .task-item .evidence-div .evidence-item-div {
          background: #f3f6fb;
          padding: 10px;
          margin: 10px; }
          .task-list-wrap .task-list .task-item .evidence-div .evidence-item-div .edit-div {
            display: inline;
            margin-left: 10px; }
          .task-list-wrap .task-list .task-item .evidence-div .evidence-item-div .evidance-name {
            display: inline; }
          .task-list-wrap .task-list .task-item .evidence-div .evidence-item-div .document-div a p.blue-text {
            display: inline; }
      .task-list-wrap .task-list .task-item.flesh {
        background-color: #ffa;
        transition: none; }
      .task-list-wrap .task-list .task-item + .task-item {
        margin-top: 8px; }
      .task-list-wrap .task-list .task-item .field-wrap {
        margin: 10px 0 5px; }
      .task-list-wrap .task-list .task-item button {
        margin: 15px 0; }
      .task-list-wrap .task-list .task-item .status {
        margin: 18px 20px;
        float: right;
        font-size: 25px; }
    .task-list-wrap .task-list .top-items .dark-text,
    .task-list-wrap .task-list .top-items .blue-text {
      margin-right: 60px; }
    .task-list-wrap .task-list .top-items .custom-label {
      margin-right: 10px;
      font-size: 12px; }
    .task-list-wrap .task-list .top-items i.edit {
      cursor: pointer; }
    .task-list-wrap .task-list p {
      line-height: 30px;
      margin: 10px 0px 0px 0px; }
    .task-list-wrap .task-list i.trash.icon {
      margin-left: 20px; }
    .task-list-wrap .task-list .view-more {
      padding: 0;
      background-color: transparent;
      border: none;
      cursor: pointer;
      outline: none; }

.clr-black {
  display: inline;
  color: black;
  cursor: pointer; }

.cursor-pointer {
  cursor: pointer; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.users-page .ui.table th,
.users-page .ui.table td {
  width: auto; }
  .users-page .ui.table th:first-child,
  .users-page .ui.table td:first-child {
    width: 50px; }
    @media screen and (max-width: 767px) {
      .users-page .ui.table th:first-child,
      .users-page .ui.table td:first-child {
        margin-bottom: 1rem; } }
  .users-page .ui.table th:nth-child(2),
  .users-page .ui.table td:nth-child(2) {
    width: 30%; }
    .users-page .ui.table th:nth-child(2) > *,
    .users-page .ui.table td:nth-child(2) > * {
      vertical-align: middle; }
    @media screen and (max-width: 1199px) {
      .users-page .ui.table th:nth-child(2),
      .users-page .ui.table td:nth-child(2) {
        width: 22%; } }
  .users-page .ui.table th:nth-child(3),
  .users-page .ui.table td:nth-child(3) {
    width: 10%; }
  .users-page .ui.table th:nth-child(4),
  .users-page .ui.table td:nth-child(4) {
    width: 15%; }
  .users-page .ui.table th:nth-child(5),
  .users-page .ui.table td:nth-child(5) {
    width: 15%; }
  .users-page .ui.table th:nth-child(6),
  .users-page .ui.table td:nth-child(6) {
    width: 110px; }
  .users-page .ui.table th:nth-child(7),
  .users-page .ui.table td:nth-child(7) {
    font-size: 20px; }
    .users-page .ui.table th:nth-child(7) i,
    .users-page .ui.table td:nth-child(7) i {
      vertical-align: middle;
      font-size: 18px;
      padding-left: 5px; }
  .users-page .ui.table th:last-child,
  .users-page .ui.table td:last-child {
    width: 65px; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.bookmark-item {
  height: 100%;
  padding: 20px 20px 40px 20px;
  position: relative;
  background-color: #fff;
  color: #606d7c;
  font-size: 14px;
  border: 1px solid #fff;
  transition: all ease-in-out 0.1s; }
  .bookmark-item:hover {
    transform: scale(1.1);
    border: 1px solid #0067ac; }
  .bookmark-item h4 {
    color: #2e384d;
    text-align: center; }
  .bookmark-item p {
    margin-top: 10px; }
  .bookmark-item .info-item {
    display: flex;
    justify-content: space-between;
    font-size: 12px; }
    .bookmark-item .info-item span {
      color: #2e384d;
      text-transform: uppercase; }
      .bookmark-item .info-item span + span {
        color: #0067ac;
        text-transform: none; }
  .bookmark-item .link {
    position: absolute;
    bottom: 20px;
    right: 20px;
    color: #0067ac;
    font-size: 28px; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.group-manager .page-title {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .group-manager .page-title .actions {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end; }
    .group-manager .page-title .actions .field-wrap {
      margin-right: 20px; }
    .group-manager .page-title .actions > .ui.button {
      margin: 0;
      white-space: nowrap; }
    @media screen and (max-width: 400px) {
      .group-manager .page-title .actions > .ui {
        width: 100%;
        max-width: none;
        margin: 0; }
        .group-manager .page-title .actions > .ui.button {
          margin-bottom: 15px; } }
    @media screen and (max-width: 400px) {
      .group-manager .page-title .actions {
        display: flex;
        flex-direction: column-reverse;
        width: 100%; } }
  @media screen and (max-width: 767px) {
    .group-manager .page-title {
      flex-direction: column; } }

.group-manager .ui.search.fluid {
  max-width: 250px;
  width: 250px;
  margin-right: 10px; }

.group-manager .nestable-item.is-dragging::before {
  border: 1px dashed #606d7c;
  background-color: #b0bac9; }

.group-manager .nestable-item .nestable-list::before {
  content: '';
  position: absolute;
  top: 77px;
  left: 20px;
  width: 4px;
  height: calc(100% - 86px);
  background-color: #b0bac9; }

.group-manager .list-header {
  display: flex;
  font-size: 12px;
  text-transform: uppercase;
  color: #b0bac9;
  padding: 0 40px 0 20px;
  margin: 35px 0 8px; }
  .group-manager .list-header > div:first-child {
    width: 14px; }
  .group-manager .list-header > div:nth-child(2) {
    width: 30px; }
  .group-manager .list-header > div:last-child {
    display: flex;
    flex-grow: 1;
    padding-left: 30px; }
  .group-manager .list-header .group-users-count {
    flex-basis: calc(15% + 80px); }
  @media screen and (max-width: 767px) {
    .group-manager .list-header {
      padding: 0 20px; } }

.group-manager .drag-icon {
  color: #b0bac9;
  cursor: move; }
  .group-manager .drag-icon i {
    font-size: 20px; }

.group-manager .group-item {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 56px;
  background-color: #fff;
  padding: 0 40px 0 20px; }
  .group-manager .group-item .expand-wrapper {
    width: 40px; }
    .group-manager .group-item .expand-wrapper button.expand {
      padding-left: 10px; }
  .group-manager .group-item.not-active {
    opacity: .2; }
  @media screen and (max-width: 767px) {
    .group-manager .group-item {
      padding: 0 20px; } }

.group-manager .group-item-content {
  display: flex;
  flex-grow: 1;
  padding-left: 23px; }
  @media screen and (max-width: 767px) {
    .group-manager .group-item-content {
      flex-wrap: wrap;
      padding-left: 20px; } }

.group-manager .group-name {
  flex-grow: 1; }
  @media screen and (max-width: 767px) {
    .group-manager .group-name {
      flex-basis: 100%;
      font-weight: bold;
      margin-bottom: 5px; } }

.group-manager .group-id {
  flex-basis: 15%; }

.group-manager .group-user {
  flex-basis: 29%; }
  @media screen and (max-width: 767px) {
    .group-manager .group-user {
      flex-grow: 1;
      flex-basis: auto; } }

.group-manager .no-control .group-users-count {
  flex-basis: calc(15% + 80px); }

.group-manager .group-users-count {
  flex-basis: 15%; }

.group-manager .edit {
  flex: 0 1 80px;
  font-weight: 600;
  color: #0067ac;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer; }
  .group-manager .edit span {
    display: inline-block;
    margin-left: 5px; }
  @media screen and (max-width: 767px) {
    .group-manager .edit {
      flex-basis: auto;
      padding: 0; } }

.group-manager .mo {
  margin-left: 10px !important; }

.create-update-group-modal .actions {
  text-align: right;
  margin-top: 30px; }

.create-update-group-modal .ui.loader::after {
  border-top-color: #767676 !important; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.grid-wrap {
  margin: 20px 0;
  height: 100%;
  width: 100%; }
  .grid-wrap .pickerWrap {
    height: 450px; }
  .grid-wrap .cell {
    padding: 10px;
    text-align: center;
    transition: 0.3s ease-in-out all;
    display: flex;
    align-items: center;
    justify-content: center; }
    .grid-wrap .cell .tooltip {
      display: flex; }
    .grid-wrap .cell .field-wrap.date-input-wrap {
      margin-bottom: -20px !important; }
      .grid-wrap .cell .field-wrap.date-input-wrap .ui.popup.left.top {
        margin-bottom: 0; }
      .grid-wrap .cell .field-wrap.date-input-wrap .ui.popup td {
        padding: 0.5em; }
      .grid-wrap .cell .field-wrap.date-input-wrap .ui.popup th:last-child {
        padding-right: 0.5em !important; }
      .grid-wrap .cell .field-wrap.date-input-wrap .ui.popup th:first-child {
        padding-left: 0.5em !important; }
      .grid-wrap .cell .field-wrap.date-input-wrap .ui.popup.popup:before {
        display: none; }
    .grid-wrap .cell .field-wrap {
      width: 100%; }
      .grid-wrap .cell .field-wrap.disabled .link {
        pointer-events: none; }
    .grid-wrap .cell.count-cell {
      justify-content: space-between;
      height: 35px; }
      .grid-wrap .cell.count-cell .total {
        background-color: green;
        color: white;
        padding: 5px;
        margin-left: 5px;
        font-size: 1.2em; }
    .grid-wrap .cell.count-cell-right {
      justify-content: flex-end;
      height: 35px; }
    .grid-wrap .cell.active {
      background-color: #4b71ad !important; }
      .grid-wrap .cell.active i {
        color: white; }
    .grid-wrap .cell i {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 3px;
      font-size: 1.23em; }
    .grid-wrap .cell.white {
      background-color: white;
      color: black; }
    .grid-wrap .cell.red {
      background-color: #b80202;
      color: white; }
    .grid-wrap .cell.green {
      background-color: #238823;
      color: white; }
    .grid-wrap .cell.amber {
      background-color: #ff9900;
      color: white; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.filter-wrap {
  display: flex;
  align-items: flex-start;
  margin: 0 20px 0 0;
  flex-grow: 1;
  flex-wrap: wrap; }
  .filter-wrap .field-wrap {
    width: calc(16% - 16px);
    margin-top: 0;
    margin-right: 16px;
    margin-bottom: 10px;
    font-size: 12px;
    min-width: 120px;
    flex-grow: 1; }
    .filter-wrap .field-wrap .field-label {
      color: rgba(0, 0, 0, 0.87);
      display: inline-block;
      white-space: nowrap; }
    .filter-wrap .field-wrap .labelsWrap {
      overflow-y: scroll;
      height: 32px;
      min-height: 32px; }
    .filter-wrap .field-wrap .search {
      width: 100% !important; }
    .filter-wrap .field-wrap .clear {
      cursor: pointer;
      right: 5px !important; }
    .filter-wrap .field-wrap i {
      top: 10px; }
    .filter-wrap .field-wrap.small {
      width: calc(8% - 24px);
      min-width: 92px; }
    .filter-wrap .field-wrap.day {
      max-width: 92px; }
  .filter-wrap .field-wrap + .field-wrap {
    margin-top: 0; }
  .filter-wrap.is-learner .field-wrap.day {
    max-width: none; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.matrix-wrap {
  padding-top: 27px;
  display: flex;
  height: 100%;
  flex-direction: column; }
  .matrix-wrap .button-link {
    padding: 0px 30px;
    cursor: pointer;
    border: none; }
    .matrix-wrap .button-link:hover {
      background-color: #0094d1;
      color: #fff; }
  .matrix-wrap .matrix-header {
    display: flex;
    align-items: flex-start; }
    .matrix-wrap .matrix-header h1 {
      margin-right: 20px;
      color: #0067ac; }
  .matrix-wrap .matrix-filter-wrap {
    display: flex;
    align-items: flex-start; }
    .matrix-wrap .matrix-filter-wrap button {
      margin-top: 25px; }
  .matrix-wrap .loader-wrap {
    position: absolute;
    z-index: 50;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5); }

.training-matrix-intro-cta {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  font-weight: 600; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.group-compliance .page-title {
  display: flex;
  justify-content: space-between !important;
  align-items: center; }
  .group-compliance .page-title .actions {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end; }
    .group-compliance .page-title .actions .field-wrap {
      margin-right: 20px; }
    .group-compliance .page-title .actions > .ui.button {
      margin: 0;
      white-space: nowrap; }
    @media screen and (max-width: 400px) {
      .group-compliance .page-title .actions > .ui {
        width: 100%;
        max-width: none;
        margin: 0; }
        .group-compliance .page-title .actions > .ui.button {
          margin-bottom: 15px; } }
    @media screen and (max-width: 400px) {
      .group-compliance .page-title .actions {
        display: flex;
        flex-direction: column-reverse;
        width: 100%; } }
  @media screen and (max-width: 767px) {
    .group-compliance .page-title {
      flex-direction: column; } }

.group-compliance h1:first-child {
  margin-top: 6px; }

.group-compliance .ui.toggle.checkbox .box:before, .group-compliance .ui.toggle.checkbox label:before {
  width: 4.5rem;
  height: 2.5rem; }

.group-compliance .ui.toggle.checkbox .box:after, .group-compliance .ui.toggle.checkbox label:after {
  width: 2.5rem;
  height: 2.5rem; }

.group-compliance .ui.toggle.checkbox input {
  width: 5.5rem;
  height: 2.5rem; }

.group-compliance .ui.label {
  background-color: inherit;
  font-weight: bold;
  font-family: "Quicksand", sans-serif;
  font-size: 22px;
  padding: 0.6833em 0 0.4833em 1.5em; }

.group-compliance .minh {
  min-height: 30px; }

.group-compliance .nestable-list {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  -ms-user-drag: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none; }

.group-compliance .ui.search.fluid {
  max-width: 250px;
  width: 250px;
  margin-right: 10px; }

.group-compliance .nestable-item::before {
  border: 1px dashed #606d7c;
  background-color: #b0bac9; }

.group-compliance .list-header {
  display: flex;
  font-size: 12px;
  text-transform: uppercase;
  color: #b0bac9;
  padding: 0 40px 0 20px;
  margin: 35px 0 8px; }
  .group-compliance .list-header > div:first-child {
    width: 14px; }
  .group-compliance .list-header > div:nth-child(2) {
    width: 30px; }
  .group-compliance .list-header > div:last-child {
    display: flex;
    flex-grow: 1;
    padding-left: 30px; }
  .group-compliance .list-header .group-users-count {
    flex-basis: calc(15% + 80px); }
  @media screen and (max-width: 767px) {
    .group-compliance .list-header {
      padding: 0 20px; } }

.group-compliance .drag-icon {
  color: #b0bac9;
  cursor: move; }
  .group-compliance .drag-icon i {
    font-size: 20px; }

.group-compliance .group-item {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 56px;
  background-color: #fff;
  padding: 0 40px 0 20px; }
  .group-compliance .group-item .expand-wrapper {
    width: 40px; }
    .group-compliance .group-item .expand-wrapper button.expand {
      padding-left: 10px; }
  .group-compliance .group-item.not-active {
    opacity: .2; }
  @media screen and (max-width: 767px) {
    .group-compliance .group-item {
      padding: 0 20px; } }

.group-compliance .fullwidth-top {
  display: flex;
  width: 100%;
  padding: 0 40px 0 20px;
  margin: 35px 0 8px; }

.group-compliance .fullwidth-bottom {
  display: flex;
  width: 100%;
  padding: 0 40px 20px 20px; }

.group-compliance .group-item-content {
  display: flex;
  flex-grow: 1;
  padding-left: 23px; }
  @media screen and (max-width: 767px) {
    .group-compliance .group-item-content {
      flex-wrap: wrap;
      padding-left: 20px; } }

.group-compliance .comp-group-name {
  flex-basis: 40%;
  text-align: center;
  font-weight: bold; }
  @media screen and (max-width: 767px) {
    .group-compliance .comp-group-name {
      flex-basis: 100%;
      font-weight: bold;
      margin-bottom: 5px; } }

.group-compliance .comp-group-elearning {
  flex-basis: 33%;
  text-align: center;
  font-weight: bold; }
  @media screen and (max-width: 767px) {
    .group-compliance .comp-group-elearning {
      flex-grow: 1;
      flex-basis: auto; } }

.group-compliance .comp-group-sessions {
  flex-basis: 33%;
  text-align: center;
  font-weight: bold; }
  @media screen and (max-width: 767px) {
    .group-compliance .comp-group-sessions {
      flex-grow: 1;
      flex-basis: auto; } }

.group-compliance .comp-group-name-sub {
  flex-basis: 34%; }
  @media screen and (max-width: 767px) {
    .group-compliance .comp-group-name-sub {
      flex-basis: 100%;
      font-weight: bold;
      margin-bottom: 5px; } }

.group-compliance .comp-group-elearning-sub {
  flex-basis: 33%;
  text-align: center; }
  @media screen and (max-width: 767px) {
    .group-compliance .comp-group-elearning-sub {
      flex-grow: 1;
      flex-basis: auto; } }

.group-compliance .comp-group-sessions-sub {
  flex-basis: 33%;
  text-align: center; }
  @media screen and (max-width: 767px) {
    .group-compliance .comp-group-sessions-sub {
      flex-grow: 1;
      flex-basis: auto; } }

.group-compliance .smallCoulmWidth {
  width: 100%;
  display: flex; }

.group-compliance .group-t {
  width: 33%;
  text-align: center; }

.group-compliance .group-name {
  flex-grow: 1; }
  @media screen and (max-width: 767px) {
    .group-compliance .group-name {
      flex-basis: 100%;
      font-weight: bold;
      margin-bottom: 5px; } }

.group-compliance .group-user {
  flex-basis: 29%; }
  @media screen and (max-width: 767px) {
    .group-compliance .group-user {
      flex-grow: 1;
      flex-basis: auto; } }

.group-compliance .no-control .group-users-count {
  flex-basis: calc(15% + 80px); }

.group-compliance .group-users-count {
  flex-basis: 15%; }

.group-compliance .edit {
  flex: 0 1 80px;
  font-weight: 600;
  color: #0067ac;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer; }
  .group-compliance .edit span {
    display: inline-block;
    margin-left: 5px; }
  @media screen and (max-width: 767px) {
    .group-compliance .edit {
      flex-basis: auto;
      padding: 0; } }

.group-compliance .mo {
  margin-left: 10px !important; }

.create-update-group-modal .actions {
  text-align: right;
  margin-top: 30px; }

.create-update-group-modal .ui.loader::after {
  border-top-color: #767676 !important; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.setting-list h2 {
  line-height: 41px;
  font-weight: normal;
  color: #2e384d; }

.roles {
  padding: 20px;
  background: #ffffff;
  width: 250px;
  cursor: pointer; }

.padding-top-30 {
  margin-top: 30px; }

.app-setting-form {
  width: 60%; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.tag-list h2 {
  line-height: 41px;
  font-weight: normal;
  color: #2e384d;
  color: #2e384d;
  font-size: 28px; }

.tag-list h3 {
  line-height: 31px;
  font-weight: normal;
  color: #2e384d;
  color: #2e384d;
  font-size: 18px; }

.tag-list span {
  text-align: center;
  display: block; }

.tag-list .pagination-wrap {
  margin-top: 25px; }

.tag-list .delete-btn {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
  position: absolute;
  top: 8px;
  right: 22px;
  cursor: pointer;
  color: #ee1e92;
  border: 1px solid #aeaeae;
  border-radius: 30px;
  background: #f3f6fb;
  font-size: 18px;
  font-weight: 700;
  line-height: 0;
  padding: 11px 6px; }

.tag-list .custom-column-div:hover .delete-btn {
  visibility: visible;
  opacity: 1; }

.tag-list .custom-column-div {
  width: auto !important;
  min-width: 20%; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.ui.modal.small.add-users-modal > .users-list {
  border-bottom: 1px solid #e0e7ff; }

.ui.modal.small.add-users-modal .group-fields {
  padding-top: 25px; }

.tag-listing {
  padding: 10px 16px;
  background: #fff;
  cursor: pointer !important;
  text-align: center;
  border-radius: 14px;
  margin: 14px;
  font-size: 18px;
  font-weight: bold;
  border: 2px solid #647382; }

.custom-column {
  padding-left: 0 !important;
  padding-right: 0 !important;
  border: 1px solid #ccc;
  margin-top: -1px !important;
  margin-left: -1px !important;
  border: none; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.tags-listing h2 {
  line-height: 41px;
  font-weight: normal;
  color: #2e384d;
  color: #2e384d;
  font-size: 28px; }

.tags-listing h3 {
  line-height: 31px;
  font-weight: normal;
  color: #2e384d;
  color: #2e384d;
  font-size: 18px; }

.tags-listing span {
  text-align: center;
  display: block; }

.tags-listing .tabs-view {
  margin-top: 30px; }
  .tags-listing .tabs-view a.active.item {
    background: #647283 !important;
    color: #fff !important; }
  .tags-listing .tabs-view .ui.bottom.attached.segment.active.tab {
    min-height: 100px !important;
    background: transparent;
    border: none; }

.tags-listing .msg-line {
  line-height: 32px;
  text-align: center; }
  .tags-listing .msg-line i {
    font-size: 23px; }
  .tags-listing .msg-line div {
    display: inline;
    text-align: center;
    font-size: 16px; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.tag-list .tabs-view {
  margin-top: 30px; }
  .tag-list .tabs-view a.active.item {
    background: #647283 !important;
    color: #fff !important; }
  .tag-list .tabs-view .ui.bottom.attached.segment.active.tab {
    min-height: 100px !important;
    background: transparent;
    border: none; }

.tag-list .msg-line {
  text-align: center; }
  .tag-list .msg-line div {
    font-size: 18px;
    display: inline; }
  .tag-list .msg-line i {
    font-size: 18px; }

.tag-list .ui.search button {
  background-color: #fff;
  color: rgba(34, 36, 38, 0.15);
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-left: none;
  border-radius: 0.28571429rem;
  transition: border-color 0.1s ease;
  outline: none;
  pointer-events: none; }
  .tag-list .ui.search button.active {
    color: #0067ac;
    cursor: pointer;
    pointer-events: auto; }

.tag-list .ui.search input {
  border-right: none; }
  .tag-list .ui.search input:focus {
    border-right: none; }
    .tag-list .ui.search input:focus + button {
      border-color: #0067ac; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.users-list .courses-list .subject-checkbox-cell {
  width: 5% !important; }

.users-list .tabs-view {
  margin-top: 30px; }
  .users-list .tabs-view a.active.item {
    background: #647283 !important;
    color: #fff !important; }
  .users-list .tabs-view .ui.bottom.attached.segment.active.tab {
    min-height: 100px !important;
    background: transparent;
    border: none; }

.users-list .page-title {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .users-list .page-title .actions > .ui.button {
    margin-right: 0; }
    @media screen and (min-width: 401px) {
      .users-list .page-title .actions > .ui.button {
        margin-left: 20px; } }
  @media screen and (max-width: 400px) {
    .users-list .page-title .actions > .ui {
      width: 100%;
      max-width: none;
      margin: 0; }
      .users-list .page-title .actions > .ui.button {
        margin-bottom: 15px; } }
  @media screen and (max-width: 400px) {
    .users-list .page-title .actions {
      display: flex;
      flex-direction: column-reverse;
      width: 100%; } }
  @media screen and (max-width: 767px) {
    .users-list .page-title {
      flex-direction: column; } }

.users-list .ui.selection.custom-dropdown {
  min-width: 150px;
  background-color: transparent; }

.users-list .ui.table tr.custom-tr {
  height: 75px !important; }
  .users-list .ui.table tr.custom-tr.inactive {
    background-color: rgba(255, 0, 0, 0.2) !important; }

.users-list .ui.checkbox {
  vertical-align: text-bottom; }

.users-list .ui.avatar.image,
.users-list .user-photo {
  margin-right: 10px;
  display: inline-block; }

.select-all-popup {
  display: flex;
  flex-direction: column; }
  .select-all-popup div {
    padding: 5px 0; }

.users-filter form {
  margin-top: 20px; }

.users-filter .ui.button {
  margin-top: 30px;
  margin-right: 10px; }
  .users-filter .ui.button:last-child {
    margin-right: 0; }

.users-filter .ui.search {
  width: calc(100% - 28px); }
  .users-filter .ui.search input,
  .users-filter .ui.search input:focus {
    border-right: none; }
  .users-filter .ui.search input:focus + button {
    border-color: #0067ac; }
  .users-filter .ui.search button {
    background-color: transparent;
    color: rgba(34, 36, 38, 0.15);
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-left: none;
    border-radius: 0.28571429rem;
    transition: border-color 0.1s ease;
    outline: none;
    pointer-events: none; }
    .users-filter .ui.search button.active {
      color: #0067ac;
      cursor: pointer;
      pointer-events: auto; }

.create-user-modal .field-wrap {
  margin-top: 23px; }

.course-btn {
  margin-top: 20px;
  width: 100%; }

.refresh-period-td .field-wrap {
  margin-bottom: 0px !important; }

.customCheck label:before,
.customCheck label:after {
  width: 20px !important;
  height: 20px !important;
  border: 2px solid #23252559 !important;
  top: -3px !important; }

.customCheck label:after {
  top: -1px !important;
  left: 0px !important;
  border: 0px !important; }

.msg-line {
  line-height: 32px; }

.custom-tr.ui.popup {
  position: relative !important; }

.course-list-bundle {
  display: flex;
  grid-gap: 10px;
  gap: 10px; }

.course-bundle-row {
  display: grid !important;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  padding: 15px !important;
  grid-gap: 20px;
  gap: 20px; }

.bundle-info {
  display: flex;
  grid-gap: 50px;
  gap: 50px;
  flex-wrap: wrap; }

@media only screen and (max-width: 1024px) {
  .course-bundle-row {
    display: grid !important;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 20px;
    gap: 20px; }
  .bundle-info {
    grid-gap: 10px;
    gap: 10px; } }

@media only screen and (max-width: 576px) {
  .course-bundle-row {
    display: grid !important;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-gap: 20px;
    gap: 20px; } }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.topic-page .ui.table th,
.topic-page .ui.table td {
  width: auto; }
  .topic-page .ui.table th:first-child,
  .topic-page .ui.table td:first-child {
    width: 2%; }
    @media screen and (max-width: 767px) {
      .topic-page .ui.table th:first-child,
      .topic-page .ui.table td:first-child {
        margin-bottom: 1rem; } }
  .topic-page .ui.table th:nth-child(2),
  .topic-page .ui.table td:nth-child(2) {
    width: 7%; }
    .topic-page .ui.table th:nth-child(2) > *,
    .topic-page .ui.table td:nth-child(2) > * {
      vertical-align: middle; }
    @media screen and (max-width: 1199px) {
      .topic-page .ui.table th:nth-child(2),
      .topic-page .ui.table td:nth-child(2) {
        width: 50%; } }
  .topic-page .ui.table th:nth-child(3),
  .topic-page .ui.table td:nth-child(3) {
    width: 40%; }
  .topic-page .ui.table th:nth-child(4),
  .topic-page .ui.table td:nth-child(4) {
    width: 12%; }
  .topic-page .ui.table th:nth-child(5),
  .topic-page .ui.table td:nth-child(5) {
    width: 12%; }
  .topic-page .ui.table th:last-child,
  .topic-page .ui.table td:last-child {
    text-align: center; }

.users-list .all-topic-table {
  flex-wrap: wrap;
  flex-direction: column; }

.session-btn-width {
  width: -moz-fit-content !important;
  width: fit-content !important; }

.session-actions {
  width: -moz-fit-content !important;
  width: fit-content !important; }

.session-stack-gap {
  grid-gap: 10px;
  gap: 10px; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.ui.modal.large.add-users-modal > .header {
  font-size: 28px;
  font-weight: 400;
  border-bottom: none;
  padding-bottom: 0; }

.ui.modal.large.add-users-modal .content {
  padding-top: 15px; }

.ui.modal.large.add-users-modal .repeater-field tr.add-item-row button {
  margin-bottom: 15px;
  background-color: #0067ac;
  color: #ffffff;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: 500; }

.ui.modal.large.add-users-modal .users-list {
  border-bottom: 1px solid #E0E7FF; }

.ui.modal.large.add-users-modal .btn-color {
  color: #ffffff !important; }

.btn-color {
  color: #ffffff !important; }

.react-toast-notifications__toast__content {
  overflow: scroll;
  max-height: 700px; }

.session-fields-info {
  margin-top: 6px; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.topic-list h2 {
  line-height: 41px;
  font-weight: normal;
  color: #2e384d; }

.topic-list .description-table {
  border-collapse: collapse !important;
  table-layout: fixed !important; }
  .topic-list .description-table td {
    border: 0px solid #a8a8a8 !important;
    background: #fff !important;
    border-right: 15px solid #f4f6fd !important; }
  .topic-list .description-table .spacer {
    height: 15px !important; }
  .topic-list .description-table span {
    margin-left: 30px; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.users-page .ui.table th,
.users-page .ui.table td {
  width: auto; }
  .users-page .ui.table th:first-child,
  .users-page .ui.table td:first-child {
    width: 50px; }
    @media screen and (max-width: 767px) {
      .users-page .ui.table th:first-child,
      .users-page .ui.table td:first-child {
        margin-bottom: 1rem; } }
  .users-page .ui.table th:nth-child(2),
  .users-page .ui.table td:nth-child(2) {
    width: 30%; }
    .users-page .ui.table th:nth-child(2) > *,
    .users-page .ui.table td:nth-child(2) > * {
      vertical-align: middle; }
    @media screen and (max-width: 1199px) {
      .users-page .ui.table th:nth-child(2),
      .users-page .ui.table td:nth-child(2) {
        width: 22%; } }
  .users-page .ui.table th:nth-child(3),
  .users-page .ui.table td:nth-child(3) {
    width: 10%; }
  .users-page .ui.table th:nth-child(4),
  .users-page .ui.table td:nth-child(4) {
    width: 15%; }
  .users-page .ui.table th:nth-child(5),
  .users-page .ui.table td:nth-child(5) {
    width: 15%; }
  .users-page .ui.table th:nth-child(6),
  .users-page .ui.table td:nth-child(6) {
    width: 110px; }
  .users-page .ui.table th:nth-child(7),
  .users-page .ui.table td:nth-child(7) {
    font-size: 20px; }
    .users-page .ui.table th:nth-child(7) i,
    .users-page .ui.table td:nth-child(7) i {
      vertical-align: middle;
      font-size: 18px;
      padding-left: 5px; }
  .users-page .ui.table th:last-child,
  .users-page .ui.table td:last-child {
    width: 65px; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.users-page h2 {
  line-height: 41px;
  font-weight: normal;
  color: #2e384d; }

.users-page .session-dropdown {
  float: right; }

.users-page .ui.table th,
.users-page .ui.table td {
  width: auto; }
  .users-page .ui.table th:first-child,
  .users-page .ui.table td:first-child {
    width: 50px; }
    @media screen and (max-width: 767px) {
      .users-page .ui.table th:first-child,
      .users-page .ui.table td:first-child {
        margin-bottom: 1rem; } }
  .users-page .ui.table th:nth-child(2),
  .users-page .ui.table td:nth-child(2) {
    width: 12%; }
  .users-page .ui.table th:nth-child(3),
  .users-page .ui.table td:nth-child(3) {
    width: 12%; }
  .users-page .ui.table th:nth-child(4),
  .users-page .ui.table td:nth-child(4) {
    width: 15%; }
  .users-page .ui.table th:nth-child(5),
  .users-page .ui.table td:nth-child(5) {
    width: 11%; }
  .users-page .ui.table th:nth-child(6),
  .users-page .ui.table td:nth-child(6) {
    width: 12%; }
  .users-page .ui.table th:nth-child(7),
  .users-page .ui.table td:nth-child(7) {
    width: 12%;
    font-size: 15px; }
  .users-page .ui.table th:last-child,
  .users-page .ui.table td:last-child {
    text-align: center; }

.users-list .all-topic-table {
  flex-wrap: wrap;
  flex-direction: column; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.session-list .margin-30 {
  margin-top: 30px; }

.session-list table.ui.celled.table {
  width: 48.8%; }

.session-list h2 {
  line-height: 41px;
  font-weight: normal;
  color: #2e384d; }

.session-list table {
  border-collapse: collapse !important;
  table-layout: fixed !important; }
  .session-list table td {
    border: 1px solid #a8a8a8 !important; }

.session-list .field {
  margin-bottom: 20px;
  position: relative; }
  .session-list .field.error label {
    color: red; }
  .session-list .field.error input {
    color: red;
    border-color: red;
    background-color: #fff6f6; }
  .session-list .field b {
    font-size: 13px !important; }

.session-list label {
  margin-bottom: 5px;
  display: block;
  color: #1a1a1a;
  font-size: 11px;
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 1.02px; }

.session-list .field-wrap + .field-wrap {
  margin-top: 0; }

.session-list .field-wrap.date-input-wrap {
  margin-bottom: 0 !important; }

.session-list .upload {
  position: relative;
  cursor: pointer;
  height: 2.5rem; }
  .session-list .upload input {
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    top: 0;
    bottom: 0;
    z-index: 9; }
  .session-list .upload .upload-custom {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 5;
    height: 2.5rem;
    padding: 0.5rem 1rem;
    line-height: 1.5;
    color: #555;
    background-color: #fff;
    border: 0.075rem solid #ddd;
    border-radius: 0.25rem;
    box-shadow: inset 0 0.2rem 0.4rem rgba(0, 0, 0, 0.05);
    -webkit-user-select: none;
    user-select: none; }
  .session-list .upload .upload-custom:before {
    position: absolute;
    top: -0.075rem;
    right: -0.075rem;
    bottom: -0.075rem;
    z-index: 6;
    display: block;
    content: 'Browse';
    height: 2.5rem;
    padding: 0.5rem 1rem;
    line-height: 1.5;
    color: #555;
    background-color: #eee;
    border: 0.075rem solid #ddd;
    border-radius: 0 0.25rem 0.25rem 0; }
  .session-list .upload .upload-custom:after {
    content: 'Choose file...'; }
  .session-list .upload b {
    position: absolute;
    z-index: 6;
    font-size: 12px;
    color: #727e8e;
    background-color: white;
    top: 1px;
    bottom: 1px;
    left: 13px;
    padding: 6px;
    width: 80%;
    overflow: hidden; }
  .session-list .upload .close {
    position: absolute;
    right: 32px;
    top: 9px;
    width: 84px;
    opacity: 0.3;
    z-index: 9; }
  .session-list .upload .close:hover {
    opacity: 1; }
  .session-list .upload .close:before,
  .session-list .upload .close:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 16px;
    width: 4px;
    background-color: #196aca; }
  .session-list .upload .close:before {
    transform: rotate(45deg); }
  .session-list .upload .close:after {
    transform: rotate(-45deg); }

.session-list .users-list .field-wrap i.trash.icon {
  margin-left: 20px; }

.ui.selection.dropdown .icon:not(.delete):not(.close) {
  position: absolute;
  right: 10px;
  z-index: 10;
  color: #0067ac;
  top: 50%;
  transform: translateY(-50%);
  margin: 0;
  padding: 0; }

.session-topic-loader {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  right: 50% !important; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.session-details-list h2 {
  line-height: 41px;
  font-weight: normal;
  color: #2e384d; }

.session-details-list .description-table {
  border-collapse: collapse !important;
  table-layout: fixed !important; }
  .session-details-list .description-table td {
    border: 0px solid #a8a8a8 !important;
    background: #fff !important;
    border-right: 15px solid #f4f6fd !important; }
  .session-details-list .description-table .spacer {
    height: 15px !important; }
  .session-details-list .description-table span {
    margin-left: 30px; }

.session-details-list .table-session {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  border-collapse: collapse; }
  .session-details-list .table-session thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #a8a8a8; }
  .session-details-list .table-session th {
    padding: 0.75rem; }
  .session-details-list .table-session td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #a8a8a8;
    text-align: center; }

.session-details-list .table-bordered {
  border: 1px solid #a8a8a8; }
  .session-details-list .table-bordered thead th {
    border-bottom-width: 2px; }
  .session-details-list .table-bordered th {
    border: 1px solid #a8a8a8; }
  .session-details-list .table-bordered td {
    border: 1px solid #a8a8a8; }

.session-learners-list {
  margin-top: 20px;
  padding: 10px; }

.session-topic-loader {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  right: 50% !important; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.success-cls {
  color: green; }

.errors-cls {
  color: red; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.migrate-banner {
  font-family: "Quicksand", sans-serif;
  background-color: #e4007f;
  color: white;
  padding-top: 20px;
  padding-bottom: 30px;
  text-align: center; }

.migrate-banner h2 {
  font-size: 32px;
  margin-bottom: 10px;
  font-weight: 600; }

.migrate-banner p {
  font-size: 16px;
  margin-bottom: 0;
  line-height: 1.6; }

.migrate-banner a {
  color: white;
  text-decoration: underline;
  font-weight: bold; }

.migrate-banner a:hover {
  color: #ffcc00; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.login-page {
  display: flex;
  justify-content: center;
  align-items: center; }
  .login-page .inner {
    width: 100%;
    max-width: 275px; }
  .login-page .logo-wrap {
    display: flex;
    align-items: center; }
  .login-page .logo {
    width: 172px;
    display: block;
    margin: 0 auto; }
  .login-page .customer-logo-wrap {
    margin-left: 35px; }
  .login-page .forms-wrap {
    max-width: 350px;
    margin-top: 65px;
    text-align: center; }
    .login-page .forms-wrap .title {
      color: #0067ac;
      font-weight: 900;
      font-size: 20px;
      line-height: 41px; }
    .login-page .forms-wrap .description {
      margin-top: 20px;
      line-height: 1.5;
      display: block;
      color: #606d7c;
      font-size: 16px; }
    .login-page .forms-wrap .bottom-info {
      margin-top: 13px;
      color: #b0bac9;
      font-size: 16px;
      text-align: center; }
      .login-page .forms-wrap .bottom-info .link-wrap {
        margin-top: 15px; }
        .login-page .forms-wrap .bottom-info .link-wrap span {
          color: #b0bac9;
          font-weight: 400; }
      .login-page .forms-wrap .bottom-info .link {
        color: #0067ac;
        font-weight: 900;
        cursor: pointer; }
        .login-page .forms-wrap .bottom-info .link:hover {
          text-decoration: underline; }
    .login-page .forms-wrap form .field-wrap {
      margin-top: 15px; }
    .login-page .forms-wrap button {
      margin-top: 15px;
      border-radius: 10px; }
  .login-page .ui.input > input {
    background-color: transparent;
    border: 1px solid #647283;
    color: #647283;
    text-align: center;
    font-family: "Quicksand", sans-serif;
    border-radius: 10px; }
    .login-page .ui.input > input:focus {
      background-color: transparent; }
    .login-page .ui.input > input::placeholder {
      color: #647283;
      opacity: 1; }

.AccountSelector_listItem__30vVn {
  display: grid !important;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto;
  grid-column-gap: 10px;
  column-gap: 10px;
  align-items: center; }

.AccountSelector_checkbox__mF9lg {
  grid-row: 1 / 3 span; }

.AccountSelector_actionPositive__KfYwW {
  color: white; }
  .AccountSelector_actionPositive__KfYwW i {
    color: currentColor !important; }

@media (max-width: 500px) {
  .AccountSelector_listHeader__2XDEO {
    font-size: medium; } }

@media (min-width: 600px) {
  .AccountSelector_listItem__30vVn {
    grid-template-columns: auto auto 1fr;
    grid-template-rows: auto; }
  .AccountSelector_checkbox__mF9lg {
    grid-row: initial; } }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

.account-selector__item .content .header {
  color: #2e384d !important; }

.masked-icon {
  position: relative; }
  .masked-icon__levelOne {
    position: relative;
    z-index: 1; }
  .masked-icon__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
    z-index: 2; }

/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Correct the line height in all browsers.
     * 2. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  line-height: 1.15;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Chrome 57- and Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Correct the padding in Firefox.
     */
fieldset {
  padding: 0.35em 0.75em 0.625em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 300;
  font-display: auto;
  src: url(/static/media/fa-light-300.64805970.eot);
  src: url(/static/media/fa-light-300.64805970.eot?#iefix) format("embedded-opentype"), url(/static/media/fa-light-300.67557ec3.woff2) format("woff2"), url(/static/media/fa-light-300.1e4c8262.woff) format("woff"), url(/static/media/fa-light-300.69ecb2bd.ttf) format("truetype"), url(/static/media/fa-light-300.f7e9551c.svg#fontawesome) format("svg"); }

.fal {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300; }

@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url(/static/media/fa-regular-400.dd9e1583.eot);
  src: url(/static/media/fa-regular-400.dd9e1583.eot?#iefix) format("embedded-opentype"), url(/static/media/fa-regular-400.ea1125e0.woff2) format("woff2"), url(/static/media/fa-regular-400.b8561728.woff) format("woff"), url(/static/media/fa-regular-400.f18935cd.ttf) format("truetype"), url(/static/media/fa-regular-400.789192a8.svg#fontawesome) format("svg"); }

.far {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400; }

@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 900;
  font-display: auto;
  src: url(/static/media/fa-solid-900.3f7e3ac8.eot);
  src: url(/static/media/fa-solid-900.3f7e3ac8.eot?#iefix) format("embedded-opentype"), url(/static/media/fa-solid-900.00d30127.woff2) format("woff2"), url(/static/media/fa-solid-900.dddc7b46.woff) format("woff"), url(/static/media/fa-solid-900.9242dedf.ttf) format("truetype"), url(/static/media/fa-solid-900.68091454.svg#fontawesome) format("svg"); }

.fa,
.fas {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900; }

.fa,
.fas,
.far,
.fal,
.fab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1; }

.fa-lg {
  font-size: 1.33333em;
  line-height: 0.75em;
  vertical-align: -.0667em; }

.fa-xs {
  font-size: .75em; }

.fa-sm {
  font-size: .875em; }

.fa-1x {
  font-size: 1em; }

.fa-2x {
  font-size: 2em; }

.fa-3x {
  font-size: 3em; }

.fa-4x {
  font-size: 4em; }

.fa-5x {
  font-size: 5em; }

.fa-6x {
  font-size: 6em; }

.fa-7x {
  font-size: 7em; }

.fa-8x {
  font-size: 8em; }

.fa-9x {
  font-size: 9em; }

.fa-10x {
  font-size: 10em; }

.fa-fw {
  text-align: center;
  width: 1.25em; }

.fa-ul {
  list-style-type: none;
  margin-left: 2.5em;
  padding-left: 0; }
  .fa-ul > li {
    position: relative; }

.fa-li {
  left: -2em;
  position: absolute;
  text-align: center;
  width: 2em;
  line-height: inherit; }

.fa-border {
  border: solid 0.08em #eee;
  border-radius: .1em;
  padding: .2em .25em .15em; }

.fa-pull-left {
  float: left; }

.fa-pull-right {
  float: right; }

.fa.fa-pull-left,
.fas.fa-pull-left,
.far.fa-pull-left,
.fal.fa-pull-left,
.fab.fa-pull-left {
  margin-right: .3em; }

.fa.fa-pull-right,
.fas.fa-pull-right,
.far.fa-pull-right,
.fal.fa-pull-right,
.fab.fa-pull-right {
  margin-left: .3em; }

.fa-spin {
  animation: fa-spin 2s infinite linear; }

.fa-pulse {
  animation: fa-spin 1s infinite steps(8); }

@keyframes fa-spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  transform: rotate(90deg); }

.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  transform: rotate(180deg); }

.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  transform: rotate(270deg); }

.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  transform: scale(-1, 1); }

.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  transform: scale(1, -1); }

.fa-flip-both, .fa-flip-horizontal.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  transform: scale(-1, -1); }

:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical,
:root .fa-flip-both {
  filter: none; }

.fa-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2.5em; }

.fa-stack-1x,
.fa-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%; }

.fa-stack-1x {
  line-height: inherit; }

.fa-stack-2x {
  font-size: 2em; }

.fa-inverse {
  color: #fff; }

/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
readers do not read off random characters that represent icons */
.fa-500px:before {
  content: "\F26E"; }

.fa-abacus:before {
  content: "\F640"; }

.fa-accessible-icon:before {
  content: "\F368"; }

.fa-accusoft:before {
  content: "\F369"; }

.fa-acorn:before {
  content: "\F6AE"; }

.fa-acquisitions-incorporated:before {
  content: "\F6AF"; }

.fa-ad:before {
  content: "\F641"; }

.fa-address-book:before {
  content: "\F2B9"; }

.fa-address-card:before {
  content: "\F2BB"; }

.fa-adjust:before {
  content: "\F042"; }

.fa-adn:before {
  content: "\F170"; }

.fa-adobe:before {
  content: "\F778"; }

.fa-adversal:before {
  content: "\F36A"; }

.fa-affiliatetheme:before {
  content: "\F36B"; }

.fa-air-freshener:before {
  content: "\F5D0"; }

.fa-alarm-clock:before {
  content: "\F34E"; }

.fa-algolia:before {
  content: "\F36C"; }

.fa-alicorn:before {
  content: "\F6B0"; }

.fa-align-center:before {
  content: "\F037"; }

.fa-align-justify:before {
  content: "\F039"; }

.fa-align-left:before {
  content: "\F036"; }

.fa-align-right:before {
  content: "\F038"; }

.fa-alipay:before {
  content: "\F642"; }

.fa-allergies:before {
  content: "\F461"; }

.fa-amazon:before {
  content: "\F270"; }

.fa-amazon-pay:before {
  content: "\F42C"; }

.fa-ambulance:before {
  content: "\F0F9"; }

.fa-american-sign-language-interpreting:before {
  content: "\F2A3"; }

.fa-amilia:before {
  content: "\F36D"; }

.fa-analytics:before {
  content: "\F643"; }

.fa-anchor:before {
  content: "\F13D"; }

.fa-android:before {
  content: "\F17B"; }

.fa-angel:before {
  content: "\F779"; }

.fa-angellist:before {
  content: "\F209"; }

.fa-angle-double-down:before {
  content: "\F103"; }

.fa-angle-double-left:before {
  content: "\F100"; }

.fa-angle-double-right:before {
  content: "\F101"; }

.fa-angle-double-up:before {
  content: "\F102"; }

.fa-angle-down:before {
  content: "\F107"; }

.fa-angle-left:before {
  content: "\F104"; }

.fa-angle-right:before {
  content: "\F105"; }

.fa-angle-up:before {
  content: "\F106"; }

.fa-angry:before {
  content: "\F556"; }

.fa-angrycreative:before {
  content: "\F36E"; }

.fa-angular:before {
  content: "\F420"; }

.fa-ankh:before {
  content: "\F644"; }

.fa-app-store:before {
  content: "\F36F"; }

.fa-app-store-ios:before {
  content: "\F370"; }

.fa-apper:before {
  content: "\F371"; }

.fa-apple:before {
  content: "\F179"; }

.fa-apple-alt:before {
  content: "\F5D1"; }

.fa-apple-crate:before {
  content: "\F6B1"; }

.fa-apple-pay:before {
  content: "\F415"; }

.fa-archive:before {
  content: "\F187"; }

.fa-archway:before {
  content: "\F557"; }

.fa-arrow-alt-circle-down:before {
  content: "\F358"; }

.fa-arrow-alt-circle-left:before {
  content: "\F359"; }

.fa-arrow-alt-circle-right:before {
  content: "\F35A"; }

.fa-arrow-alt-circle-up:before {
  content: "\F35B"; }

.fa-arrow-alt-down:before {
  content: "\F354"; }

.fa-arrow-alt-from-bottom:before {
  content: "\F346"; }

.fa-arrow-alt-from-left:before {
  content: "\F347"; }

.fa-arrow-alt-from-right:before {
  content: "\F348"; }

.fa-arrow-alt-from-top:before {
  content: "\F349"; }

.fa-arrow-alt-left:before {
  content: "\F355"; }

.fa-arrow-alt-right:before {
  content: "\F356"; }

.fa-arrow-alt-square-down:before {
  content: "\F350"; }

.fa-arrow-alt-square-left:before {
  content: "\F351"; }

.fa-arrow-alt-square-right:before {
  content: "\F352"; }

.fa-arrow-alt-square-up:before {
  content: "\F353"; }

.fa-arrow-alt-to-bottom:before {
  content: "\F34A"; }

.fa-arrow-alt-to-left:before {
  content: "\F34B"; }

.fa-arrow-alt-to-right:before {
  content: "\F34C"; }

.fa-arrow-alt-to-top:before {
  content: "\F34D"; }

.fa-arrow-alt-up:before {
  content: "\F357"; }

.fa-arrow-circle-down:before {
  content: "\F0AB"; }

.fa-arrow-circle-left:before {
  content: "\F0A8"; }

.fa-arrow-circle-right:before {
  content: "\F0A9"; }

.fa-arrow-circle-up:before {
  content: "\F0AA"; }

.fa-arrow-down:before {
  content: "\F063"; }

.fa-arrow-from-bottom:before {
  content: "\F342"; }

.fa-arrow-from-left:before {
  content: "\F343"; }

.fa-arrow-from-right:before {
  content: "\F344"; }

.fa-arrow-from-top:before {
  content: "\F345"; }

.fa-arrow-left:before {
  content: "\F060"; }

.fa-arrow-right:before {
  content: "\F061"; }

.fa-arrow-square-down:before {
  content: "\F339"; }

.fa-arrow-square-left:before {
  content: "\F33A"; }

.fa-arrow-square-right:before {
  content: "\F33B"; }

.fa-arrow-square-up:before {
  content: "\F33C"; }

.fa-arrow-to-bottom:before {
  content: "\F33D"; }

.fa-arrow-to-left:before {
  content: "\F33E"; }

.fa-arrow-to-right:before {
  content: "\F340"; }

.fa-arrow-to-top:before {
  content: "\F341"; }

.fa-arrow-up:before {
  content: "\F062"; }

.fa-arrows:before {
  content: "\F047"; }

.fa-arrows-alt:before {
  content: "\F0B2"; }

.fa-arrows-alt-h:before {
  content: "\F337"; }

.fa-arrows-alt-v:before {
  content: "\F338"; }

.fa-arrows-h:before {
  content: "\F07E"; }

.fa-arrows-v:before {
  content: "\F07D"; }

.fa-artstation:before {
  content: "\F77A"; }

.fa-assistive-listening-systems:before {
  content: "\F2A2"; }

.fa-asterisk:before {
  content: "\F069"; }

.fa-asymmetrik:before {
  content: "\F372"; }

.fa-at:before {
  content: "\F1FA"; }

.fa-atlas:before {
  content: "\F558"; }

.fa-atlassian:before {
  content: "\F77B"; }

.fa-atom:before {
  content: "\F5D2"; }

.fa-atom-alt:before {
  content: "\F5D3"; }

.fa-audible:before {
  content: "\F373"; }

.fa-audio-description:before {
  content: "\F29E"; }

.fa-autoprefixer:before {
  content: "\F41C"; }

.fa-avianex:before {
  content: "\F374"; }

.fa-aviato:before {
  content: "\F421"; }

.fa-award:before {
  content: "\F559"; }

.fa-aws:before {
  content: "\F375"; }

.fa-axe:before {
  content: "\F6B2"; }

.fa-axe-battle:before {
  content: "\F6B3"; }

.fa-baby:before {
  content: "\F77C"; }

.fa-baby-carriage:before {
  content: "\F77D"; }

.fa-backpack:before {
  content: "\F5D4"; }

.fa-backspace:before {
  content: "\F55A"; }

.fa-backward:before {
  content: "\F04A"; }

.fa-bacon:before {
  content: "\F7E5"; }

.fa-badge:before {
  content: "\F335"; }

.fa-badge-check:before {
  content: "\F336"; }

.fa-badge-dollar:before {
  content: "\F645"; }

.fa-badge-percent:before {
  content: "\F646"; }

.fa-badger-honey:before {
  content: "\F6B4"; }

.fa-balance-scale:before {
  content: "\F24E"; }

.fa-balance-scale-left:before {
  content: "\F515"; }

.fa-balance-scale-right:before {
  content: "\F516"; }

.fa-ball-pile:before {
  content: "\F77E"; }

.fa-ballot:before {
  content: "\F732"; }

.fa-ballot-check:before {
  content: "\F733"; }

.fa-ban:before {
  content: "\F05E"; }

.fa-band-aid:before {
  content: "\F462"; }

.fa-bandcamp:before {
  content: "\F2D5"; }

.fa-barcode:before {
  content: "\F02A"; }

.fa-barcode-alt:before {
  content: "\F463"; }

.fa-barcode-read:before {
  content: "\F464"; }

.fa-barcode-scan:before {
  content: "\F465"; }

.fa-bars:before {
  content: "\F0C9"; }

.fa-baseball:before {
  content: "\F432"; }

.fa-baseball-ball:before {
  content: "\F433"; }

.fa-basketball-ball:before {
  content: "\F434"; }

.fa-basketball-hoop:before {
  content: "\F435"; }

.fa-bat:before {
  content: "\F6B5"; }

.fa-bath:before {
  content: "\F2CD"; }

.fa-battery-bolt:before {
  content: "\F376"; }

.fa-battery-empty:before {
  content: "\F244"; }

.fa-battery-full:before {
  content: "\F240"; }

.fa-battery-half:before {
  content: "\F242"; }

.fa-battery-quarter:before {
  content: "\F243"; }

.fa-battery-slash:before {
  content: "\F377"; }

.fa-battery-three-quarters:before {
  content: "\F241"; }

.fa-bed:before {
  content: "\F236"; }

.fa-beer:before {
  content: "\F0FC"; }

.fa-behance:before {
  content: "\F1B4"; }

.fa-behance-square:before {
  content: "\F1B5"; }

.fa-bell:before {
  content: "\F0F3"; }

.fa-bell-school:before {
  content: "\F5D5"; }

.fa-bell-school-slash:before {
  content: "\F5D6"; }

.fa-bell-slash:before {
  content: "\F1F6"; }

.fa-bells:before {
  content: "\F77F"; }

.fa-bezier-curve:before {
  content: "\F55B"; }

.fa-bible:before {
  content: "\F647"; }

.fa-bicycle:before {
  content: "\F206"; }

.fa-bimobject:before {
  content: "\F378"; }

.fa-binoculars:before {
  content: "\F1E5"; }

.fa-biohazard:before {
  content: "\F780"; }

.fa-birthday-cake:before {
  content: "\F1FD"; }

.fa-bitbucket:before {
  content: "\F171"; }

.fa-bitcoin:before {
  content: "\F379"; }

.fa-bity:before {
  content: "\F37A"; }

.fa-black-tie:before {
  content: "\F27E"; }

.fa-blackberry:before {
  content: "\F37B"; }

.fa-blanket:before {
  content: "\F498"; }

.fa-blender:before {
  content: "\F517"; }

.fa-blender-phone:before {
  content: "\F6B6"; }

.fa-blind:before {
  content: "\F29D"; }

.fa-blog:before {
  content: "\F781"; }

.fa-blogger:before {
  content: "\F37C"; }

.fa-blogger-b:before {
  content: "\F37D"; }

.fa-bluetooth:before {
  content: "\F293"; }

.fa-bluetooth-b:before {
  content: "\F294"; }

.fa-bold:before {
  content: "\F032"; }

.fa-bolt:before {
  content: "\F0E7"; }

.fa-bomb:before {
  content: "\F1E2"; }

.fa-bone:before {
  content: "\F5D7"; }

.fa-bone-break:before {
  content: "\F5D8"; }

.fa-bong:before {
  content: "\F55C"; }

.fa-book:before {
  content: "\F02D"; }

.fa-book-alt:before {
  content: "\F5D9"; }

.fa-book-dead:before {
  content: "\F6B7"; }

.fa-book-heart:before {
  content: "\F499"; }

.fa-book-medical:before {
  content: "\F7E6"; }

.fa-book-open:before {
  content: "\F518"; }

.fa-book-reader:before {
  content: "\F5DA"; }

.fa-book-spells:before {
  content: "\F6B8"; }

.fa-book-user:before {
  content: "\F7E7"; }

.fa-bookmark:before {
  content: "\F02E"; }

.fa-books:before {
  content: "\F5DB"; }

.fa-books-medical:before {
  content: "\F7E8"; }

.fa-boot:before {
  content: "\F782"; }

.fa-booth-curtain:before {
  content: "\F734"; }

.fa-bow-arrow:before {
  content: "\F6B9"; }

.fa-bowling-ball:before {
  content: "\F436"; }

.fa-bowling-pins:before {
  content: "\F437"; }

.fa-box:before {
  content: "\F466"; }

.fa-box-alt:before {
  content: "\F49A"; }

.fa-box-ballot:before {
  content: "\F735"; }

.fa-box-check:before {
  content: "\F467"; }

.fa-box-fragile:before {
  content: "\F49B"; }

.fa-box-full:before {
  content: "\F49C"; }

.fa-box-heart:before {
  content: "\F49D"; }

.fa-box-open:before {
  content: "\F49E"; }

.fa-box-up:before {
  content: "\F49F"; }

.fa-box-usd:before {
  content: "\F4A0"; }

.fa-boxes:before {
  content: "\F468"; }

.fa-boxes-alt:before {
  content: "\F4A1"; }

.fa-boxing-glove:before {
  content: "\F438"; }

.fa-brackets:before {
  content: "\F7E9"; }

.fa-brackets-curly:before {
  content: "\F7EA"; }

.fa-braille:before {
  content: "\F2A1"; }

.fa-brain:before {
  content: "\F5DC"; }

.fa-bread-loaf:before {
  content: "\F7EB"; }

.fa-bread-slice:before {
  content: "\F7EC"; }

.fa-briefcase:before {
  content: "\F0B1"; }

.fa-briefcase-medical:before {
  content: "\F469"; }

.fa-broadcast-tower:before {
  content: "\F519"; }

.fa-broom:before {
  content: "\F51A"; }

.fa-browser:before {
  content: "\F37E"; }

.fa-brush:before {
  content: "\F55D"; }

.fa-btc:before {
  content: "\F15A"; }

.fa-bug:before {
  content: "\F188"; }

.fa-building:before {
  content: "\F1AD"; }

.fa-bullhorn:before {
  content: "\F0A1"; }

.fa-bullseye:before {
  content: "\F140"; }

.fa-bullseye-arrow:before {
  content: "\F648"; }

.fa-bullseye-pointer:before {
  content: "\F649"; }

.fa-burn:before {
  content: "\F46A"; }

.fa-buromobelexperte:before {
  content: "\F37F"; }

.fa-burrito:before {
  content: "\F7ED"; }

.fa-bus:before {
  content: "\F207"; }

.fa-bus-alt:before {
  content: "\F55E"; }

.fa-bus-school:before {
  content: "\F5DD"; }

.fa-business-time:before {
  content: "\F64A"; }

.fa-buysellads:before {
  content: "\F20D"; }

.fa-cabinet-filing:before {
  content: "\F64B"; }

.fa-calculator:before {
  content: "\F1EC"; }

.fa-calculator-alt:before {
  content: "\F64C"; }

.fa-calendar:before {
  content: "\F133"; }

.fa-calendar-alt:before {
  content: "\F073"; }

.fa-calendar-check:before {
  content: "\F274"; }

.fa-calendar-day:before {
  content: "\F783"; }

.fa-calendar-edit:before {
  content: "\F333"; }

.fa-calendar-exclamation:before {
  content: "\F334"; }

.fa-calendar-minus:before {
  content: "\F272"; }

.fa-calendar-plus:before {
  content: "\F271"; }

.fa-calendar-star:before {
  content: "\F736"; }

.fa-calendar-times:before {
  content: "\F273"; }

.fa-calendar-week:before {
  content: "\F784"; }

.fa-camera:before {
  content: "\F030"; }

.fa-camera-alt:before {
  content: "\F332"; }

.fa-camera-retro:before {
  content: "\F083"; }

.fa-campfire:before {
  content: "\F6BA"; }

.fa-campground:before {
  content: "\F6BB"; }

.fa-canadian-maple-leaf:before {
  content: "\F785"; }

.fa-candle-holder:before {
  content: "\F6BC"; }

.fa-candy-cane:before {
  content: "\F786"; }

.fa-candy-corn:before {
  content: "\F6BD"; }

.fa-cannabis:before {
  content: "\F55F"; }

.fa-capsules:before {
  content: "\F46B"; }

.fa-car:before {
  content: "\F1B9"; }

.fa-car-alt:before {
  content: "\F5DE"; }

.fa-car-battery:before {
  content: "\F5DF"; }

.fa-car-bump:before {
  content: "\F5E0"; }

.fa-car-crash:before {
  content: "\F5E1"; }

.fa-car-garage:before {
  content: "\F5E2"; }

.fa-car-mechanic:before {
  content: "\F5E3"; }

.fa-car-side:before {
  content: "\F5E4"; }

.fa-car-tilt:before {
  content: "\F5E5"; }

.fa-car-wash:before {
  content: "\F5E6"; }

.fa-caret-circle-down:before {
  content: "\F32D"; }

.fa-caret-circle-left:before {
  content: "\F32E"; }

.fa-caret-circle-right:before {
  content: "\F330"; }

.fa-caret-circle-up:before {
  content: "\F331"; }

.fa-caret-down:before {
  content: "\F0D7"; }

.fa-caret-left:before {
  content: "\F0D9"; }

.fa-caret-right:before {
  content: "\F0DA"; }

.fa-caret-square-down:before {
  content: "\F150"; }

.fa-caret-square-left:before {
  content: "\F191"; }

.fa-caret-square-right:before {
  content: "\F152"; }

.fa-caret-square-up:before {
  content: "\F151"; }

.fa-caret-up:before {
  content: "\F0D8"; }

.fa-carrot:before {
  content: "\F787"; }

.fa-cart-arrow-down:before {
  content: "\F218"; }

.fa-cart-plus:before {
  content: "\F217"; }

.fa-cash-register:before {
  content: "\F788"; }

.fa-cat:before {
  content: "\F6BE"; }

.fa-cauldron:before {
  content: "\F6BF"; }

.fa-cc-amazon-pay:before {
  content: "\F42D"; }

.fa-cc-amex:before {
  content: "\F1F3"; }

.fa-cc-apple-pay:before {
  content: "\F416"; }

.fa-cc-diners-club:before {
  content: "\F24C"; }

.fa-cc-discover:before {
  content: "\F1F2"; }

.fa-cc-jcb:before {
  content: "\F24B"; }

.fa-cc-mastercard:before {
  content: "\F1F1"; }

.fa-cc-paypal:before {
  content: "\F1F4"; }

.fa-cc-stripe:before {
  content: "\F1F5"; }

.fa-cc-visa:before {
  content: "\F1F0"; }

.fa-centercode:before {
  content: "\F380"; }

.fa-centos:before {
  content: "\F789"; }

.fa-certificate:before {
  content: "\F0A3"; }

.fa-chair:before {
  content: "\F6C0"; }

.fa-chair-office:before {
  content: "\F6C1"; }

.fa-chalkboard:before {
  content: "\F51B"; }

.fa-chalkboard-teacher:before {
  content: "\F51C"; }

.fa-charging-station:before {
  content: "\F5E7"; }

.fa-chart-area:before {
  content: "\F1FE"; }

.fa-chart-bar:before {
  content: "\F080"; }

.fa-chart-line:before {
  content: "\F201"; }

.fa-chart-line-down:before {
  content: "\F64D"; }

.fa-chart-network:before {
  content: "\F78A"; }

.fa-chart-pie:before {
  content: "\F200"; }

.fa-chart-pie-alt:before {
  content: "\F64E"; }

.fa-chart-scatter:before {
  content: "\F7EE"; }

.fa-check:before {
  content: "\F00C"; }

.fa-check-circle:before {
  content: "\F058"; }

.fa-check-double:before {
  content: "\F560"; }

.fa-check-square:before {
  content: "\F14A"; }

.fa-cheese:before {
  content: "\F7EF"; }

.fa-cheese-swiss:before {
  content: "\F7F0"; }

.fa-cheeseburger:before {
  content: "\F7F1"; }

.fa-chess:before {
  content: "\F439"; }

.fa-chess-bishop:before {
  content: "\F43A"; }

.fa-chess-bishop-alt:before {
  content: "\F43B"; }

.fa-chess-board:before {
  content: "\F43C"; }

.fa-chess-clock:before {
  content: "\F43D"; }

.fa-chess-clock-alt:before {
  content: "\F43E"; }

.fa-chess-king:before {
  content: "\F43F"; }

.fa-chess-king-alt:before {
  content: "\F440"; }

.fa-chess-knight:before {
  content: "\F441"; }

.fa-chess-knight-alt:before {
  content: "\F442"; }

.fa-chess-pawn:before {
  content: "\F443"; }

.fa-chess-pawn-alt:before {
  content: "\F444"; }

.fa-chess-queen:before {
  content: "\F445"; }

.fa-chess-queen-alt:before {
  content: "\F446"; }

.fa-chess-rook:before {
  content: "\F447"; }

.fa-chess-rook-alt:before {
  content: "\F448"; }

.fa-chevron-circle-down:before {
  content: "\F13A"; }

.fa-chevron-circle-left:before {
  content: "\F137"; }

.fa-chevron-circle-right:before {
  content: "\F138"; }

.fa-chevron-circle-up:before {
  content: "\F139"; }

.fa-chevron-double-down:before {
  content: "\F322"; }

.fa-chevron-double-left:before {
  content: "\F323"; }

.fa-chevron-double-right:before {
  content: "\F324"; }

.fa-chevron-double-up:before {
  content: "\F325"; }

.fa-chevron-down:before {
  content: "\F078"; }

.fa-chevron-left:before {
  content: "\F053"; }

.fa-chevron-right:before {
  content: "\F054"; }

.fa-chevron-square-down:before {
  content: "\F329"; }

.fa-chevron-square-left:before {
  content: "\F32A"; }

.fa-chevron-square-right:before {
  content: "\F32B"; }

.fa-chevron-square-up:before {
  content: "\F32C"; }

.fa-chevron-up:before {
  content: "\F077"; }

.fa-child:before {
  content: "\F1AE"; }

.fa-chimney:before {
  content: "\F78B"; }

.fa-chrome:before {
  content: "\F268"; }

.fa-church:before {
  content: "\F51D"; }

.fa-circle:before {
  content: "\F111"; }

.fa-circle-notch:before {
  content: "\F1CE"; }

.fa-city:before {
  content: "\F64F"; }

.fa-claw-marks:before {
  content: "\F6C2"; }

.fa-clinic-medical:before {
  content: "\F7F2"; }

.fa-clipboard:before {
  content: "\F328"; }

.fa-clipboard-check:before {
  content: "\F46C"; }

.fa-clipboard-list:before {
  content: "\F46D"; }

.fa-clipboard-list-check:before {
  content: "\F737"; }

.fa-clipboard-prescription:before {
  content: "\F5E8"; }

.fa-clipboard-user:before {
  content: "\F7F3"; }

.fa-clock:before {
  content: "\F017"; }

.fa-clone:before {
  content: "\F24D"; }

.fa-closed-captioning:before {
  content: "\F20A"; }

.fa-cloud:before {
  content: "\F0C2"; }

.fa-cloud-download:before {
  content: "\F0ED"; }

.fa-cloud-download-alt:before {
  content: "\F381"; }

.fa-cloud-drizzle:before {
  content: "\F738"; }

.fa-cloud-hail:before {
  content: "\F739"; }

.fa-cloud-hail-mixed:before {
  content: "\F73A"; }

.fa-cloud-meatball:before {
  content: "\F73B"; }

.fa-cloud-moon:before {
  content: "\F6C3"; }

.fa-cloud-moon-rain:before {
  content: "\F73C"; }

.fa-cloud-rain:before {
  content: "\F73D"; }

.fa-cloud-rainbow:before {
  content: "\F73E"; }

.fa-cloud-showers:before {
  content: "\F73F"; }

.fa-cloud-showers-heavy:before {
  content: "\F740"; }

.fa-cloud-sleet:before {
  content: "\F741"; }

.fa-cloud-snow:before {
  content: "\F742"; }

.fa-cloud-sun:before {
  content: "\F6C4"; }

.fa-cloud-sun-rain:before {
  content: "\F743"; }

.fa-cloud-upload:before {
  content: "\F0EE"; }

.fa-cloud-upload-alt:before {
  content: "\F382"; }

.fa-clouds:before {
  content: "\F744"; }

.fa-clouds-moon:before {
  content: "\F745"; }

.fa-clouds-sun:before {
  content: "\F746"; }

.fa-cloudscale:before {
  content: "\F383"; }

.fa-cloudsmith:before {
  content: "\F384"; }

.fa-cloudversify:before {
  content: "\F385"; }

.fa-club:before {
  content: "\F327"; }

.fa-cocktail:before {
  content: "\F561"; }

.fa-code:before {
  content: "\F121"; }

.fa-code-branch:before {
  content: "\F126"; }

.fa-code-commit:before {
  content: "\F386"; }

.fa-code-merge:before {
  content: "\F387"; }

.fa-codepen:before {
  content: "\F1CB"; }

.fa-codiepie:before {
  content: "\F284"; }

.fa-coffee:before {
  content: "\F0F4"; }

.fa-coffee-togo:before {
  content: "\F6C5"; }

.fa-coffin:before {
  content: "\F6C6"; }

.fa-cog:before {
  content: "\F013"; }

.fa-cogs:before {
  content: "\F085"; }

.fa-coins:before {
  content: "\F51E"; }

.fa-columns:before {
  content: "\F0DB"; }

.fa-comment:before {
  content: "\F075"; }

.fa-comment-alt:before {
  content: "\F27A"; }

.fa-comment-alt-check:before {
  content: "\F4A2"; }

.fa-comment-alt-dollar:before {
  content: "\F650"; }

.fa-comment-alt-dots:before {
  content: "\F4A3"; }

.fa-comment-alt-edit:before {
  content: "\F4A4"; }

.fa-comment-alt-exclamation:before {
  content: "\F4A5"; }

.fa-comment-alt-lines:before {
  content: "\F4A6"; }

.fa-comment-alt-medical:before {
  content: "\F7F4"; }

.fa-comment-alt-minus:before {
  content: "\F4A7"; }

.fa-comment-alt-plus:before {
  content: "\F4A8"; }

.fa-comment-alt-slash:before {
  content: "\F4A9"; }

.fa-comment-alt-smile:before {
  content: "\F4AA"; }

.fa-comment-alt-times:before {
  content: "\F4AB"; }

.fa-comment-check:before {
  content: "\F4AC"; }

.fa-comment-dollar:before {
  content: "\F651"; }

.fa-comment-dots:before {
  content: "\F4AD"; }

.fa-comment-edit:before {
  content: "\F4AE"; }

.fa-comment-exclamation:before {
  content: "\F4AF"; }

.fa-comment-lines:before {
  content: "\F4B0"; }

.fa-comment-medical:before {
  content: "\F7F5"; }

.fa-comment-minus:before {
  content: "\F4B1"; }

.fa-comment-plus:before {
  content: "\F4B2"; }

.fa-comment-slash:before {
  content: "\F4B3"; }

.fa-comment-smile:before {
  content: "\F4B4"; }

.fa-comment-times:before {
  content: "\F4B5"; }

.fa-comments:before {
  content: "\F086"; }

.fa-comments-alt:before {
  content: "\F4B6"; }

.fa-comments-alt-dollar:before {
  content: "\F652"; }

.fa-comments-dollar:before {
  content: "\F653"; }

.fa-compact-disc:before {
  content: "\F51F"; }

.fa-compass:before {
  content: "\F14E"; }

.fa-compass-slash:before {
  content: "\F5E9"; }

.fa-compress:before {
  content: "\F066"; }

.fa-compress-alt:before {
  content: "\F422"; }

.fa-compress-arrows-alt:before {
  content: "\F78C"; }

.fa-compress-wide:before {
  content: "\F326"; }

.fa-concierge-bell:before {
  content: "\F562"; }

.fa-confluence:before {
  content: "\F78D"; }

.fa-connectdevelop:before {
  content: "\F20E"; }

.fa-container-storage:before {
  content: "\F4B7"; }

.fa-contao:before {
  content: "\F26D"; }

.fa-conveyor-belt:before {
  content: "\F46E"; }

.fa-conveyor-belt-alt:before {
  content: "\F46F"; }

.fa-cookie:before {
  content: "\F563"; }

.fa-cookie-bite:before {
  content: "\F564"; }

.fa-copy:before {
  content: "\F0C5"; }

.fa-copyright:before {
  content: "\F1F9"; }

.fa-corn:before {
  content: "\F6C7"; }

.fa-couch:before {
  content: "\F4B8"; }

.fa-cow:before {
  content: "\F6C8"; }

.fa-cpanel:before {
  content: "\F388"; }

.fa-creative-commons:before {
  content: "\F25E"; }

.fa-creative-commons-by:before {
  content: "\F4E7"; }

.fa-creative-commons-nc:before {
  content: "\F4E8"; }

.fa-creative-commons-nc-eu:before {
  content: "\F4E9"; }

.fa-creative-commons-nc-jp:before {
  content: "\F4EA"; }

.fa-creative-commons-nd:before {
  content: "\F4EB"; }

.fa-creative-commons-pd:before {
  content: "\F4EC"; }

.fa-creative-commons-pd-alt:before {
  content: "\F4ED"; }

.fa-creative-commons-remix:before {
  content: "\F4EE"; }

.fa-creative-commons-sa:before {
  content: "\F4EF"; }

.fa-creative-commons-sampling:before {
  content: "\F4F0"; }

.fa-creative-commons-sampling-plus:before {
  content: "\F4F1"; }

.fa-creative-commons-share:before {
  content: "\F4F2"; }

.fa-creative-commons-zero:before {
  content: "\F4F3"; }

.fa-credit-card:before {
  content: "\F09D"; }

.fa-credit-card-blank:before {
  content: "\F389"; }

.fa-credit-card-front:before {
  content: "\F38A"; }

.fa-cricket:before {
  content: "\F449"; }

.fa-critical-role:before {
  content: "\F6C9"; }

.fa-croissant:before {
  content: "\F7F6"; }

.fa-crop:before {
  content: "\F125"; }

.fa-crop-alt:before {
  content: "\F565"; }

.fa-cross:before {
  content: "\F654"; }

.fa-crosshairs:before {
  content: "\F05B"; }

.fa-crow:before {
  content: "\F520"; }

.fa-crown:before {
  content: "\F521"; }

.fa-crutch:before {
  content: "\F7F7"; }

.fa-crutches:before {
  content: "\F7F8"; }

.fa-css3:before {
  content: "\F13C"; }

.fa-css3-alt:before {
  content: "\F38B"; }

.fa-cube:before {
  content: "\F1B2"; }

.fa-cubes:before {
  content: "\F1B3"; }

.fa-curling:before {
  content: "\F44A"; }

.fa-cut:before {
  content: "\F0C4"; }

.fa-cuttlefish:before {
  content: "\F38C"; }

.fa-d-and-d:before {
  content: "\F38D"; }

.fa-d-and-d-beyond:before {
  content: "\F6CA"; }

.fa-dagger:before {
  content: "\F6CB"; }

.fa-dashcube:before {
  content: "\F210"; }

.fa-database:before {
  content: "\F1C0"; }

.fa-deaf:before {
  content: "\F2A4"; }

.fa-debug:before {
  content: "\F7F9"; }

.fa-deer:before {
  content: "\F78E"; }

.fa-deer-rudolph:before {
  content: "\F78F"; }

.fa-delicious:before {
  content: "\F1A5"; }

.fa-democrat:before {
  content: "\F747"; }

.fa-deploydog:before {
  content: "\F38E"; }

.fa-deskpro:before {
  content: "\F38F"; }

.fa-desktop:before {
  content: "\F108"; }

.fa-desktop-alt:before {
  content: "\F390"; }

.fa-dev:before {
  content: "\F6CC"; }

.fa-deviantart:before {
  content: "\F1BD"; }

.fa-dewpoint:before {
  content: "\F748"; }

.fa-dharmachakra:before {
  content: "\F655"; }

.fa-dhl:before {
  content: "\F790"; }

.fa-diagnoses:before {
  content: "\F470"; }

.fa-diamond:before {
  content: "\F219"; }

.fa-diaspora:before {
  content: "\F791"; }

.fa-dice:before {
  content: "\F522"; }

.fa-dice-d10:before {
  content: "\F6CD"; }

.fa-dice-d12:before {
  content: "\F6CE"; }

.fa-dice-d20:before {
  content: "\F6CF"; }

.fa-dice-d4:before {
  content: "\F6D0"; }

.fa-dice-d6:before {
  content: "\F6D1"; }

.fa-dice-d8:before {
  content: "\F6D2"; }

.fa-dice-five:before {
  content: "\F523"; }

.fa-dice-four:before {
  content: "\F524"; }

.fa-dice-one:before {
  content: "\F525"; }

.fa-dice-six:before {
  content: "\F526"; }

.fa-dice-three:before {
  content: "\F527"; }

.fa-dice-two:before {
  content: "\F528"; }

.fa-digg:before {
  content: "\F1A6"; }

.fa-digital-ocean:before {
  content: "\F391"; }

.fa-digital-tachograph:before {
  content: "\F566"; }

.fa-diploma:before {
  content: "\F5EA"; }

.fa-directions:before {
  content: "\F5EB"; }

.fa-discord:before {
  content: "\F392"; }

.fa-discourse:before {
  content: "\F393"; }

.fa-disease:before {
  content: "\F7FA"; }

.fa-divide:before {
  content: "\F529"; }

.fa-dizzy:before {
  content: "\F567"; }

.fa-dna:before {
  content: "\F471"; }

.fa-do-not-enter:before {
  content: "\F5EC"; }

.fa-dochub:before {
  content: "\F394"; }

.fa-docker:before {
  content: "\F395"; }

.fa-dog:before {
  content: "\F6D3"; }

.fa-dog-leashed:before {
  content: "\F6D4"; }

.fa-dollar-sign:before {
  content: "\F155"; }

.fa-dolly:before {
  content: "\F472"; }

.fa-dolly-empty:before {
  content: "\F473"; }

.fa-dolly-flatbed:before {
  content: "\F474"; }

.fa-dolly-flatbed-alt:before {
  content: "\F475"; }

.fa-dolly-flatbed-empty:before {
  content: "\F476"; }

.fa-donate:before {
  content: "\F4B9"; }

.fa-door-closed:before {
  content: "\F52A"; }

.fa-door-open:before {
  content: "\F52B"; }

.fa-dot-circle:before {
  content: "\F192"; }

.fa-dove:before {
  content: "\F4BA"; }

.fa-download:before {
  content: "\F019"; }

.fa-draft2digital:before {
  content: "\F396"; }

.fa-drafting-compass:before {
  content: "\F568"; }

.fa-dragon:before {
  content: "\F6D5"; }

.fa-draw-circle:before {
  content: "\F5ED"; }

.fa-draw-polygon:before {
  content: "\F5EE"; }

.fa-draw-square:before {
  content: "\F5EF"; }

.fa-dreidel:before {
  content: "\F792"; }

.fa-dribbble:before {
  content: "\F17D"; }

.fa-dribbble-square:before {
  content: "\F397"; }

.fa-dropbox:before {
  content: "\F16B"; }

.fa-drum:before {
  content: "\F569"; }

.fa-drum-steelpan:before {
  content: "\F56A"; }

.fa-drumstick:before {
  content: "\F6D6"; }

.fa-drumstick-bite:before {
  content: "\F6D7"; }

.fa-drupal:before {
  content: "\F1A9"; }

.fa-duck:before {
  content: "\F6D8"; }

.fa-dumbbell:before {
  content: "\F44B"; }

.fa-dumpster:before {
  content: "\F793"; }

.fa-dumpster-fire:before {
  content: "\F794"; }

.fa-dungeon:before {
  content: "\F6D9"; }

.fa-dyalog:before {
  content: "\F399"; }

.fa-ear:before {
  content: "\F5F0"; }

.fa-ear-muffs:before {
  content: "\F795"; }

.fa-earlybirds:before {
  content: "\F39A"; }

.fa-ebay:before {
  content: "\F4F4"; }

.fa-eclipse:before {
  content: "\F749"; }

.fa-eclipse-alt:before {
  content: "\F74A"; }

.fa-edge:before {
  content: "\F282"; }

.fa-edit:before {
  content: "\F044"; }

.fa-egg:before {
  content: "\F7FB"; }

.fa-egg-fried:before {
  content: "\F7FC"; }

.fa-eject:before {
  content: "\F052"; }

.fa-elementor:before {
  content: "\F430"; }

.fa-elephant:before {
  content: "\F6DA"; }

.fa-ellipsis-h:before {
  content: "\F141"; }

.fa-ellipsis-h-alt:before {
  content: "\F39B"; }

.fa-ellipsis-v:before {
  content: "\F142"; }

.fa-ellipsis-v-alt:before {
  content: "\F39C"; }

.fa-ello:before {
  content: "\F5F1"; }

.fa-ember:before {
  content: "\F423"; }

.fa-empire:before {
  content: "\F1D1"; }

.fa-empty-set:before {
  content: "\F656"; }

.fa-engine-warning:before {
  content: "\F5F2"; }

.fa-envelope:before {
  content: "\F0E0"; }

.fa-envelope-open:before {
  content: "\F2B6"; }

.fa-envelope-open-dollar:before {
  content: "\F657"; }

.fa-envelope-open-text:before {
  content: "\F658"; }

.fa-envelope-square:before {
  content: "\F199"; }

.fa-envira:before {
  content: "\F299"; }

.fa-equals:before {
  content: "\F52C"; }

.fa-eraser:before {
  content: "\F12D"; }

.fa-erlang:before {
  content: "\F39D"; }

.fa-ethereum:before {
  content: "\F42E"; }

.fa-ethernet:before {
  content: "\F796"; }

.fa-etsy:before {
  content: "\F2D7"; }

.fa-euro-sign:before {
  content: "\F153"; }

.fa-exchange:before {
  content: "\F0EC"; }

.fa-exchange-alt:before {
  content: "\F362"; }

.fa-exclamation:before {
  content: "\F12A"; }

.fa-exclamation-circle:before {
  content: "\F06A"; }

.fa-exclamation-square:before {
  content: "\F321"; }

.fa-exclamation-triangle:before {
  content: "\F071"; }

.fa-expand:before {
  content: "\F065"; }

.fa-expand-alt:before {
  content: "\F424"; }

.fa-expand-arrows:before {
  content: "\F31D"; }

.fa-expand-arrows-alt:before {
  content: "\F31E"; }

.fa-expand-wide:before {
  content: "\F320"; }

.fa-expeditedssl:before {
  content: "\F23E"; }

.fa-external-link:before {
  content: "\F08E"; }

.fa-external-link-alt:before {
  content: "\F35D"; }

.fa-external-link-square:before {
  content: "\F14C"; }

.fa-external-link-square-alt:before {
  content: "\F360"; }

.fa-eye:before {
  content: "\F06E"; }

.fa-eye-dropper:before {
  content: "\F1FB"; }

.fa-eye-evil:before {
  content: "\F6DB"; }

.fa-eye-slash:before {
  content: "\F070"; }

.fa-facebook:before {
  content: "\F09A"; }

.fa-facebook-f:before {
  content: "\F39E"; }

.fa-facebook-messenger:before {
  content: "\F39F"; }

.fa-facebook-square:before {
  content: "\F082"; }

.fa-fantasy-flight-games:before {
  content: "\F6DC"; }

.fa-fast-backward:before {
  content: "\F049"; }

.fa-fast-forward:before {
  content: "\F050"; }

.fa-fax:before {
  content: "\F1AC"; }

.fa-feather:before {
  content: "\F52D"; }

.fa-feather-alt:before {
  content: "\F56B"; }

.fa-fedex:before {
  content: "\F797"; }

.fa-fedora:before {
  content: "\F798"; }

.fa-female:before {
  content: "\F182"; }

.fa-field-hockey:before {
  content: "\F44C"; }

.fa-fighter-jet:before {
  content: "\F0FB"; }

.fa-figma:before {
  content: "\F799"; }

.fa-file:before {
  content: "\F15B"; }

.fa-file-alt:before {
  content: "\F15C"; }

.fa-file-archive:before {
  content: "\F1C6"; }

.fa-file-audio:before {
  content: "\F1C7"; }

.fa-file-certificate:before {
  content: "\F5F3"; }

.fa-file-chart-line:before {
  content: "\F659"; }

.fa-file-chart-pie:before {
  content: "\F65A"; }

.fa-file-check:before {
  content: "\F316"; }

.fa-file-code:before {
  content: "\F1C9"; }

.fa-file-contract:before {
  content: "\F56C"; }

.fa-file-csv:before {
  content: "\F6DD"; }

.fa-file-download:before {
  content: "\F56D"; }

.fa-file-edit:before {
  content: "\F31C"; }

.fa-file-excel:before {
  content: "\F1C3"; }

.fa-file-exclamation:before {
  content: "\F31A"; }

.fa-file-export:before {
  content: "\F56E"; }

.fa-file-image:before {
  content: "\F1C5"; }

.fa-file-import:before {
  content: "\F56F"; }

.fa-file-invoice:before {
  content: "\F570"; }

.fa-file-invoice-dollar:before {
  content: "\F571"; }

.fa-file-medical:before {
  content: "\F477"; }

.fa-file-medical-alt:before {
  content: "\F478"; }

.fa-file-minus:before {
  content: "\F318"; }

.fa-file-pdf:before {
  content: "\F1C1"; }

.fa-file-plus:before {
  content: "\F319"; }

.fa-file-powerpoint:before {
  content: "\F1C4"; }

.fa-file-prescription:before {
  content: "\F572"; }

.fa-file-signature:before {
  content: "\F573"; }

.fa-file-spreadsheet:before {
  content: "\F65B"; }

.fa-file-times:before {
  content: "\F317"; }

.fa-file-upload:before {
  content: "\F574"; }

.fa-file-user:before {
  content: "\F65C"; }

.fa-file-video:before {
  content: "\F1C8"; }

.fa-file-word:before {
  content: "\F1C2"; }

.fa-files-medical:before {
  content: "\F7FD"; }

.fa-fill:before {
  content: "\F575"; }

.fa-fill-drip:before {
  content: "\F576"; }

.fa-film:before {
  content: "\F008"; }

.fa-film-alt:before {
  content: "\F3A0"; }

.fa-filter:before {
  content: "\F0B0"; }

.fa-fingerprint:before {
  content: "\F577"; }

.fa-fire:before {
  content: "\F06D"; }

.fa-fire-alt:before {
  content: "\F7E4"; }

.fa-fire-extinguisher:before {
  content: "\F134"; }

.fa-fire-smoke:before {
  content: "\F74B"; }

.fa-firefox:before {
  content: "\F269"; }

.fa-fireplace:before {
  content: "\F79A"; }

.fa-first-aid:before {
  content: "\F479"; }

.fa-first-order:before {
  content: "\F2B0"; }

.fa-first-order-alt:before {
  content: "\F50A"; }

.fa-firstdraft:before {
  content: "\F3A1"; }

.fa-fish:before {
  content: "\F578"; }

.fa-fish-cooked:before {
  content: "\F7FE"; }

.fa-fist-raised:before {
  content: "\F6DE"; }

.fa-flag:before {
  content: "\F024"; }

.fa-flag-alt:before {
  content: "\F74C"; }

.fa-flag-checkered:before {
  content: "\F11E"; }

.fa-flag-usa:before {
  content: "\F74D"; }

.fa-flame:before {
  content: "\F6DF"; }

.fa-flask:before {
  content: "\F0C3"; }

.fa-flask-poison:before {
  content: "\F6E0"; }

.fa-flask-potion:before {
  content: "\F6E1"; }

.fa-flickr:before {
  content: "\F16E"; }

.fa-flipboard:before {
  content: "\F44D"; }

.fa-flower:before {
  content: "\F7FF"; }

.fa-flower-daffodil:before {
  content: "\F800"; }

.fa-flower-tulip:before {
  content: "\F801"; }

.fa-flushed:before {
  content: "\F579"; }

.fa-fly:before {
  content: "\F417"; }

.fa-fog:before {
  content: "\F74E"; }

.fa-folder:before {
  content: "\F07B"; }

.fa-folder-minus:before {
  content: "\F65D"; }

.fa-folder-open:before {
  content: "\F07C"; }

.fa-folder-plus:before {
  content: "\F65E"; }

.fa-folder-times:before {
  content: "\F65F"; }

.fa-folder-tree:before {
  content: "\F802"; }

.fa-folders:before {
  content: "\F660"; }

.fa-font:before {
  content: "\F031"; }

.fa-font-awesome:before {
  content: "\F2B4"; }

.fa-font-awesome-alt:before {
  content: "\F35C"; }

.fa-font-awesome-flag:before {
  content: "\F425"; }

.fa-font-awesome-logo-full:before {
  content: "\F4E6"; }

.fa-fonticons:before {
  content: "\F280"; }

.fa-fonticons-fi:before {
  content: "\F3A2"; }

.fa-football-ball:before {
  content: "\F44E"; }

.fa-football-helmet:before {
  content: "\F44F"; }

.fa-forklift:before {
  content: "\F47A"; }

.fa-fort-awesome:before {
  content: "\F286"; }

.fa-fort-awesome-alt:before {
  content: "\F3A3"; }

.fa-forumbee:before {
  content: "\F211"; }

.fa-forward:before {
  content: "\F04E"; }

.fa-foursquare:before {
  content: "\F180"; }

.fa-fragile:before {
  content: "\F4BB"; }

.fa-free-code-camp:before {
  content: "\F2C5"; }

.fa-freebsd:before {
  content: "\F3A4"; }

.fa-french-fries:before {
  content: "\F803"; }

.fa-frog:before {
  content: "\F52E"; }

.fa-frosty-head:before {
  content: "\F79B"; }

.fa-frown:before {
  content: "\F119"; }

.fa-frown-open:before {
  content: "\F57A"; }

.fa-fulcrum:before {
  content: "\F50B"; }

.fa-function:before {
  content: "\F661"; }

.fa-funnel-dollar:before {
  content: "\F662"; }

.fa-futbol:before {
  content: "\F1E3"; }

.fa-galactic-republic:before {
  content: "\F50C"; }

.fa-galactic-senate:before {
  content: "\F50D"; }

.fa-gamepad:before {
  content: "\F11B"; }

.fa-gas-pump:before {
  content: "\F52F"; }

.fa-gas-pump-slash:before {
  content: "\F5F4"; }

.fa-gavel:before {
  content: "\F0E3"; }

.fa-gem:before {
  content: "\F3A5"; }

.fa-genderless:before {
  content: "\F22D"; }

.fa-get-pocket:before {
  content: "\F265"; }

.fa-gg:before {
  content: "\F260"; }

.fa-gg-circle:before {
  content: "\F261"; }

.fa-ghost:before {
  content: "\F6E2"; }

.fa-gift:before {
  content: "\F06B"; }

.fa-gift-card:before {
  content: "\F663"; }

.fa-gifts:before {
  content: "\F79C"; }

.fa-gingerbread-man:before {
  content: "\F79D"; }

.fa-git:before {
  content: "\F1D3"; }

.fa-git-square:before {
  content: "\F1D2"; }

.fa-github:before {
  content: "\F09B"; }

.fa-github-alt:before {
  content: "\F113"; }

.fa-github-square:before {
  content: "\F092"; }

.fa-gitkraken:before {
  content: "\F3A6"; }

.fa-gitlab:before {
  content: "\F296"; }

.fa-gitter:before {
  content: "\F426"; }

.fa-glass:before {
  content: "\F804"; }

.fa-glass-champagne:before {
  content: "\F79E"; }

.fa-glass-cheers:before {
  content: "\F79F"; }

.fa-glass-martini:before {
  content: "\F000"; }

.fa-glass-martini-alt:before {
  content: "\F57B"; }

.fa-glass-whiskey:before {
  content: "\F7A0"; }

.fa-glass-whiskey-rocks:before {
  content: "\F7A1"; }

.fa-glasses:before {
  content: "\F530"; }

.fa-glasses-alt:before {
  content: "\F5F5"; }

.fa-glide:before {
  content: "\F2A5"; }

.fa-glide-g:before {
  content: "\F2A6"; }

.fa-globe:before {
  content: "\F0AC"; }

.fa-globe-africa:before {
  content: "\F57C"; }

.fa-globe-americas:before {
  content: "\F57D"; }

.fa-globe-asia:before {
  content: "\F57E"; }

.fa-globe-europe:before {
  content: "\F7A2"; }

.fa-globe-snow:before {
  content: "\F7A3"; }

.fa-globe-stand:before {
  content: "\F5F6"; }

.fa-gofore:before {
  content: "\F3A7"; }

.fa-golf-ball:before {
  content: "\F450"; }

.fa-golf-club:before {
  content: "\F451"; }

.fa-goodreads:before {
  content: "\F3A8"; }

.fa-goodreads-g:before {
  content: "\F3A9"; }

.fa-google:before {
  content: "\F1A0"; }

.fa-google-drive:before {
  content: "\F3AA"; }

.fa-google-play:before {
  content: "\F3AB"; }

.fa-google-plus:before {
  content: "\F2B3"; }

.fa-google-plus-g:before {
  content: "\F0D5"; }

.fa-google-plus-square:before {
  content: "\F0D4"; }

.fa-google-wallet:before {
  content: "\F1EE"; }

.fa-gopuram:before {
  content: "\F664"; }

.fa-graduation-cap:before {
  content: "\F19D"; }

.fa-gratipay:before {
  content: "\F184"; }

.fa-grav:before {
  content: "\F2D6"; }

.fa-greater-than:before {
  content: "\F531"; }

.fa-greater-than-equal:before {
  content: "\F532"; }

.fa-grimace:before {
  content: "\F57F"; }

.fa-grin:before {
  content: "\F580"; }

.fa-grin-alt:before {
  content: "\F581"; }

.fa-grin-beam:before {
  content: "\F582"; }

.fa-grin-beam-sweat:before {
  content: "\F583"; }

.fa-grin-hearts:before {
  content: "\F584"; }

.fa-grin-squint:before {
  content: "\F585"; }

.fa-grin-squint-tears:before {
  content: "\F586"; }

.fa-grin-stars:before {
  content: "\F587"; }

.fa-grin-tears:before {
  content: "\F588"; }

.fa-grin-tongue:before {
  content: "\F589"; }

.fa-grin-tongue-squint:before {
  content: "\F58A"; }

.fa-grin-tongue-wink:before {
  content: "\F58B"; }

.fa-grin-wink:before {
  content: "\F58C"; }

.fa-grip-horizontal:before {
  content: "\F58D"; }

.fa-grip-lines:before {
  content: "\F7A4"; }

.fa-grip-lines-vertical:before {
  content: "\F7A5"; }

.fa-grip-vertical:before {
  content: "\F58E"; }

.fa-gripfire:before {
  content: "\F3AC"; }

.fa-grunt:before {
  content: "\F3AD"; }

.fa-guitar:before {
  content: "\F7A6"; }

.fa-gulp:before {
  content: "\F3AE"; }

.fa-h-square:before {
  content: "\F0FD"; }

.fa-h1:before {
  content: "\F313"; }

.fa-h2:before {
  content: "\F314"; }

.fa-h3:before {
  content: "\F315"; }

.fa-hacker-news:before {
  content: "\F1D4"; }

.fa-hacker-news-square:before {
  content: "\F3AF"; }

.fa-hackerrank:before {
  content: "\F5F7"; }

.fa-hamburger:before {
  content: "\F805"; }

.fa-hammer:before {
  content: "\F6E3"; }

.fa-hammer-war:before {
  content: "\F6E4"; }

.fa-hamsa:before {
  content: "\F665"; }

.fa-hand-heart:before {
  content: "\F4BC"; }

.fa-hand-holding:before {
  content: "\F4BD"; }

.fa-hand-holding-box:before {
  content: "\F47B"; }

.fa-hand-holding-heart:before {
  content: "\F4BE"; }

.fa-hand-holding-magic:before {
  content: "\F6E5"; }

.fa-hand-holding-seedling:before {
  content: "\F4BF"; }

.fa-hand-holding-usd:before {
  content: "\F4C0"; }

.fa-hand-holding-water:before {
  content: "\F4C1"; }

.fa-hand-lizard:before {
  content: "\F258"; }

.fa-hand-middle-finger:before {
  content: "\F806"; }

.fa-hand-paper:before {
  content: "\F256"; }

.fa-hand-peace:before {
  content: "\F25B"; }

.fa-hand-point-down:before {
  content: "\F0A7"; }

.fa-hand-point-left:before {
  content: "\F0A5"; }

.fa-hand-point-right:before {
  content: "\F0A4"; }

.fa-hand-point-up:before {
  content: "\F0A6"; }

.fa-hand-pointer:before {
  content: "\F25A"; }

.fa-hand-receiving:before {
  content: "\F47C"; }

.fa-hand-rock:before {
  content: "\F255"; }

.fa-hand-scissors:before {
  content: "\F257"; }

.fa-hand-spock:before {
  content: "\F259"; }

.fa-hands:before {
  content: "\F4C2"; }

.fa-hands-heart:before {
  content: "\F4C3"; }

.fa-hands-helping:before {
  content: "\F4C4"; }

.fa-hands-usd:before {
  content: "\F4C5"; }

.fa-handshake:before {
  content: "\F2B5"; }

.fa-handshake-alt:before {
  content: "\F4C6"; }

.fa-hanukiah:before {
  content: "\F6E6"; }

.fa-hard-hat:before {
  content: "\F807"; }

.fa-hashtag:before {
  content: "\F292"; }

.fa-hat-santa:before {
  content: "\F7A7"; }

.fa-hat-winter:before {
  content: "\F7A8"; }

.fa-hat-witch:before {
  content: "\F6E7"; }

.fa-hat-wizard:before {
  content: "\F6E8"; }

.fa-haykal:before {
  content: "\F666"; }

.fa-hdd:before {
  content: "\F0A0"; }

.fa-head-side:before {
  content: "\F6E9"; }

.fa-head-side-brain:before {
  content: "\F808"; }

.fa-head-side-medical:before {
  content: "\F809"; }

.fa-head-vr:before {
  content: "\F6EA"; }

.fa-heading:before {
  content: "\F1DC"; }

.fa-headphones:before {
  content: "\F025"; }

.fa-headphones-alt:before {
  content: "\F58F"; }

.fa-headset:before {
  content: "\F590"; }

.fa-heart:before {
  content: "\F004"; }

.fa-heart-broken:before {
  content: "\F7A9"; }

.fa-heart-circle:before {
  content: "\F4C7"; }

.fa-heart-rate:before {
  content: "\F5F8"; }

.fa-heart-square:before {
  content: "\F4C8"; }

.fa-heartbeat:before {
  content: "\F21E"; }

.fa-helicopter:before {
  content: "\F533"; }

.fa-helmet-battle:before {
  content: "\F6EB"; }

.fa-hexagon:before {
  content: "\F312"; }

.fa-highlighter:before {
  content: "\F591"; }

.fa-hiking:before {
  content: "\F6EC"; }

.fa-hippo:before {
  content: "\F6ED"; }

.fa-hips:before {
  content: "\F452"; }

.fa-hire-a-helper:before {
  content: "\F3B0"; }

.fa-history:before {
  content: "\F1DA"; }

.fa-hockey-mask:before {
  content: "\F6EE"; }

.fa-hockey-puck:before {
  content: "\F453"; }

.fa-hockey-sticks:before {
  content: "\F454"; }

.fa-holly-berry:before {
  content: "\F7AA"; }

.fa-home:before {
  content: "\F015"; }

.fa-home-alt:before {
  content: "\F80A"; }

.fa-home-heart:before {
  content: "\F4C9"; }

.fa-home-lg:before {
  content: "\F80B"; }

.fa-home-lg-alt:before {
  content: "\F80C"; }

.fa-hood-cloak:before {
  content: "\F6EF"; }

.fa-hooli:before {
  content: "\F427"; }

.fa-hornbill:before {
  content: "\F592"; }

.fa-horse:before {
  content: "\F6F0"; }

.fa-horse-head:before {
  content: "\F7AB"; }

.fa-hospital:before {
  content: "\F0F8"; }

.fa-hospital-alt:before {
  content: "\F47D"; }

.fa-hospital-symbol:before {
  content: "\F47E"; }

.fa-hospital-user:before {
  content: "\F80D"; }

.fa-hospitals:before {
  content: "\F80E"; }

.fa-hot-tub:before {
  content: "\F593"; }

.fa-hotdog:before {
  content: "\F80F"; }

.fa-hotel:before {
  content: "\F594"; }

.fa-hotjar:before {
  content: "\F3B1"; }

.fa-hourglass:before {
  content: "\F254"; }

.fa-hourglass-end:before {
  content: "\F253"; }

.fa-hourglass-half:before {
  content: "\F252"; }

.fa-hourglass-start:before {
  content: "\F251"; }

.fa-house-damage:before {
  content: "\F6F1"; }

.fa-house-flood:before {
  content: "\F74F"; }

.fa-houzz:before {
  content: "\F27C"; }

.fa-hryvnia:before {
  content: "\F6F2"; }

.fa-html5:before {
  content: "\F13B"; }

.fa-hubspot:before {
  content: "\F3B2"; }

.fa-humidity:before {
  content: "\F750"; }

.fa-hurricane:before {
  content: "\F751"; }

.fa-i-cursor:before {
  content: "\F246"; }

.fa-ice-cream:before {
  content: "\F810"; }

.fa-ice-skate:before {
  content: "\F7AC"; }

.fa-icicles:before {
  content: "\F7AD"; }

.fa-id-badge:before {
  content: "\F2C1"; }

.fa-id-card:before {
  content: "\F2C2"; }

.fa-id-card-alt:before {
  content: "\F47F"; }

.fa-igloo:before {
  content: "\F7AE"; }

.fa-image:before {
  content: "\F03E"; }

.fa-images:before {
  content: "\F302"; }

.fa-imdb:before {
  content: "\F2D8"; }

.fa-inbox:before {
  content: "\F01C"; }

.fa-inbox-in:before {
  content: "\F310"; }

.fa-inbox-out:before {
  content: "\F311"; }

.fa-indent:before {
  content: "\F03C"; }

.fa-industry:before {
  content: "\F275"; }

.fa-industry-alt:before {
  content: "\F3B3"; }

.fa-infinity:before {
  content: "\F534"; }

.fa-info:before {
  content: "\F129"; }

.fa-info-circle:before {
  content: "\F05A"; }

.fa-info-square:before {
  content: "\F30F"; }

.fa-inhaler:before {
  content: "\F5F9"; }

.fa-instagram:before {
  content: "\F16D"; }

.fa-integral:before {
  content: "\F667"; }

.fa-intercom:before {
  content: "\F7AF"; }

.fa-internet-explorer:before {
  content: "\F26B"; }

.fa-intersection:before {
  content: "\F668"; }

.fa-inventory:before {
  content: "\F480"; }

.fa-invision:before {
  content: "\F7B0"; }

.fa-ioxhost:before {
  content: "\F208"; }

.fa-island-tropical:before {
  content: "\F811"; }

.fa-italic:before {
  content: "\F033"; }

.fa-itunes:before {
  content: "\F3B4"; }

.fa-itunes-note:before {
  content: "\F3B5"; }

.fa-jack-o-lantern:before {
  content: "\F30E"; }

.fa-java:before {
  content: "\F4E4"; }

.fa-jedi:before {
  content: "\F669"; }

.fa-jedi-order:before {
  content: "\F50E"; }

.fa-jenkins:before {
  content: "\F3B6"; }

.fa-jira:before {
  content: "\F7B1"; }

.fa-joget:before {
  content: "\F3B7"; }

.fa-joint:before {
  content: "\F595"; }

.fa-joomla:before {
  content: "\F1AA"; }

.fa-journal-whills:before {
  content: "\F66A"; }

.fa-js:before {
  content: "\F3B8"; }

.fa-js-square:before {
  content: "\F3B9"; }

.fa-jsfiddle:before {
  content: "\F1CC"; }

.fa-kaaba:before {
  content: "\F66B"; }

.fa-kaggle:before {
  content: "\F5FA"; }

.fa-key:before {
  content: "\F084"; }

.fa-key-skeleton:before {
  content: "\F6F3"; }

.fa-keybase:before {
  content: "\F4F5"; }

.fa-keyboard:before {
  content: "\F11C"; }

.fa-keycdn:before {
  content: "\F3BA"; }

.fa-keynote:before {
  content: "\F66C"; }

.fa-khanda:before {
  content: "\F66D"; }

.fa-kickstarter:before {
  content: "\F3BB"; }

.fa-kickstarter-k:before {
  content: "\F3BC"; }

.fa-kidneys:before {
  content: "\F5FB"; }

.fa-kiss:before {
  content: "\F596"; }

.fa-kiss-beam:before {
  content: "\F597"; }

.fa-kiss-wink-heart:before {
  content: "\F598"; }

.fa-kite:before {
  content: "\F6F4"; }

.fa-kiwi-bird:before {
  content: "\F535"; }

.fa-knife-kitchen:before {
  content: "\F6F5"; }

.fa-korvue:before {
  content: "\F42F"; }

.fa-lambda:before {
  content: "\F66E"; }

.fa-lamp:before {
  content: "\F4CA"; }

.fa-landmark:before {
  content: "\F66F"; }

.fa-landmark-alt:before {
  content: "\F752"; }

.fa-language:before {
  content: "\F1AB"; }

.fa-laptop:before {
  content: "\F109"; }

.fa-laptop-code:before {
  content: "\F5FC"; }

.fa-laptop-medical:before {
  content: "\F812"; }

.fa-laravel:before {
  content: "\F3BD"; }

.fa-lastfm:before {
  content: "\F202"; }

.fa-lastfm-square:before {
  content: "\F203"; }

.fa-laugh:before {
  content: "\F599"; }

.fa-laugh-beam:before {
  content: "\F59A"; }

.fa-laugh-squint:before {
  content: "\F59B"; }

.fa-laugh-wink:before {
  content: "\F59C"; }

.fa-layer-group:before {
  content: "\F5FD"; }

.fa-layer-minus:before {
  content: "\F5FE"; }

.fa-layer-plus:before {
  content: "\F5FF"; }

.fa-leaf:before {
  content: "\F06C"; }

.fa-leaf-heart:before {
  content: "\F4CB"; }

.fa-leaf-maple:before {
  content: "\F6F6"; }

.fa-leaf-oak:before {
  content: "\F6F7"; }

.fa-leanpub:before {
  content: "\F212"; }

.fa-lemon:before {
  content: "\F094"; }

.fa-less:before {
  content: "\F41D"; }

.fa-less-than:before {
  content: "\F536"; }

.fa-less-than-equal:before {
  content: "\F537"; }

.fa-level-down:before {
  content: "\F149"; }

.fa-level-down-alt:before {
  content: "\F3BE"; }

.fa-level-up:before {
  content: "\F148"; }

.fa-level-up-alt:before {
  content: "\F3BF"; }

.fa-life-ring:before {
  content: "\F1CD"; }

.fa-lightbulb:before {
  content: "\F0EB"; }

.fa-lightbulb-dollar:before {
  content: "\F670"; }

.fa-lightbulb-exclamation:before {
  content: "\F671"; }

.fa-lightbulb-on:before {
  content: "\F672"; }

.fa-lightbulb-slash:before {
  content: "\F673"; }

.fa-lights-holiday:before {
  content: "\F7B2"; }

.fa-line:before {
  content: "\F3C0"; }

.fa-link:before {
  content: "\F0C1"; }

.fa-linkedin:before {
  content: "\F08C"; }

.fa-linkedin-in:before {
  content: "\F0E1"; }

.fa-linode:before {
  content: "\F2B8"; }

.fa-linux:before {
  content: "\F17C"; }

.fa-lips:before {
  content: "\F600"; }

.fa-lira-sign:before {
  content: "\F195"; }

.fa-list:before {
  content: "\F03A"; }

.fa-list-alt:before {
  content: "\F022"; }

.fa-list-ol:before {
  content: "\F0CB"; }

.fa-list-ul:before {
  content: "\F0CA"; }

.fa-location:before {
  content: "\F601"; }

.fa-location-arrow:before {
  content: "\F124"; }

.fa-location-circle:before {
  content: "\F602"; }

.fa-location-slash:before {
  content: "\F603"; }

.fa-lock:before {
  content: "\F023"; }

.fa-lock-alt:before {
  content: "\F30D"; }

.fa-lock-open:before {
  content: "\F3C1"; }

.fa-lock-open-alt:before {
  content: "\F3C2"; }

.fa-long-arrow-alt-down:before {
  content: "\F309"; }

.fa-long-arrow-alt-left:before {
  content: "\F30A"; }

.fa-long-arrow-alt-right:before {
  content: "\F30B"; }

.fa-long-arrow-alt-up:before {
  content: "\F30C"; }

.fa-long-arrow-down:before {
  content: "\F175"; }

.fa-long-arrow-left:before {
  content: "\F177"; }

.fa-long-arrow-right:before {
  content: "\F178"; }

.fa-long-arrow-up:before {
  content: "\F176"; }

.fa-loveseat:before {
  content: "\F4CC"; }

.fa-low-vision:before {
  content: "\F2A8"; }

.fa-luchador:before {
  content: "\F455"; }

.fa-luggage-cart:before {
  content: "\F59D"; }

.fa-lungs:before {
  content: "\F604"; }

.fa-lyft:before {
  content: "\F3C3"; }

.fa-mace:before {
  content: "\F6F8"; }

.fa-magento:before {
  content: "\F3C4"; }

.fa-magic:before {
  content: "\F0D0"; }

.fa-magnet:before {
  content: "\F076"; }

.fa-mail-bulk:before {
  content: "\F674"; }

.fa-mailbox:before {
  content: "\F813"; }

.fa-mailchimp:before {
  content: "\F59E"; }

.fa-male:before {
  content: "\F183"; }

.fa-mandalorian:before {
  content: "\F50F"; }

.fa-mandolin:before {
  content: "\F6F9"; }

.fa-map:before {
  content: "\F279"; }

.fa-map-marked:before {
  content: "\F59F"; }

.fa-map-marked-alt:before {
  content: "\F5A0"; }

.fa-map-marker:before {
  content: "\F041"; }

.fa-map-marker-alt:before {
  content: "\F3C5"; }

.fa-map-marker-alt-slash:before {
  content: "\F605"; }

.fa-map-marker-check:before {
  content: "\F606"; }

.fa-map-marker-edit:before {
  content: "\F607"; }

.fa-map-marker-exclamation:before {
  content: "\F608"; }

.fa-map-marker-minus:before {
  content: "\F609"; }

.fa-map-marker-plus:before {
  content: "\F60A"; }

.fa-map-marker-question:before {
  content: "\F60B"; }

.fa-map-marker-slash:before {
  content: "\F60C"; }

.fa-map-marker-smile:before {
  content: "\F60D"; }

.fa-map-marker-times:before {
  content: "\F60E"; }

.fa-map-pin:before {
  content: "\F276"; }

.fa-map-signs:before {
  content: "\F277"; }

.fa-markdown:before {
  content: "\F60F"; }

.fa-marker:before {
  content: "\F5A1"; }

.fa-mars:before {
  content: "\F222"; }

.fa-mars-double:before {
  content: "\F227"; }

.fa-mars-stroke:before {
  content: "\F229"; }

.fa-mars-stroke-h:before {
  content: "\F22B"; }

.fa-mars-stroke-v:before {
  content: "\F22A"; }

.fa-mask:before {
  content: "\F6FA"; }

.fa-mastodon:before {
  content: "\F4F6"; }

.fa-maxcdn:before {
  content: "\F136"; }

.fa-meat:before {
  content: "\F814"; }

.fa-medal:before {
  content: "\F5A2"; }

.fa-medapps:before {
  content: "\F3C6"; }

.fa-medium:before {
  content: "\F23A"; }

.fa-medium-m:before {
  content: "\F3C7"; }

.fa-medkit:before {
  content: "\F0FA"; }

.fa-medrt:before {
  content: "\F3C8"; }

.fa-meetup:before {
  content: "\F2E0"; }

.fa-megaphone:before {
  content: "\F675"; }

.fa-megaport:before {
  content: "\F5A3"; }

.fa-meh:before {
  content: "\F11A"; }

.fa-meh-blank:before {
  content: "\F5A4"; }

.fa-meh-rolling-eyes:before {
  content: "\F5A5"; }

.fa-memory:before {
  content: "\F538"; }

.fa-mendeley:before {
  content: "\F7B3"; }

.fa-menorah:before {
  content: "\F676"; }

.fa-mercury:before {
  content: "\F223"; }

.fa-meteor:before {
  content: "\F753"; }

.fa-microchip:before {
  content: "\F2DB"; }

.fa-microphone:before {
  content: "\F130"; }

.fa-microphone-alt:before {
  content: "\F3C9"; }

.fa-microphone-alt-slash:before {
  content: "\F539"; }

.fa-microphone-slash:before {
  content: "\F131"; }

.fa-microscope:before {
  content: "\F610"; }

.fa-microsoft:before {
  content: "\F3CA"; }

.fa-mind-share:before {
  content: "\F677"; }

.fa-minus:before {
  content: "\F068"; }

.fa-minus-circle:before {
  content: "\F056"; }

.fa-minus-hexagon:before {
  content: "\F307"; }

.fa-minus-octagon:before {
  content: "\F308"; }

.fa-minus-square:before {
  content: "\F146"; }

.fa-mistletoe:before {
  content: "\F7B4"; }

.fa-mitten:before {
  content: "\F7B5"; }

.fa-mix:before {
  content: "\F3CB"; }

.fa-mixcloud:before {
  content: "\F289"; }

.fa-mizuni:before {
  content: "\F3CC"; }

.fa-mobile:before {
  content: "\F10B"; }

.fa-mobile-alt:before {
  content: "\F3CD"; }

.fa-mobile-android:before {
  content: "\F3CE"; }

.fa-mobile-android-alt:before {
  content: "\F3CF"; }

.fa-modx:before {
  content: "\F285"; }

.fa-monero:before {
  content: "\F3D0"; }

.fa-money-bill:before {
  content: "\F0D6"; }

.fa-money-bill-alt:before {
  content: "\F3D1"; }

.fa-money-bill-wave:before {
  content: "\F53A"; }

.fa-money-bill-wave-alt:before {
  content: "\F53B"; }

.fa-money-check:before {
  content: "\F53C"; }

.fa-money-check-alt:before {
  content: "\F53D"; }

.fa-monitor-heart-rate:before {
  content: "\F611"; }

.fa-monkey:before {
  content: "\F6FB"; }

.fa-monument:before {
  content: "\F5A6"; }

.fa-moon:before {
  content: "\F186"; }

.fa-moon-cloud:before {
  content: "\F754"; }

.fa-moon-stars:before {
  content: "\F755"; }

.fa-mortar-pestle:before {
  content: "\F5A7"; }

.fa-mosque:before {
  content: "\F678"; }

.fa-motorcycle:before {
  content: "\F21C"; }

.fa-mountain:before {
  content: "\F6FC"; }

.fa-mountains:before {
  content: "\F6FD"; }

.fa-mouse-pointer:before {
  content: "\F245"; }

.fa-mug-hot:before {
  content: "\F7B6"; }

.fa-mug-marshmallows:before {
  content: "\F7B7"; }

.fa-music:before {
  content: "\F001"; }

.fa-napster:before {
  content: "\F3D2"; }

.fa-narwhal:before {
  content: "\F6FE"; }

.fa-neos:before {
  content: "\F612"; }

.fa-network-wired:before {
  content: "\F6FF"; }

.fa-neuter:before {
  content: "\F22C"; }

.fa-newspaper:before {
  content: "\F1EA"; }

.fa-nimblr:before {
  content: "\F5A8"; }

.fa-nintendo-switch:before {
  content: "\F418"; }

.fa-node:before {
  content: "\F419"; }

.fa-node-js:before {
  content: "\F3D3"; }

.fa-not-equal:before {
  content: "\F53E"; }

.fa-notes-medical:before {
  content: "\F481"; }

.fa-npm:before {
  content: "\F3D4"; }

.fa-ns8:before {
  content: "\F3D5"; }

.fa-nutritionix:before {
  content: "\F3D6"; }

.fa-object-group:before {
  content: "\F247"; }

.fa-object-ungroup:before {
  content: "\F248"; }

.fa-octagon:before {
  content: "\F306"; }

.fa-odnoklassniki:before {
  content: "\F263"; }

.fa-odnoklassniki-square:before {
  content: "\F264"; }

.fa-oil-can:before {
  content: "\F613"; }

.fa-oil-temp:before {
  content: "\F614"; }

.fa-old-republic:before {
  content: "\F510"; }

.fa-om:before {
  content: "\F679"; }

.fa-omega:before {
  content: "\F67A"; }

.fa-opencart:before {
  content: "\F23D"; }

.fa-openid:before {
  content: "\F19B"; }

.fa-opera:before {
  content: "\F26A"; }

.fa-optin-monster:before {
  content: "\F23C"; }

.fa-ornament:before {
  content: "\F7B8"; }

.fa-osi:before {
  content: "\F41A"; }

.fa-otter:before {
  content: "\F700"; }

.fa-outdent:before {
  content: "\F03B"; }

.fa-page4:before {
  content: "\F3D7"; }

.fa-pagelines:before {
  content: "\F18C"; }

.fa-pager:before {
  content: "\F815"; }

.fa-paint-brush:before {
  content: "\F1FC"; }

.fa-paint-brush-alt:before {
  content: "\F5A9"; }

.fa-paint-roller:before {
  content: "\F5AA"; }

.fa-palette:before {
  content: "\F53F"; }

.fa-palfed:before {
  content: "\F3D8"; }

.fa-pallet:before {
  content: "\F482"; }

.fa-pallet-alt:before {
  content: "\F483"; }

.fa-paper-plane:before {
  content: "\F1D8"; }

.fa-paperclip:before {
  content: "\F0C6"; }

.fa-parachute-box:before {
  content: "\F4CD"; }

.fa-paragraph:before {
  content: "\F1DD"; }

.fa-parking:before {
  content: "\F540"; }

.fa-parking-circle:before {
  content: "\F615"; }

.fa-parking-circle-slash:before {
  content: "\F616"; }

.fa-parking-slash:before {
  content: "\F617"; }

.fa-passport:before {
  content: "\F5AB"; }

.fa-pastafarianism:before {
  content: "\F67B"; }

.fa-paste:before {
  content: "\F0EA"; }

.fa-patreon:before {
  content: "\F3D9"; }

.fa-pause:before {
  content: "\F04C"; }

.fa-pause-circle:before {
  content: "\F28B"; }

.fa-paw:before {
  content: "\F1B0"; }

.fa-paw-alt:before {
  content: "\F701"; }

.fa-paw-claws:before {
  content: "\F702"; }

.fa-paypal:before {
  content: "\F1ED"; }

.fa-peace:before {
  content: "\F67C"; }

.fa-pegasus:before {
  content: "\F703"; }

.fa-pen:before {
  content: "\F304"; }

.fa-pen-alt:before {
  content: "\F305"; }

.fa-pen-fancy:before {
  content: "\F5AC"; }

.fa-pen-nib:before {
  content: "\F5AD"; }

.fa-pen-square:before {
  content: "\F14B"; }

.fa-pencil:before {
  content: "\F040"; }

.fa-pencil-alt:before {
  content: "\F303"; }

.fa-pencil-paintbrush:before {
  content: "\F618"; }

.fa-pencil-ruler:before {
  content: "\F5AE"; }

.fa-pennant:before {
  content: "\F456"; }

.fa-penny-arcade:before {
  content: "\F704"; }

.fa-people-carry:before {
  content: "\F4CE"; }

.fa-pepper-hot:before {
  content: "\F816"; }

.fa-percent:before {
  content: "\F295"; }

.fa-percentage:before {
  content: "\F541"; }

.fa-periscope:before {
  content: "\F3DA"; }

.fa-person-booth:before {
  content: "\F756"; }

.fa-person-carry:before {
  content: "\F4CF"; }

.fa-person-dolly:before {
  content: "\F4D0"; }

.fa-person-dolly-empty:before {
  content: "\F4D1"; }

.fa-person-sign:before {
  content: "\F757"; }

.fa-phabricator:before {
  content: "\F3DB"; }

.fa-phoenix-framework:before {
  content: "\F3DC"; }

.fa-phoenix-squadron:before {
  content: "\F511"; }

.fa-phone:before {
  content: "\F095"; }

.fa-phone-office:before {
  content: "\F67D"; }

.fa-phone-plus:before {
  content: "\F4D2"; }

.fa-phone-slash:before {
  content: "\F3DD"; }

.fa-phone-square:before {
  content: "\F098"; }

.fa-phone-volume:before {
  content: "\F2A0"; }

.fa-php:before {
  content: "\F457"; }

.fa-pi:before {
  content: "\F67E"; }

.fa-pie:before {
  content: "\F705"; }

.fa-pied-piper:before {
  content: "\F2AE"; }

.fa-pied-piper-alt:before {
  content: "\F1A8"; }

.fa-pied-piper-hat:before {
  content: "\F4E5"; }

.fa-pied-piper-pp:before {
  content: "\F1A7"; }

.fa-pig:before {
  content: "\F706"; }

.fa-piggy-bank:before {
  content: "\F4D3"; }

.fa-pills:before {
  content: "\F484"; }

.fa-pinterest:before {
  content: "\F0D2"; }

.fa-pinterest-p:before {
  content: "\F231"; }

.fa-pinterest-square:before {
  content: "\F0D3"; }

.fa-pizza:before {
  content: "\F817"; }

.fa-pizza-slice:before {
  content: "\F818"; }

.fa-place-of-worship:before {
  content: "\F67F"; }

.fa-plane:before {
  content: "\F072"; }

.fa-plane-alt:before {
  content: "\F3DE"; }

.fa-plane-arrival:before {
  content: "\F5AF"; }

.fa-plane-departure:before {
  content: "\F5B0"; }

.fa-play:before {
  content: "\F04B"; }

.fa-play-circle:before {
  content: "\F144"; }

.fa-playstation:before {
  content: "\F3DF"; }

.fa-plug:before {
  content: "\F1E6"; }

.fa-plus:before {
  content: "\F067"; }

.fa-plus-circle:before {
  content: "\F055"; }

.fa-plus-hexagon:before {
  content: "\F300"; }

.fa-plus-octagon:before {
  content: "\F301"; }

.fa-plus-square:before {
  content: "\F0FE"; }

.fa-podcast:before {
  content: "\F2CE"; }

.fa-podium:before {
  content: "\F680"; }

.fa-podium-star:before {
  content: "\F758"; }

.fa-poll:before {
  content: "\F681"; }

.fa-poll-h:before {
  content: "\F682"; }

.fa-poll-people:before {
  content: "\F759"; }

.fa-poo:before {
  content: "\F2FE"; }

.fa-poo-storm:before {
  content: "\F75A"; }

.fa-poop:before {
  content: "\F619"; }

.fa-popcorn:before {
  content: "\F819"; }

.fa-portrait:before {
  content: "\F3E0"; }

.fa-pound-sign:before {
  content: "\F154"; }

.fa-power-off:before {
  content: "\F011"; }

.fa-pray:before {
  content: "\F683"; }

.fa-praying-hands:before {
  content: "\F684"; }

.fa-prescription:before {
  content: "\F5B1"; }

.fa-prescription-bottle:before {
  content: "\F485"; }

.fa-prescription-bottle-alt:before {
  content: "\F486"; }

.fa-presentation:before {
  content: "\F685"; }

.fa-print:before {
  content: "\F02F"; }

.fa-print-search:before {
  content: "\F81A"; }

.fa-print-slash:before {
  content: "\F686"; }

.fa-procedures:before {
  content: "\F487"; }

.fa-product-hunt:before {
  content: "\F288"; }

.fa-project-diagram:before {
  content: "\F542"; }

.fa-pumpkin:before {
  content: "\F707"; }

.fa-pushed:before {
  content: "\F3E1"; }

.fa-puzzle-piece:before {
  content: "\F12E"; }

.fa-python:before {
  content: "\F3E2"; }

.fa-qq:before {
  content: "\F1D6"; }

.fa-qrcode:before {
  content: "\F029"; }

.fa-question:before {
  content: "\F128"; }

.fa-question-circle:before {
  content: "\F059"; }

.fa-question-square:before {
  content: "\F2FD"; }

.fa-quidditch:before {
  content: "\F458"; }

.fa-quinscape:before {
  content: "\F459"; }

.fa-quora:before {
  content: "\F2C4"; }

.fa-quote-left:before {
  content: "\F10D"; }

.fa-quote-right:before {
  content: "\F10E"; }

.fa-quran:before {
  content: "\F687"; }

.fa-r-project:before {
  content: "\F4F7"; }

.fa-rabbit:before {
  content: "\F708"; }

.fa-rabbit-fast:before {
  content: "\F709"; }

.fa-racquet:before {
  content: "\F45A"; }

.fa-radiation:before {
  content: "\F7B9"; }

.fa-radiation-alt:before {
  content: "\F7BA"; }

.fa-rainbow:before {
  content: "\F75B"; }

.fa-raindrops:before {
  content: "\F75C"; }

.fa-ram:before {
  content: "\F70A"; }

.fa-ramp-loading:before {
  content: "\F4D4"; }

.fa-random:before {
  content: "\F074"; }

.fa-raspberry-pi:before {
  content: "\F7BB"; }

.fa-ravelry:before {
  content: "\F2D9"; }

.fa-react:before {
  content: "\F41B"; }

.fa-reacteurope:before {
  content: "\F75D"; }

.fa-readme:before {
  content: "\F4D5"; }

.fa-rebel:before {
  content: "\F1D0"; }

.fa-receipt:before {
  content: "\F543"; }

.fa-rectangle-landscape:before {
  content: "\F2FA"; }

.fa-rectangle-portrait:before {
  content: "\F2FB"; }

.fa-rectangle-wide:before {
  content: "\F2FC"; }

.fa-recycle:before {
  content: "\F1B8"; }

.fa-red-river:before {
  content: "\F3E3"; }

.fa-reddit:before {
  content: "\F1A1"; }

.fa-reddit-alien:before {
  content: "\F281"; }

.fa-reddit-square:before {
  content: "\F1A2"; }

.fa-redhat:before {
  content: "\F7BC"; }

.fa-redo:before {
  content: "\F01E"; }

.fa-redo-alt:before {
  content: "\F2F9"; }

.fa-registered:before {
  content: "\F25D"; }

.fa-renren:before {
  content: "\F18B"; }

.fa-repeat:before {
  content: "\F363"; }

.fa-repeat-1:before {
  content: "\F365"; }

.fa-repeat-1-alt:before {
  content: "\F366"; }

.fa-repeat-alt:before {
  content: "\F364"; }

.fa-reply:before {
  content: "\F3E5"; }

.fa-reply-all:before {
  content: "\F122"; }

.fa-replyd:before {
  content: "\F3E6"; }

.fa-republican:before {
  content: "\F75E"; }

.fa-researchgate:before {
  content: "\F4F8"; }

.fa-resolving:before {
  content: "\F3E7"; }

.fa-restroom:before {
  content: "\F7BD"; }

.fa-retweet:before {
  content: "\F079"; }

.fa-retweet-alt:before {
  content: "\F361"; }

.fa-rev:before {
  content: "\F5B2"; }

.fa-ribbon:before {
  content: "\F4D6"; }

.fa-ring:before {
  content: "\F70B"; }

.fa-rings-wedding:before {
  content: "\F81B"; }

.fa-road:before {
  content: "\F018"; }

.fa-robot:before {
  content: "\F544"; }

.fa-rocket:before {
  content: "\F135"; }

.fa-rocketchat:before {
  content: "\F3E8"; }

.fa-rockrms:before {
  content: "\F3E9"; }

.fa-route:before {
  content: "\F4D7"; }

.fa-route-highway:before {
  content: "\F61A"; }

.fa-route-interstate:before {
  content: "\F61B"; }

.fa-rss:before {
  content: "\F09E"; }

.fa-rss-square:before {
  content: "\F143"; }

.fa-ruble-sign:before {
  content: "\F158"; }

.fa-ruler:before {
  content: "\F545"; }

.fa-ruler-combined:before {
  content: "\F546"; }

.fa-ruler-horizontal:before {
  content: "\F547"; }

.fa-ruler-triangle:before {
  content: "\F61C"; }

.fa-ruler-vertical:before {
  content: "\F548"; }

.fa-running:before {
  content: "\F70C"; }

.fa-rupee-sign:before {
  content: "\F156"; }

.fa-rv:before {
  content: "\F7BE"; }

.fa-sack:before {
  content: "\F81C"; }

.fa-sack-dollar:before {
  content: "\F81D"; }

.fa-sad-cry:before {
  content: "\F5B3"; }

.fa-sad-tear:before {
  content: "\F5B4"; }

.fa-safari:before {
  content: "\F267"; }

.fa-salad:before {
  content: "\F81E"; }

.fa-sandwich:before {
  content: "\F81F"; }

.fa-sass:before {
  content: "\F41E"; }

.fa-satellite:before {
  content: "\F7BF"; }

.fa-satellite-dish:before {
  content: "\F7C0"; }

.fa-sausage:before {
  content: "\F820"; }

.fa-save:before {
  content: "\F0C7"; }

.fa-scalpel:before {
  content: "\F61D"; }

.fa-scalpel-path:before {
  content: "\F61E"; }

.fa-scanner:before {
  content: "\F488"; }

.fa-scanner-keyboard:before {
  content: "\F489"; }

.fa-scanner-touchscreen:before {
  content: "\F48A"; }

.fa-scarecrow:before {
  content: "\F70D"; }

.fa-scarf:before {
  content: "\F7C1"; }

.fa-schlix:before {
  content: "\F3EA"; }

.fa-school:before {
  content: "\F549"; }

.fa-screwdriver:before {
  content: "\F54A"; }

.fa-scribd:before {
  content: "\F28A"; }

.fa-scroll:before {
  content: "\F70E"; }

.fa-scroll-old:before {
  content: "\F70F"; }

.fa-scrubber:before {
  content: "\F2F8"; }

.fa-scythe:before {
  content: "\F710"; }

.fa-sd-card:before {
  content: "\F7C2"; }

.fa-search:before {
  content: "\F002"; }

.fa-search-dollar:before {
  content: "\F688"; }

.fa-search-location:before {
  content: "\F689"; }

.fa-search-minus:before {
  content: "\F010"; }

.fa-search-plus:before {
  content: "\F00E"; }

.fa-searchengin:before {
  content: "\F3EB"; }

.fa-seedling:before {
  content: "\F4D8"; }

.fa-sellcast:before {
  content: "\F2DA"; }

.fa-sellsy:before {
  content: "\F213"; }

.fa-server:before {
  content: "\F233"; }

.fa-servicestack:before {
  content: "\F3EC"; }

.fa-shapes:before {
  content: "\F61F"; }

.fa-share:before {
  content: "\F064"; }

.fa-share-all:before {
  content: "\F367"; }

.fa-share-alt:before {
  content: "\F1E0"; }

.fa-share-alt-square:before {
  content: "\F1E1"; }

.fa-share-square:before {
  content: "\F14D"; }

.fa-sheep:before {
  content: "\F711"; }

.fa-shekel-sign:before {
  content: "\F20B"; }

.fa-shield:before {
  content: "\F132"; }

.fa-shield-alt:before {
  content: "\F3ED"; }

.fa-shield-check:before {
  content: "\F2F7"; }

.fa-shield-cross:before {
  content: "\F712"; }

.fa-ship:before {
  content: "\F21A"; }

.fa-shipping-fast:before {
  content: "\F48B"; }

.fa-shipping-timed:before {
  content: "\F48C"; }

.fa-shirtsinbulk:before {
  content: "\F214"; }

.fa-shish-kebab:before {
  content: "\F821"; }

.fa-shoe-prints:before {
  content: "\F54B"; }

.fa-shopping-bag:before {
  content: "\F290"; }

.fa-shopping-basket:before {
  content: "\F291"; }

.fa-shopping-cart:before {
  content: "\F07A"; }

.fa-shopware:before {
  content: "\F5B5"; }

.fa-shovel:before {
  content: "\F713"; }

.fa-shovel-snow:before {
  content: "\F7C3"; }

.fa-shower:before {
  content: "\F2CC"; }

.fa-shredder:before {
  content: "\F68A"; }

.fa-shuttle-van:before {
  content: "\F5B6"; }

.fa-shuttlecock:before {
  content: "\F45B"; }

.fa-sickle:before {
  content: "\F822"; }

.fa-sigma:before {
  content: "\F68B"; }

.fa-sign:before {
  content: "\F4D9"; }

.fa-sign-in:before {
  content: "\F090"; }

.fa-sign-in-alt:before {
  content: "\F2F6"; }

.fa-sign-language:before {
  content: "\F2A7"; }

.fa-sign-out:before {
  content: "\F08B"; }

.fa-sign-out-alt:before {
  content: "\F2F5"; }

.fa-signal:before {
  content: "\F012"; }

.fa-signal-1:before {
  content: "\F68C"; }

.fa-signal-2:before {
  content: "\F68D"; }

.fa-signal-3:before {
  content: "\F68E"; }

.fa-signal-4:before {
  content: "\F68F"; }

.fa-signal-alt:before {
  content: "\F690"; }

.fa-signal-alt-1:before {
  content: "\F691"; }

.fa-signal-alt-2:before {
  content: "\F692"; }

.fa-signal-alt-3:before {
  content: "\F693"; }

.fa-signal-alt-slash:before {
  content: "\F694"; }

.fa-signal-slash:before {
  content: "\F695"; }

.fa-signature:before {
  content: "\F5B7"; }

.fa-sim-card:before {
  content: "\F7C4"; }

.fa-simplybuilt:before {
  content: "\F215"; }

.fa-sistrix:before {
  content: "\F3EE"; }

.fa-sitemap:before {
  content: "\F0E8"; }

.fa-sith:before {
  content: "\F512"; }

.fa-skating:before {
  content: "\F7C5"; }

.fa-skeleton:before {
  content: "\F620"; }

.fa-sketch:before {
  content: "\F7C6"; }

.fa-ski-jump:before {
  content: "\F7C7"; }

.fa-ski-lift:before {
  content: "\F7C8"; }

.fa-skiing:before {
  content: "\F7C9"; }

.fa-skiing-nordic:before {
  content: "\F7CA"; }

.fa-skull:before {
  content: "\F54C"; }

.fa-skull-crossbones:before {
  content: "\F714"; }

.fa-skyatlas:before {
  content: "\F216"; }

.fa-skype:before {
  content: "\F17E"; }

.fa-slack:before {
  content: "\F198"; }

.fa-slack-hash:before {
  content: "\F3EF"; }

.fa-slash:before {
  content: "\F715"; }

.fa-sledding:before {
  content: "\F7CB"; }

.fa-sleigh:before {
  content: "\F7CC"; }

.fa-sliders-h:before {
  content: "\F1DE"; }

.fa-sliders-h-square:before {
  content: "\F3F0"; }

.fa-sliders-v:before {
  content: "\F3F1"; }

.fa-sliders-v-square:before {
  content: "\F3F2"; }

.fa-slideshare:before {
  content: "\F1E7"; }

.fa-smile:before {
  content: "\F118"; }

.fa-smile-beam:before {
  content: "\F5B8"; }

.fa-smile-plus:before {
  content: "\F5B9"; }

.fa-smile-wink:before {
  content: "\F4DA"; }

.fa-smog:before {
  content: "\F75F"; }

.fa-smoke:before {
  content: "\F760"; }

.fa-smoking:before {
  content: "\F48D"; }

.fa-smoking-ban:before {
  content: "\F54D"; }

.fa-sms:before {
  content: "\F7CD"; }

.fa-snake:before {
  content: "\F716"; }

.fa-snapchat:before {
  content: "\F2AB"; }

.fa-snapchat-ghost:before {
  content: "\F2AC"; }

.fa-snapchat-square:before {
  content: "\F2AD"; }

.fa-snow-blowing:before {
  content: "\F761"; }

.fa-snowboarding:before {
  content: "\F7CE"; }

.fa-snowflake:before {
  content: "\F2DC"; }

.fa-snowflakes:before {
  content: "\F7CF"; }

.fa-snowman:before {
  content: "\F7D0"; }

.fa-snowmobile:before {
  content: "\F7D1"; }

.fa-snowplow:before {
  content: "\F7D2"; }

.fa-socks:before {
  content: "\F696"; }

.fa-solar-panel:before {
  content: "\F5BA"; }

.fa-sort:before {
  content: "\F0DC"; }

.fa-sort-alpha-down:before {
  content: "\F15D"; }

.fa-sort-alpha-up:before {
  content: "\F15E"; }

.fa-sort-amount-down:before {
  content: "\F160"; }

.fa-sort-amount-up:before {
  content: "\F161"; }

.fa-sort-down:before {
  content: "\F0DD"; }

.fa-sort-numeric-down:before {
  content: "\F162"; }

.fa-sort-numeric-up:before {
  content: "\F163"; }

.fa-sort-up:before {
  content: "\F0DE"; }

.fa-soundcloud:before {
  content: "\F1BE"; }

.fa-soup:before {
  content: "\F823"; }

.fa-sourcetree:before {
  content: "\F7D3"; }

.fa-spa:before {
  content: "\F5BB"; }

.fa-space-shuttle:before {
  content: "\F197"; }

.fa-spade:before {
  content: "\F2F4"; }

.fa-speakap:before {
  content: "\F3F3"; }

.fa-spider:before {
  content: "\F717"; }

.fa-spider-black-widow:before {
  content: "\F718"; }

.fa-spider-web:before {
  content: "\F719"; }

.fa-spinner:before {
  content: "\F110"; }

.fa-spinner-third:before {
  content: "\F3F4"; }

.fa-splotch:before {
  content: "\F5BC"; }

.fa-spotify:before {
  content: "\F1BC"; }

.fa-spray-can:before {
  content: "\F5BD"; }

.fa-square:before {
  content: "\F0C8"; }

.fa-square-full:before {
  content: "\F45C"; }

.fa-square-root:before {
  content: "\F697"; }

.fa-square-root-alt:before {
  content: "\F698"; }

.fa-squarespace:before {
  content: "\F5BE"; }

.fa-squirrel:before {
  content: "\F71A"; }

.fa-stack-exchange:before {
  content: "\F18D"; }

.fa-stack-overflow:before {
  content: "\F16C"; }

.fa-staff:before {
  content: "\F71B"; }

.fa-stamp:before {
  content: "\F5BF"; }

.fa-star:before {
  content: "\F005"; }

.fa-star-and-crescent:before {
  content: "\F699"; }

.fa-star-christmas:before {
  content: "\F7D4"; }

.fa-star-exclamation:before {
  content: "\F2F3"; }

.fa-star-half:before {
  content: "\F089"; }

.fa-star-half-alt:before {
  content: "\F5C0"; }

.fa-star-of-david:before {
  content: "\F69A"; }

.fa-star-of-life:before {
  content: "\F621"; }

.fa-stars:before {
  content: "\F762"; }

.fa-staylinked:before {
  content: "\F3F5"; }

.fa-steak:before {
  content: "\F824"; }

.fa-steam:before {
  content: "\F1B6"; }

.fa-steam-square:before {
  content: "\F1B7"; }

.fa-steam-symbol:before {
  content: "\F3F6"; }

.fa-steering-wheel:before {
  content: "\F622"; }

.fa-step-backward:before {
  content: "\F048"; }

.fa-step-forward:before {
  content: "\F051"; }

.fa-stethoscope:before {
  content: "\F0F1"; }

.fa-sticker-mule:before {
  content: "\F3F7"; }

.fa-sticky-note:before {
  content: "\F249"; }

.fa-stocking:before {
  content: "\F7D5"; }

.fa-stomach:before {
  content: "\F623"; }

.fa-stop:before {
  content: "\F04D"; }

.fa-stop-circle:before {
  content: "\F28D"; }

.fa-stopwatch:before {
  content: "\F2F2"; }

.fa-store:before {
  content: "\F54E"; }

.fa-store-alt:before {
  content: "\F54F"; }

.fa-strava:before {
  content: "\F428"; }

.fa-stream:before {
  content: "\F550"; }

.fa-street-view:before {
  content: "\F21D"; }

.fa-stretcher:before {
  content: "\F825"; }

.fa-strikethrough:before {
  content: "\F0CC"; }

.fa-stripe:before {
  content: "\F429"; }

.fa-stripe-s:before {
  content: "\F42A"; }

.fa-stroopwafel:before {
  content: "\F551"; }

.fa-studiovinari:before {
  content: "\F3F8"; }

.fa-stumbleupon:before {
  content: "\F1A4"; }

.fa-stumbleupon-circle:before {
  content: "\F1A3"; }

.fa-subscript:before {
  content: "\F12C"; }

.fa-subway:before {
  content: "\F239"; }

.fa-suitcase:before {
  content: "\F0F2"; }

.fa-suitcase-rolling:before {
  content: "\F5C1"; }

.fa-sun:before {
  content: "\F185"; }

.fa-sun-cloud:before {
  content: "\F763"; }

.fa-sun-dust:before {
  content: "\F764"; }

.fa-sun-haze:before {
  content: "\F765"; }

.fa-sunrise:before {
  content: "\F766"; }

.fa-sunset:before {
  content: "\F767"; }

.fa-superpowers:before {
  content: "\F2DD"; }

.fa-superscript:before {
  content: "\F12B"; }

.fa-supple:before {
  content: "\F3F9"; }

.fa-surprise:before {
  content: "\F5C2"; }

.fa-suse:before {
  content: "\F7D6"; }

.fa-swatchbook:before {
  content: "\F5C3"; }

.fa-swimmer:before {
  content: "\F5C4"; }

.fa-swimming-pool:before {
  content: "\F5C5"; }

.fa-sword:before {
  content: "\F71C"; }

.fa-swords:before {
  content: "\F71D"; }

.fa-synagogue:before {
  content: "\F69B"; }

.fa-sync:before {
  content: "\F021"; }

.fa-sync-alt:before {
  content: "\F2F1"; }

.fa-syringe:before {
  content: "\F48E"; }

.fa-table:before {
  content: "\F0CE"; }

.fa-table-tennis:before {
  content: "\F45D"; }

.fa-tablet:before {
  content: "\F10A"; }

.fa-tablet-alt:before {
  content: "\F3FA"; }

.fa-tablet-android:before {
  content: "\F3FB"; }

.fa-tablet-android-alt:before {
  content: "\F3FC"; }

.fa-tablet-rugged:before {
  content: "\F48F"; }

.fa-tablets:before {
  content: "\F490"; }

.fa-tachometer:before {
  content: "\F0E4"; }

.fa-tachometer-alt:before {
  content: "\F3FD"; }

.fa-tachometer-alt-average:before {
  content: "\F624"; }

.fa-tachometer-alt-fast:before {
  content: "\F625"; }

.fa-tachometer-alt-fastest:before {
  content: "\F626"; }

.fa-tachometer-alt-slow:before {
  content: "\F627"; }

.fa-tachometer-alt-slowest:before {
  content: "\F628"; }

.fa-tachometer-average:before {
  content: "\F629"; }

.fa-tachometer-fast:before {
  content: "\F62A"; }

.fa-tachometer-fastest:before {
  content: "\F62B"; }

.fa-tachometer-slow:before {
  content: "\F62C"; }

.fa-tachometer-slowest:before {
  content: "\F62D"; }

.fa-taco:before {
  content: "\F826"; }

.fa-tag:before {
  content: "\F02B"; }

.fa-tags:before {
  content: "\F02C"; }

.fa-tally:before {
  content: "\F69C"; }

.fa-tanakh:before {
  content: "\F827"; }

.fa-tape:before {
  content: "\F4DB"; }

.fa-tasks:before {
  content: "\F0AE"; }

.fa-tasks-alt:before {
  content: "\F828"; }

.fa-taxi:before {
  content: "\F1BA"; }

.fa-teamspeak:before {
  content: "\F4F9"; }

.fa-teeth:before {
  content: "\F62E"; }

.fa-teeth-open:before {
  content: "\F62F"; }

.fa-telegram:before {
  content: "\F2C6"; }

.fa-telegram-plane:before {
  content: "\F3FE"; }

.fa-temperature-frigid:before {
  content: "\F768"; }

.fa-temperature-high:before {
  content: "\F769"; }

.fa-temperature-hot:before {
  content: "\F76A"; }

.fa-temperature-low:before {
  content: "\F76B"; }

.fa-tencent-weibo:before {
  content: "\F1D5"; }

.fa-tenge:before {
  content: "\F7D7"; }

.fa-tennis-ball:before {
  content: "\F45E"; }

.fa-terminal:before {
  content: "\F120"; }

.fa-text-height:before {
  content: "\F034"; }

.fa-text-width:before {
  content: "\F035"; }

.fa-th:before {
  content: "\F00A"; }

.fa-th-large:before {
  content: "\F009"; }

.fa-th-list:before {
  content: "\F00B"; }

.fa-the-red-yeti:before {
  content: "\F69D"; }

.fa-theater-masks:before {
  content: "\F630"; }

.fa-themeco:before {
  content: "\F5C6"; }

.fa-themeisle:before {
  content: "\F2B2"; }

.fa-thermometer:before {
  content: "\F491"; }

.fa-thermometer-empty:before {
  content: "\F2CB"; }

.fa-thermometer-full:before {
  content: "\F2C7"; }

.fa-thermometer-half:before {
  content: "\F2C9"; }

.fa-thermometer-quarter:before {
  content: "\F2CA"; }

.fa-thermometer-three-quarters:before {
  content: "\F2C8"; }

.fa-theta:before {
  content: "\F69E"; }

.fa-think-peaks:before {
  content: "\F731"; }

.fa-thumbs-down:before {
  content: "\F165"; }

.fa-thumbs-up:before {
  content: "\F164"; }

.fa-thumbtack:before {
  content: "\F08D"; }

.fa-thunderstorm:before {
  content: "\F76C"; }

.fa-thunderstorm-moon:before {
  content: "\F76D"; }

.fa-thunderstorm-sun:before {
  content: "\F76E"; }

.fa-ticket:before {
  content: "\F145"; }

.fa-ticket-alt:before {
  content: "\F3FF"; }

.fa-tilde:before {
  content: "\F69F"; }

.fa-times:before {
  content: "\F00D"; }

.fa-times-circle:before {
  content: "\F057"; }

.fa-times-hexagon:before {
  content: "\F2EE"; }

.fa-times-octagon:before {
  content: "\F2F0"; }

.fa-times-square:before {
  content: "\F2D3"; }

.fa-tint:before {
  content: "\F043"; }

.fa-tint-slash:before {
  content: "\F5C7"; }

.fa-tire:before {
  content: "\F631"; }

.fa-tire-flat:before {
  content: "\F632"; }

.fa-tire-pressure-warning:before {
  content: "\F633"; }

.fa-tire-rugged:before {
  content: "\F634"; }

.fa-tired:before {
  content: "\F5C8"; }

.fa-toggle-off:before {
  content: "\F204"; }

.fa-toggle-on:before {
  content: "\F205"; }

.fa-toilet:before {
  content: "\F7D8"; }

.fa-toilet-paper:before {
  content: "\F71E"; }

.fa-toilet-paper-alt:before {
  content: "\F71F"; }

.fa-tombstone:before {
  content: "\F720"; }

.fa-tombstone-alt:before {
  content: "\F721"; }

.fa-toolbox:before {
  content: "\F552"; }

.fa-tools:before {
  content: "\F7D9"; }

.fa-tooth:before {
  content: "\F5C9"; }

.fa-toothbrush:before {
  content: "\F635"; }

.fa-torah:before {
  content: "\F6A0"; }

.fa-torii-gate:before {
  content: "\F6A1"; }

.fa-tornado:before {
  content: "\F76F"; }

.fa-tractor:before {
  content: "\F722"; }

.fa-trade-federation:before {
  content: "\F513"; }

.fa-trademark:before {
  content: "\F25C"; }

.fa-traffic-cone:before {
  content: "\F636"; }

.fa-traffic-light:before {
  content: "\F637"; }

.fa-traffic-light-go:before {
  content: "\F638"; }

.fa-traffic-light-slow:before {
  content: "\F639"; }

.fa-traffic-light-stop:before {
  content: "\F63A"; }

.fa-train:before {
  content: "\F238"; }

.fa-tram:before {
  content: "\F7DA"; }

.fa-transgender:before {
  content: "\F224"; }

.fa-transgender-alt:before {
  content: "\F225"; }

.fa-trash:before {
  content: "\F1F8"; }

.fa-trash-alt:before {
  content: "\F2ED"; }

.fa-trash-restore:before {
  content: "\F829"; }

.fa-trash-restore-alt:before {
  content: "\F82A"; }

.fa-treasure-chest:before {
  content: "\F723"; }

.fa-tree:before {
  content: "\F1BB"; }

.fa-tree-alt:before {
  content: "\F400"; }

.fa-tree-christmas:before {
  content: "\F7DB"; }

.fa-tree-decorated:before {
  content: "\F7DC"; }

.fa-tree-large:before {
  content: "\F7DD"; }

.fa-tree-palm:before {
  content: "\F82B"; }

.fa-trees:before {
  content: "\F724"; }

.fa-trello:before {
  content: "\F181"; }

.fa-triangle:before {
  content: "\F2EC"; }

.fa-tripadvisor:before {
  content: "\F262"; }

.fa-trophy:before {
  content: "\F091"; }

.fa-trophy-alt:before {
  content: "\F2EB"; }

.fa-truck:before {
  content: "\F0D1"; }

.fa-truck-container:before {
  content: "\F4DC"; }

.fa-truck-couch:before {
  content: "\F4DD"; }

.fa-truck-loading:before {
  content: "\F4DE"; }

.fa-truck-monster:before {
  content: "\F63B"; }

.fa-truck-moving:before {
  content: "\F4DF"; }

.fa-truck-pickup:before {
  content: "\F63C"; }

.fa-truck-plow:before {
  content: "\F7DE"; }

.fa-truck-ramp:before {
  content: "\F4E0"; }

.fa-tshirt:before {
  content: "\F553"; }

.fa-tty:before {
  content: "\F1E4"; }

.fa-tumblr:before {
  content: "\F173"; }

.fa-tumblr-square:before {
  content: "\F174"; }

.fa-turkey:before {
  content: "\F725"; }

.fa-turtle:before {
  content: "\F726"; }

.fa-tv:before {
  content: "\F26C"; }

.fa-tv-retro:before {
  content: "\F401"; }

.fa-twitch:before {
  content: "\F1E8"; }

.fa-twitter:before {
  content: "\F099"; }

.fa-twitter-square:before {
  content: "\F081"; }

.fa-typo3:before {
  content: "\F42B"; }

.fa-uber:before {
  content: "\F402"; }

.fa-ubuntu:before {
  content: "\F7DF"; }

.fa-uikit:before {
  content: "\F403"; }

.fa-umbrella:before {
  content: "\F0E9"; }

.fa-umbrella-beach:before {
  content: "\F5CA"; }

.fa-underline:before {
  content: "\F0CD"; }

.fa-undo:before {
  content: "\F0E2"; }

.fa-undo-alt:before {
  content: "\F2EA"; }

.fa-unicorn:before {
  content: "\F727"; }

.fa-union:before {
  content: "\F6A2"; }

.fa-uniregistry:before {
  content: "\F404"; }

.fa-universal-access:before {
  content: "\F29A"; }

.fa-university:before {
  content: "\F19C"; }

.fa-unlink:before {
  content: "\F127"; }

.fa-unlock:before {
  content: "\F09C"; }

.fa-unlock-alt:before {
  content: "\F13E"; }

.fa-untappd:before {
  content: "\F405"; }

.fa-upload:before {
  content: "\F093"; }

.fa-ups:before {
  content: "\F7E0"; }

.fa-usb:before {
  content: "\F287"; }

.fa-usd-circle:before {
  content: "\F2E8"; }

.fa-usd-square:before {
  content: "\F2E9"; }

.fa-user:before {
  content: "\F007"; }

.fa-user-alt:before {
  content: "\F406"; }

.fa-user-alt-slash:before {
  content: "\F4FA"; }

.fa-user-astronaut:before {
  content: "\F4FB"; }

.fa-user-chart:before {
  content: "\F6A3"; }

.fa-user-check:before {
  content: "\F4FC"; }

.fa-user-circle:before {
  content: "\F2BD"; }

.fa-user-clock:before {
  content: "\F4FD"; }

.fa-user-cog:before {
  content: "\F4FE"; }

.fa-user-crown:before {
  content: "\F6A4"; }

.fa-user-edit:before {
  content: "\F4FF"; }

.fa-user-friends:before {
  content: "\F500"; }

.fa-user-graduate:before {
  content: "\F501"; }

.fa-user-hard-hat:before {
  content: "\F82C"; }

.fa-user-headset:before {
  content: "\F82D"; }

.fa-user-injured:before {
  content: "\F728"; }

.fa-user-lock:before {
  content: "\F502"; }

.fa-user-md:before {
  content: "\F0F0"; }

.fa-user-md-chat:before {
  content: "\F82E"; }

.fa-user-minus:before {
  content: "\F503"; }

.fa-user-ninja:before {
  content: "\F504"; }

.fa-user-nurse:before {
  content: "\F82F"; }

.fa-user-plus:before {
  content: "\F234"; }

.fa-user-secret:before {
  content: "\F21B"; }

.fa-user-shield:before {
  content: "\F505"; }

.fa-user-slash:before {
  content: "\F506"; }

.fa-user-tag:before {
  content: "\F507"; }

.fa-user-tie:before {
  content: "\F508"; }

.fa-user-times:before {
  content: "\F235"; }

.fa-users:before {
  content: "\F0C0"; }

.fa-users-class:before {
  content: "\F63D"; }

.fa-users-cog:before {
  content: "\F509"; }

.fa-users-crown:before {
  content: "\F6A5"; }

.fa-users-medical:before {
  content: "\F830"; }

.fa-usps:before {
  content: "\F7E1"; }

.fa-ussunnah:before {
  content: "\F407"; }

.fa-utensil-fork:before {
  content: "\F2E3"; }

.fa-utensil-knife:before {
  content: "\F2E4"; }

.fa-utensil-spoon:before {
  content: "\F2E5"; }

.fa-utensils:before {
  content: "\F2E7"; }

.fa-utensils-alt:before {
  content: "\F2E6"; }

.fa-vaadin:before {
  content: "\F408"; }

.fa-value-absolute:before {
  content: "\F6A6"; }

.fa-vector-square:before {
  content: "\F5CB"; }

.fa-venus:before {
  content: "\F221"; }

.fa-venus-double:before {
  content: "\F226"; }

.fa-venus-mars:before {
  content: "\F228"; }

.fa-viacoin:before {
  content: "\F237"; }

.fa-viadeo:before {
  content: "\F2A9"; }

.fa-viadeo-square:before {
  content: "\F2AA"; }

.fa-vial:before {
  content: "\F492"; }

.fa-vials:before {
  content: "\F493"; }

.fa-viber:before {
  content: "\F409"; }

.fa-video:before {
  content: "\F03D"; }

.fa-video-plus:before {
  content: "\F4E1"; }

.fa-video-slash:before {
  content: "\F4E2"; }

.fa-vihara:before {
  content: "\F6A7"; }

.fa-vimeo:before {
  content: "\F40A"; }

.fa-vimeo-square:before {
  content: "\F194"; }

.fa-vimeo-v:before {
  content: "\F27D"; }

.fa-vine:before {
  content: "\F1CA"; }

.fa-vk:before {
  content: "\F189"; }

.fa-vnv:before {
  content: "\F40B"; }

.fa-volcano:before {
  content: "\F770"; }

.fa-volleyball-ball:before {
  content: "\F45F"; }

.fa-volume:before {
  content: "\F6A8"; }

.fa-volume-down:before {
  content: "\F027"; }

.fa-volume-mute:before {
  content: "\F6A9"; }

.fa-volume-off:before {
  content: "\F026"; }

.fa-volume-slash:before {
  content: "\F2E2"; }

.fa-volume-up:before {
  content: "\F028"; }

.fa-vote-nay:before {
  content: "\F771"; }

.fa-vote-yea:before {
  content: "\F772"; }

.fa-vr-cardboard:before {
  content: "\F729"; }

.fa-vuejs:before {
  content: "\F41F"; }

.fa-walker:before {
  content: "\F831"; }

.fa-walking:before {
  content: "\F554"; }

.fa-wallet:before {
  content: "\F555"; }

.fa-wand:before {
  content: "\F72A"; }

.fa-wand-magic:before {
  content: "\F72B"; }

.fa-warehouse:before {
  content: "\F494"; }

.fa-warehouse-alt:before {
  content: "\F495"; }

.fa-watch:before {
  content: "\F2E1"; }

.fa-watch-fitness:before {
  content: "\F63E"; }

.fa-water:before {
  content: "\F773"; }

.fa-water-lower:before {
  content: "\F774"; }

.fa-water-rise:before {
  content: "\F775"; }

.fa-webcam:before {
  content: "\F832"; }

.fa-webcam-slash:before {
  content: "\F833"; }

.fa-weebly:before {
  content: "\F5CC"; }

.fa-weibo:before {
  content: "\F18A"; }

.fa-weight:before {
  content: "\F496"; }

.fa-weight-hanging:before {
  content: "\F5CD"; }

.fa-weixin:before {
  content: "\F1D7"; }

.fa-whale:before {
  content: "\F72C"; }

.fa-whatsapp:before {
  content: "\F232"; }

.fa-whatsapp-square:before {
  content: "\F40C"; }

.fa-wheat:before {
  content: "\F72D"; }

.fa-wheelchair:before {
  content: "\F193"; }

.fa-whistle:before {
  content: "\F460"; }

.fa-whmcs:before {
  content: "\F40D"; }

.fa-wifi:before {
  content: "\F1EB"; }

.fa-wifi-1:before {
  content: "\F6AA"; }

.fa-wifi-2:before {
  content: "\F6AB"; }

.fa-wifi-slash:before {
  content: "\F6AC"; }

.fa-wikipedia-w:before {
  content: "\F266"; }

.fa-wind:before {
  content: "\F72E"; }

.fa-wind-warning:before {
  content: "\F776"; }

.fa-window:before {
  content: "\F40E"; }

.fa-window-alt:before {
  content: "\F40F"; }

.fa-window-close:before {
  content: "\F410"; }

.fa-window-maximize:before {
  content: "\F2D0"; }

.fa-window-minimize:before {
  content: "\F2D1"; }

.fa-window-restore:before {
  content: "\F2D2"; }

.fa-windows:before {
  content: "\F17A"; }

.fa-windsock:before {
  content: "\F777"; }

.fa-wine-bottle:before {
  content: "\F72F"; }

.fa-wine-glass:before {
  content: "\F4E3"; }

.fa-wine-glass-alt:before {
  content: "\F5CE"; }

.fa-wix:before {
  content: "\F5CF"; }

.fa-wizards-of-the-coast:before {
  content: "\F730"; }

.fa-wolf-pack-battalion:before {
  content: "\F514"; }

.fa-won-sign:before {
  content: "\F159"; }

.fa-wordpress:before {
  content: "\F19A"; }

.fa-wordpress-simple:before {
  content: "\F411"; }

.fa-wpbeginner:before {
  content: "\F297"; }

.fa-wpexplorer:before {
  content: "\F2DE"; }

.fa-wpforms:before {
  content: "\F298"; }

.fa-wpressr:before {
  content: "\F3E4"; }

.fa-wreath:before {
  content: "\F7E2"; }

.fa-wrench:before {
  content: "\F0AD"; }

.fa-x-ray:before {
  content: "\F497"; }

.fa-xbox:before {
  content: "\F412"; }

.fa-xing:before {
  content: "\F168"; }

.fa-xing-square:before {
  content: "\F169"; }

.fa-y-combinator:before {
  content: "\F23B"; }

.fa-yahoo:before {
  content: "\F19E"; }

.fa-yandex:before {
  content: "\F413"; }

.fa-yandex-international:before {
  content: "\F414"; }

.fa-yarn:before {
  content: "\F7E3"; }

.fa-yelp:before {
  content: "\F1E9"; }

.fa-yen-sign:before {
  content: "\F157"; }

.fa-yin-yang:before {
  content: "\F6AD"; }

.fa-yoast:before {
  content: "\F2B1"; }

.fa-youtube:before {
  content: "\F167"; }

.fa-youtube-square:before {
  content: "\F431"; }

.fa-zhihu:before {
  content: "\F63F"; }

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto; }

@media only screen and (max-width: 767px) {
  .hidden-xs {
    display: none !important; } }

@media only screen and (max-width: 500px) {
  .hidden-smm {
    display: none !important; } }

@media only screen and (max-width: 767px) {
  .hidden-sm {
    display: none !important; } }

@media only screen and (max-width: 991px) {
  .hidden-md {
    display: none !important; } }

@media only screen and (max-width: 1199px) {
  .hidden-lg {
    display: none !important; } }

body,
html {
  min-height: 100%;
  scroll-behavior: smooth; }

body {
  position: relative;
  min-width: 0;
  font-size: 15px;
  background-color: #f4f6fc !important; }
  @media screen and (max-width: 767px) {
    body {
      font-size: 14px; } }
  body[style*="top: 60px"] {
    margin-top: -60px; }

h1, h2, h3, .ui.modal > .header {
  font-family: "Poppins", sans-serif; }

#recite-wrapper {
  height: 100%; }

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
  font-family: "Quicksand", sans-serif; }
  #recite-wrapper #root {
    padding-top: 60px; }
  #root svg {
    overflow: visible; }
  #root img {
    max-width: 100%; }

.hidden {
  display: none; }

.pointer {
  cursor: pointer; }

.text-center {
  text-align: center; }

.text-right {
  text-align: right; }

.blue-text {
  color: #0067ac; }
  .blue-text:hover {
    color: #0067ac; }

.dark-text {
  color: #2e384d; }

.custom-label,
.field-label,
.field-wrap label {
  color: #1a1a1a;
  font-size: 11px;
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 1.02px; }

.page-title h1 {
  color: #2e384d;
  font-size: 28px;
  font-weight: normal;
  line-height: 41px; }

.page-title p {
  line-height: 28px; }

.ui.input {
  width: 100%; }
  .ui.input.error > input {
    color: red;
    border-color: red; }
  .ui.input > input:focus {
    border-color: #0067ac; }

.ui.selection.dropdown {
  border: 1px solid #e0e7ff; }
  .ui.selection.dropdown.loading > i.icon {
    padding: 0 !important; }
  .ui.selection.dropdown:hover {
    border: 1px solid #e0e7ff; }
  .ui.selection.dropdown:focus, .ui.selection.dropdown.active,
  .ui.selection.dropdown.active .menu, .ui.selection.dropdown.active:hover,
  .ui.selection.dropdown.active:hover .menu {
    border: 1px solid #0067ac !important; }
  .ui.selection.dropdown .item {
    font-family: "Quicksand", sans-serif; }
  .ui.selection.dropdown .icon:not(.delete):not(.close) {
    position: absolute;
    right: 10px;
    z-index: 10;
    color: #0067ac; }

.ui.button {
  background-color: #0067ac;
  color: #fff;
  font-family: "Quicksand", sans-serif; }
  .ui.button:hover, .ui.button:focus {
    background-color: #0094d1;
    color: #fff; }
  .ui.button.secondary {
    background-color: #33ac2e; }
    .ui.button.secondary:hover {
      background-color: #2a9426; }
  .ui.button.reverse {
    background-color: #90a6d5;
    color: #fff;
    font-weight: normal; }
    .ui.button.reverse:hover {
      background-color: #6d7d9e; }

.ui.button:disabled,
.ui.buttons .disabled.button, .ui.disabled.active.button, .ui.disabled.button, .ui.disabled.button:hover {
  opacity: 1 !important;
  background: #b0bac9; }

.ui .radio.checkbox {
  line-height: 33px; }
  .ui .radio.checkbox.custom-checkbox {
    line-height: 24px; }
    .ui .radio.checkbox.custom-checkbox .box:before,
    .ui .radio.checkbox.custom-checkbox label:before {
      width: 24px;
      height: 24px; }
    .ui .radio.checkbox.custom-checkbox .box:after,
    .ui .radio.checkbox.custom-checkbox label:after {
      width: 24px;
      height: 24px;
      font-size: 16px; }
    .ui .radio.checkbox.custom-checkbox input:checked ~ .box:after,
    .ui .radio.checkbox.custom-checkbox input:checked ~ label:after,
    .ui .radio.checkbox.custom-checkbox input:checked:focus ~ .box:after,
    .ui .radio.checkbox.custom-checkbox input:checked:focus ~ label:after {
      color: #0067ac; }
    .ui .radio.checkbox.custom-checkbox .box:before,
    .ui .radio.checkbox.custom-checkbox label:before,
    .ui .radio.checkbox.custom-checkbox .box:hover:before,
    .ui .radio.checkbox.custom-checkbox label:hover:before,
    .ui .radio.checkbox.custom-checkbox .box:active:before,
    .ui .radio.checkbox.custom-checkbox label:active:before,
    .ui .radio.checkbox.custom-checkbox input[type='checkbox']:focus ~ label:before,
    .ui .radio.checkbox.custom-checkbox input[type='checkbox']:focus ~ .box:before,
    .ui .radio.checkbox.custom-checkbox input:checked ~ .box:before,
    .ui .radio.checkbox.custom-checkbox input:checked ~ label:before,
    .ui .radio.checkbox.custom-checkbox input:focus ~ .box:before,
    .ui .radio.checkbox.custom-checkbox input:focus ~ label:before,
    .ui .radio.checkbox.custom-checkbox input:checked:focus ~ .box:before,
    .ui .radio.checkbox.custom-checkbox input:checked:focus ~ label:before {
      border-color: #0067ac; }
    .ui .radio.checkbox.custom-checkbox label {
      padding-left: 2.5em; }
    .ui .radio.checkbox.custom-checkbox input[type='checkbox'] {
      width: 24px;
      height: 24px; }
  .ui .radio.checkbox .box:before,
  .ui .radio.checkbox label:before {
    width: 33px;
    height: 33px; }
  .ui .radio.checkbox .box:after,
  .ui .radio.checkbox label:after {
    width: 33px;
    height: 33px; }
  .ui .radio.checkbox label {
    padding-left: 3em; }
  .ui .radio.checkbox input[type='radio'] {
    width: 33px;
    height: 33px; }
  .ui .radio.checkbox input:checked ~ .box:before,
  .ui .radio.checkbox input:checked ~ label:before {
    border-color: #0067ac; }
  .ui .radio.checkbox input:checked ~ .box:after,
  .ui .radio.checkbox input:checked ~ label:after,
  .ui .radio.checkbox input:focus:checked ~ .box:after,
  .ui .radio.checkbox input:focus:checked ~ label:after {
    background-color: #0067ac; }

i.far, i.fas, i.fal, i.icon {
  color: #0067ac; }

i.icon.far, i.icon.fas, i.icon.fal {
  font-family: 'Font Awesome 5 Pro', Icons; }

.secondary i.far, .secondary i.fas, .secondary i.fal, .secondary i.icon {
  color: white; }

.field-wrap {
  position: relative; }
  .field-wrap.date-input-wrap .ui.popup.top {
    top: auto !important;
    bottom: 100% !important; }
  .field-wrap.date-input-wrap .ui.popup.bottom {
    top: 100% !important;
    bottom: auto !important; }
  .field-wrap.date-input-wrap .ui.popup.left {
    left: 0 !important;
    right: auto !important; }
  .field-wrap.date-input-wrap .ui.popup.right {
    left: auto !important;
    right: 0 !important; }
  .field-wrap + .field-wrap {
    margin-top: 23px; }
  .field-wrap .ui.dropdown.error,
  .field-wrap .ui.dropdown.error > .text,
  .field-wrap .ui.dropdown.error > .default.text {
    background-color: transparent;
    color: red;
    border-color: red; }
    .field-wrap .ui.dropdown.error i,
    .field-wrap .ui.dropdown.error > .text i,
    .field-wrap .ui.dropdown.error > .default.text i {
      color: red; }
  .field-wrap .ui.dropdown.error > .menu > .item {
    color: #000; }
    .field-wrap .ui.dropdown.error > .menu > .item:hover {
      background-color: rgba(0, 0, 0, 0.1); }
  .field-wrap.error .field-label,
  .field-wrap.error .ui.checkbox label,
  .field-wrap.error label,
  .field-wrap .error.field .field-label,
  .field-wrap .error.field .ui.checkbox label,
  .field-wrap .error.field label {
    color: red; }
  .field-wrap.error input,
  .field-wrap.error input:focus,
  .field-wrap.error textarea,
  .field-wrap.error textarea:focus,
  .field-wrap.error .ui.checkbox label::before,
  .field-wrap .error.field input,
  .field-wrap .error.field input:focus,
  .field-wrap .error.field textarea,
  .field-wrap .error.field textarea:focus,
  .field-wrap .error.field .ui.checkbox label::before {
    border-color: red; }
  .field-wrap .error.field input::placeholder {
    color: red; }
  .field-wrap .error-msg {
    color: red;
    font-size: 12px; }

.repeater-field {
  margin-top: 1rem; }
  .repeater-field > .field-label {
    margin-bottom: 15px !important; }

.repeater-field--items {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed; }
  .repeater-field--items tr:first-child .field-wrap {
    margin-top: 0; }
  .repeater-field--items tr:first-child .repeater-field--item--button .icon {
    margin-top: 20px; }
  .repeater-field--items tr:not(:last-child) td {
    padding-bottom: 20px; }
  .repeater-field--items tr .repeater-field--item--button .icon {
    font-size: 16px; }
  .repeater-field--items tr.add-item-row button {
    background-color: transparent;
    border: none;
    font-size: 15px;
    color: #0067ac; }
    .repeater-field--items tr.add-item-row button .text {
      text-decoration: underline;
      vertical-align: middle; }
    .repeater-field--items tr.add-item-row button .plus {
      font-size: 30px;
      vertical-align: middle;
      padding-left: 5px; }
    .repeater-field--items tr.add-item-row button:focus {
      outline: none; }
  .repeater-field--items td {
    width: auto;
    vertical-align: middle;
    padding: 0 15px 0 0; }
    .repeater-field--items td:last-child {
      padding-right: 0; }
  .repeater-field--items .repeater-field--item--button {
    width: 40px; }
    .repeater-field--items .repeater-field--item--button .ui.button {
      display: block;
      margin-right: 0; }

.main-content {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  overflow: hidden; }
  .main-content .container {
    max-width: none;
    padding-bottom: 50px;
    overflow-y: auto;
    display: flex;
    flex-direction: column; }

.container {
  flex: 1 1 auto;
  width: 100%;
  max-width: calc(100vw - 215px);
  padding: 0 40px; }
  @media only screen and (max-width: 991px) {
    .container {
      max-width: 100%;
      padding: 0 15px; } }

.field-wrap {
  margin-bottom: 20px; }
  .field-wrap .field-label,
  .field-wrap label {
    margin-bottom: 5px;
    display: block; }

.error-message {
  margin-top: 5px;
  display: inline-block;
  color: red; }

.success-message {
  margin-top: 5px;
  display: inline-block;
  color: green;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.4; }

.button-link {
  padding: 0 20px;
  height: 40px;
  display: inline-block;
  font-family: "Quicksand", sans-serif;
  font-weight: 500;
  line-height: 40px;
  text-align: center;
  border-radius: 4px;
  background-color: #0067ac;
  color: #fff; }
  .button-link:hover, .button-link:focus {
    background-color: #0094d1;
    color: #fff; }
  .button-link + .button-link {
    margin-left: 8px; }
  .button-link.secondary {
    background-color: #33ac2e; }
    .button-link.secondary:hover {
      background-color: #2a9426; }
  .button-link.disable {
    background-color: #b0bac9;
    pointer-events: none; }

a, .link {
  color: #0067ac; }

textarea {
  padding: 10px;
  border-color: #0067ac;
  border-radius: 5px;
  outline: none; }

.form-modal .actions {
  margin-top: 30px; }

.form-modal .ui.dropdown.selection {
  border: 1px solid rgba(34, 36, 38, 0.15); }
  .form-modal .ui.dropdown.selection .icon:not(.delete):not(.close) {
    z-index: 10; }

.modal.confirm .actions .ui.button:not(.primary) {
  background: #e0e1e2 none;
  color: rgba(0, 0, 0, 0.6); }

.create-update-group-modal .actions {
  display: flex;
  justify-content: flex-end; }
  .create-update-group-modal .actions button.negative.button {
    justify-self: flex-start;
    margin: 0; }
    .create-update-group-modal .actions button.negative.button + button {
      margin-left: auto; }

.pagination-wrap {
  display: flex;
  align-items: center;
  flex-direction: column; }
  .pagination-wrap p {
    margin-top: 10px; }

label[for="recite-close"],
#recite-close {
  display: none !important; }

.expand {
  margin-left: 12px;
  padding: 0;
  background-color: transparent;
  font-size: 20px;
  line-height: 1;
  border: none;
  cursor: pointer;
  outline: none; }
  .expand i {
    color: #69707f;
    vertical-align: text-top; }

.ui.multiple.selection.dropdown .labelsWrap {
  display: flex;
  flex-wrap: wrap; }
  .ui.multiple.selection.dropdown .labelsWrap .ui.label {
    margin-bottom: 5px;
    display: inline-block; }

.target-tooltip {
  position: absolute;
  left: 134px;
  top: -3px !important;
  z-index: 999999; }

.target-tooltip-parent {
  position: relative;
  margin-bottom: 40px !important; }

i.grey.icon {
  color: #ffffff !important; }

